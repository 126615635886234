import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { FieldGridText } from "../../components/FieldGridText";
import { FieldGridNumber } from "../../components/FieldGridNumber";
import { FieldGridCheckbox } from "../../components/FieldGridCheckbox";
import { FieldGridList } from "../../components/FieldGridList";
import { FieldGridDate } from "../../components/FieldGridDate";
import { FieldGridHour } from "../../components/FieldGridHour";
import { FieldGridListSearch } from "../../components/FieldGridListSearch";
import { FieldGridNoAccess } from "../FieldGridNoAccess";

export const RegistroSubModulo = ({
  registro: initialValues,
  labels,
  dropdownOpened,
  handleDropdown,
  module,
  moduleLists,
  sourceModule,
  updateRecords,
  setLoading,
  allowLinkElement,
  allowCreateRecordChild,
  inheritedFields,
  access,
  setSubmodulesResetTrigger,
}) => {
  const [beingEdited, setBeingEdited] = useState(
    initialValues.DefaultEditable ? true : false
  );
  const [values, setValues] = useState(initialValues);
  const form = useRef();

  var varStyleDisabledCustom = false;
  var varBeginEdited = beingEdited;
  if (module === "UB_estructuraprop" && !beingEdited) {
    varStyleDisabledCustom = true;
    varBeginEdited = true;
  }

  if (module === "UB_estructuraprop" && beingEdited) {
    varStyleDisabledCustom = false;
  }
 
  /* if (initialValues.DefaultEditable === true)
  {
    setBeingEdited(true);
  }*/



  const idRegistro = initialValues.Id;
  const recordInheritedFields = { ...inheritedFields };
  recordInheritedFields.ParentId = {
    Value: idRegistro,
    Label: values.Name,
  };

  const handleEdit = () => {
    handleDropdown(idRegistro);
    setBeingEdited(true);
  };

  const handleChange = (key, value) => {
    if (varStyleDisabledCustom) return false;
    const newValues = { ...values };
    newValues[key] = value;
    setValues(newValues);
  };

  const handleSubmitRow = (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(form.current);
    const objectFormData = Object.fromEntries(formData);
    objectFormData.Id = idRegistro;
    objectFormData.LastModified = values.LastModified;
    objectFormData.IsGridSave = true;

    const data = {};
    data.idModule = module;
    data.fields = objectFormData;

    API.saveByModule(data)
      .then((res) => {
        if (res.Status === "OK") {
          handleChange("LastModified", res.LastModified);
          const msg = res.Msg.split("/");
          if (msg.includes("updateRecords")) updateRecords();
          if (msg.includes("submodulesReset")) setSubmodulesResetTrigger(true);
          if (msg.includes("extraMsg")) {
            toast.success(`Guardado\n${msg.pop()}`);
          } else toast.success("Guardado");
          setBeingEdited(false);
        } else if (res.Status === "NeedReload") {
          toast.error(
            "Se encontró una version mas reciente.\nSe requiere recargar (se perderán los cambios no guardados)"
          );
        } else {
          toast.info(`${res.Status}: ${res.Msg}`);
        }
      })
      .catch((error) =>
        toast.error(`${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setValues(initialValues);
    setBeingEdited(false);
  };

  const unlink = () => {
    setLoading(true);
    handleDropdown(idRegistro);

    const data = {};
    data.SourceModule = sourceModule?.type;
    data.IdSourceEntity = sourceModule?.id;
    data.LinkModule = module;
    data.IdLinksEntities = [idRegistro];

    API.unlinkModule(data)
      .then((res) => {
        if (res.Status === "OK") {
          toast.success("Desvinculado");
          updateRecords();
        } else if (res.Status === "KO") {
          toast.error(res.Msg);
        } else {
          toast.info(`${res.Status}: ${res.Msg}`);
        }
      })
      .catch((error) =>
        toast.error(`${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };
  
  const setCustomReadOnly = (isEditable, module, row, column) => {
    if (module !== "UB_UsosPricing") return isEditable;
    
    if (
      column === "PrecioVenta" &&
      (row === "Garajes Libres (€/unidad)" ||
        row === "Garajes VPO (€/unidad)" ||
        row === "Trasteros (€/unidad)")
    )
      return "0";
    if (
      column === "PrecioUnitario" &&
      (row === "Garajes Libres (€/unidad)" ||
        row === "Garajes VPO (€/unidad)" ||
        row === "Trasteros (€/unidad)")
    )
      return "1";

    return isEditable;
  };

  if (labels.length === 0) return null;

  return (
    <S.Tr
      ref={form}
      onSubmit={handleSubmitRow}
      beingEdited={beingEdited}
      highlighted={values.Highlighted}
      off={values.NoAplica || values.NoContinuar || values.DisabledRow}
    >
      {labels.map((field) => {
        const { key, type, relateLink, editable, required } = field;

        let iseditable = setCustomReadOnly(
          editable,
          module,
          values.Nombre,
          key
        );
        if(values.IsTotal)
        {
          return (
            <FieldGridText
              key={key}
              field={field}
              value={values[key] || ""}
              beingEdited={beingEdited}
              handleChange={handleChange}
              link={relateLink.link}
              infoLink={{
                module,
                id: idRegistro,
              }}
              readOnly={iseditable === "0"}
              required={required === "1"}
              allowLinkElement={allowLinkElement = false}
              highlighted={values.Highlighted}
            />
          );
        }
        else if(values.IsTexto && type === "enum")
        {
          return (
            <FieldGridText
            key={key}
            field={field}
            value={values[key] || ""}
            beingEdited={beingEdited}
            handleChange={handleChange}
            link={relateLink.link}
            infoLink={{
              module,
              id: idRegistro,
            }}
            readOnly={iseditable === "0"}
            required={required === "1"}
          />
          );
        }
        else{
        switch (type) {
          case "text":
          case "file":
            return (
              <FieldGridText
                key={key}
                field={field}
                value={values[key] || ""}
                beingEdited={beingEdited}
                handleChange={handleChange}
                link={relateLink.link}
                infoLink={{
                  module,
                  id: idRegistro,
                }}
                readOnly={iseditable === "0"}
                required={required === "1"}
              />
            );

          case "int":
          case "float":
          case "decimal":
          case "currency":
          case "coordenada":
            return (
              <FieldGridNumber
                key={key}
                field={field}
                value={values[key] || 0}
                empty={values[key] === "" || values[key] === null}
                type={type}
                beingEdited={beingEdited}
                handleChange={handleChange}
                readOnly={iseditable === "0"}
                required={required === "1"}
              />
            );

          case "bool":
            return (
              <FieldGridCheckbox
                key={key}
                field={field}
                value={values[key]}
                beingEdited={varBeginEdited}
                handleChange={handleChange}
                readOnly={iseditable === "0"}
                required={required === "1"}
              />
            );

          case "enum":
            return (
              <FieldGridList
                key={key}
                field={field}
                value={values[key]}
                options={moduleLists[key]?.SelectValues}
                beingEdited={beingEdited}
                handleChange={handleChange}
                readOnly={iseditable === "0"}
                required={required === "1"}
              />
            );

          case "date":
            return (
              <FieldGridDate
                key={key}
                field={field}
                value={values[key]?.split("T")[0] || ""}
                beingEdited={beingEdited}
                handleChange={handleChange}
                readOnly={iseditable === "0"}
                required={required === "1"}
              />
            );
            case "hour":
                return (
                    <FieldGridHour
                        key={key}
                        field={field}
                        value={values[key]?.split("T")[0] || ""}
                        beingEdited={beingEdited}
                        handleChange={handleChange}
                        readOnly={iseditable === "0"}
                        required={required === "1"}
                    />
                );
          case "relate":
            return (
              <FieldGridListSearch
                key={key}
                id={key}
                listName={moduleLists[key]?.ListName}
                currentOption={{
                  value: values[key]?.Value || values[key] || "",
                  label: values[key]?.Label,
                }}
                canBeEmpty={moduleLists[key]?.ShowEmpty === "1"}
                handleChange={handleChange}
                beingEdited={beingEdited}
                readOnly={iseditable === "0"}
                required={required === "1"}
              />
            );

          case "imagen":
            return (
              <img
                key={key}
                alt=""
                src={"data:image/jpg;base64," + values[key]}
              />
            );

          case "no_access":
            return (
              <FieldGridNoAccess
                key={key}
                field={field}
                value={values[key] || ""}
              />
            );

          default:
            return (
              <FieldGridText
                key={key}
                field={field}
                value={values[key] || ""}
                beingEdited={beingEdited}
                handleChange={handleChange}
                readOnly={iseditable === "0"}
                required={required === "1"}
              />
            );
        }
      }})}
      <S.TdEdit>
        <S.Container>
          {beingEdited ? (
            <>
              <S.Button type="submit" styleType="save" title="Guardar">
                <b>✓</b>
              </S.Button>
              <S.Button
                type="button"
                styleType="cancel"
                title="Cancelar"
                onClick={handleCancel}
              >
                <b>X</b>
              </S.Button>
            </>
          ) : (
            <>
              {allowCreateRecordChild && values.parentRecord && (
                <Link
                  to={{
                    pathname: `/${module}/create`,
                    sourceModule,
                    inheritedFields: recordInheritedFields,
                  }}
                >
                  <S.Button
                    type="button"
                    onClick={() => handleDropdown(idRegistro)}
                  >
                    +
                  </S.Button>
                </Link>
              )}
              <S.Button
                type="button"
                onClick={() => handleDropdown(idRegistro)}
                disabled={
                  (access && access[module]?.edit === 0) ||
                  (!values.Editable && !allowLinkElement)
                }
              >
                ▾
              </S.Button>
            </>
          )}
          {dropdownOpened === idRegistro &&
            (values.Editable || allowLinkElement) && (
              <S.Ul>
                {values.Editable && <S.Li onClick={handleEdit}>Editar</S.Li>}
                {allowLinkElement && <S.Li onClick={unlink}>Desvincular</S.Li>}
                {module === "Coordenadas" && (
                  <S.Li onClick={unlink}>Eliminar</S.Li>
                )}
              </S.Ul>
            )}
        </S.Container>
      </S.TdEdit>
    </S.Tr>
  );
};

RegistroSubModulo.propTypes = {
  registro: PropTypes.object,
  labels: PropTypes.arrayOf(PropTypes.object),
  dropdownOpened: PropTypes.string,
  handleDropdown: PropTypes.func.isRequired,
  module: PropTypes.string.isRequired,
  moduleLists: PropTypes.object,
  sourceModule: PropTypes.objectOf(PropTypes.string),
  updateRecords: PropTypes.func,
  setLoading: PropTypes.func,
  allowLinkElement: PropTypes.bool,
  allowCreateRecordChild: PropTypes.bool,
  inheritedFields: PropTypes.objectOf(PropTypes.object),
  access: PropTypes.object,
  setTriggerReset: PropTypes.func,
};
