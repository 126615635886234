import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { useParams, useHistory } from "react-router-dom";
import * as API from "../../api";
import { FieldText } from "../../components/FieldText";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldTextArea } from "../../components/FieldTextArea";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { FieldNumber } from "../../components/FieldNumber";
import { FieldNoAccess } from "../../components/FieldNoAccess";
import { Button } from "../../components/Button";
import { Module } from "../../components/Module";
import { Loading } from "../../components/Loading";
import { Dialog } from "../../components/Dialog";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST, ADD_RELATE_ENTITY_TEXT } from "../../constants";
import { Link } from "react-router-dom";
import { usePermissions } from "../../context/PermissionsContext";

export const DetalleFincasAp = () => {
  const { idFincaAp } = useParams();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("");
  const [submenus, setSubmenus] = useState([]);
  const [fields, setFields] = useState({});
  const [detalleFincaAp, setDetalleFincaAp] = useState({});
  const [detalleFincaApListas, setDetalleFincaApListas] = useState({});
  const [editarOpened, setEditarOpened] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [editionMode, setEditionMode] = useState(false);
  const [modified, setModified] = useState(false);
  const form = useRef();
  const timerToast = useRef();
  const history = useHistory();
  useEffect(() => {
    const dataRenderPage = {
      Module: "UB_FincasAp",
      RenderType: idFincaAp === "create" ? "create" : "detail",
    };
    API.renderPage(dataRenderPage)
      .then((res) => {
        const submenus = Object.keys(res);
        setSubmenus(submenus);
        setSection(submenus[0]);
        setFields(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
    API.getListByModule("UB_FincasAp")
      .then((res) => {
        setDetalleFincaApListas(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  }, [idFincaAp]);
  useEffect(() => {
    if (idFincaAp === "create") {
      setLoading(false);
    } else {
      API.getFincaAp(idFincaAp)
        .then((res) => {
          setDetalleFincaAp(res);
          setInitialValues(res);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
          error.code !== 401 &&
            toast("error", `${error.msg.Message} (código ${error.code})`);
          setTimeout(
            () => history.push("/UB_FincasAp"),
            DEFAULT_TIMER_TOAST - 100
          );
        })
        .finally(() => setLoading(false));
    }
  }, [idFincaAp, history]);
  useEffect(() => {
    document.addEventListener("wheel", blurActiveInputNumber);
    return () => document.removeEventListener("wheel", blurActiveInputNumber);
  }, []);

  const create = idFincaAp === "create";
  const editable = create || detalleFincaAp.Editable;

  const blurActiveInputNumber = () =>
    document.activeElement.type === "number" && document.activeElement.blur();

  const updateDetail = () => {
    API.getFincaAp(idFincaAp)
      .then((res) => {
        setDetalleFincaAp(res);
        setInitialValues(res);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
        setTimeout(
          () => history.push("/UB_FincasAp"),
          DEFAULT_TIMER_TOAST - 100
        );
      })
      .finally(() => setLoading(false));
  };

  const createAccount = (key, value) => {
    setLoading(true);
    const data = {};
    data.idModule = "Accounts";
    data.fields = {
      Id: "00000000-0000-0000-0000-000000000000",
      Name: value.Value,
    };
    API.saveByModule(data)
      .then((res) => {
        toast("success", `Creado "${value.Value}"`);
        const newDetalleFincaAp = { ...detalleFincaAp };
        newDetalleFincaAp[key] = { Value: res.NewId, Label: value.Value };
        setDetalleFincaAp(newDetalleFincaAp);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (key, value) => {
    setEditarOpened(false);
    key !== "LastModified" && setModified(true);
    if (value.Label?.includes(ADD_RELATE_ENTITY_TEXT))
      createAccount(key, value);
    else {
      const newDetalleFincaAp = { ...detalleFincaAp };
      newDetalleFincaAp[key] = value;
      setDetalleFincaAp(newDetalleFincaAp);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveFincaAp();
  };

  const saveFincaAp = () => {
    setLoading(true);
    setEditarOpened(false);
    const formData = new FormData(form.current);
    const objectFormData = Object.fromEntries(formData);
    objectFormData.Id = create
      ? "00000000-0000-0000-0000-000000000000"
      : idFincaAp;
    objectFormData.TeamId = detalleFincaAp.TeamId;
    objectFormData.LastModified = detalleFincaAp.LastModified;
    Object.values(fields)
      .flat()
      .filter((e) => e.type === "enum_multiple")
      .map((e) => e.key)
      .forEach((key) => (objectFormData[key] = formData.getAll(key)));

    API.saveFincaAp(objectFormData)
      .then((res) => {
        if (res.Status === "OK") {
          handleChange("LastModified", res.LastModified);
          setInitialValues(detalleFincaAp);
          setEditionMode(false);
          setModified(false);
          if (create) {
            toast("success", "Finca Creada");
            setTimeout(
              () => history.push("/UB_FincasAp"),
              DEFAULT_TIMER_TOAST - 100
            );
          } else {
            handleChange("LastModified", res.LastModified);
            toast("success", "Finca Guardada");
          }
        } else if (res.Status === "KO") {
          toast("error", res.Msg, 5000);
        } else if (res.Status === "NeedReload") {
          toast(
            "error",
            "Se encontró una version mas reciente de la finca.\nSe requiere recargar (se perderán los cambios no guardados)",
            5000
          );
        } else {
          toast("info", res.Status, 5000);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const handleDuplicar = () => {
    setEditarOpened(false);
    setDialogVisible(true);
  };

  const duplicarFincaAp = (name) => {
    if (name === detalleFincaAp.Name) {
      toast("error", "El nombre debe ser distinto");
      return;
    }
    setLoading(true);
    const cloneData = {
      IdEntity: idFincaAp,
      Name: name,
    };
    API.cloneFincaAp(cloneData)
      .then((res) => {
        setDialogVisible(false);
        if (res.Status === "OK") {
          toast("success", "Finca Duplicada");
        } else toast("error", res.Msg, 5000);
      })
      .catch((error) =>
        toast("error", `${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0) {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  const render_submenu_fields = (fields) => (
    <S.FieldsContainer>
      {fields.map((field, index) => {
        const {
          key,
          label,
          type,
          editable,
          required,
          relateLink,
          visibleCondition,
          AllowAddRelateEntity,
        } = field;

        let hidden = false;
        if (visibleCondition.condition !== "") {
          var valueCast = visibleCondition.value;
          if (visibleCondition.value === "true") {
            valueCast = true;
          }
          if (visibleCondition.value === "false") {
            valueCast = false;
          }

          if (
            visibleCondition.condition === "equal" &&
            detalleFincaAp[visibleCondition.field] !== valueCast
          ) {
            hidden = true;
          }

          if (
            visibleCondition.condition === "not_equal" &&
            detalleFincaAp[visibleCondition.field] === valueCast
          ) {
            hidden = true;
          }
        }

        switch (type) {
          case "text":
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleFincaAp[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleFincaAp[key]
                }
                hidden={hidden}
              />
            );

          case "enum":
            const currentLabel =
              detalleFincaAp[key] &&
              detalleFincaApListas[key]?.SelectValues.find(
                (option) => option.Id === detalleFincaAp[key]
              )?.Value;
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOption={{
                  value: detalleFincaAp[key] || "",
                  label: currentLabel,
                }}
                options={detalleFincaApListas[key]?.SelectValues}
                canBeEmpty={detalleFincaApListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleFincaAp[key]
                }
                hidden={hidden}
              />
            );

          case "enum_multiple":
            const currentOptions = detalleFincaAp[key]?.map((id) => {
              const option = {};
              option.value = id;
              option.label = detalleFincaApListas[key]?.SelectValues.find(
                (option) => option.Id === id
              )?.Value;
              return option;
            });
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOptions={currentOptions}
                options={detalleFincaApListas[key]?.SelectValues}
                canBeEmpty={detalleFincaApListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleFincaAp[key]
                }
                hidden={hidden}
              />
            );

          case "relate":
            return (
              <FieldListSearch
                key={key}
                id={key}
                label={label}
                listName={detalleFincaApListas[key]?.ListName}
                currentOption={{
                  value: detalleFincaAp[key]?.Value || "",
                  label: detalleFincaAp[key]?.Label,
                }}
                canBeEmpty={detalleFincaApListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                relateLink={relateLink}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleFincaAp[key]
                }
                allowAddRelateEntity={AllowAddRelateEntity}
              />
            );

          case "textarea":
            return (
              <FieldTextArea
                key={key}
                id={key}
                label={label}
                value={detalleFincaAp[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleFincaAp[key]
                }
                hidden={hidden}
              />
            );

          case "bool":
            return (
              <FieldCheckbox
                key={key}
                id={key}
                label={label}
                value={detalleFincaAp[key] || false}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
              />
            );

          case "int":
          case "float":
          case "decimal":
          case "currency":
            return (
              <FieldNumber
                key={key}
                id={key}
                label={label}
                value={detalleFincaAp[key] || 0}
                empty={
                  detalleFincaAp[key] === "" ||
                  detalleFincaAp[key] === null ||
                  create
                }
                handleChange={handleChange}
                type={type}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleFincaAp[key]
                }
                hidden={hidden}
              />
            );

          case "no_access":
            return <FieldNoAccess key={key} id={key} label={label} />;

          case "Empty":
            return <li key={`empty${index}`} />;

          default:
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleFincaAp[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleFincaAp[key]
                }
                hidden={hidden}
              />
            );
        }
      })}
    </S.FieldsContainer>
  );

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {dialogVisible && (
        <Dialog
          message="Nombre de la nueva finca aportada:"
          initialValue={detalleFincaAp.Name}
          labelConfirm="Duplicar"
          confirm={duplicarFincaAp}
          cancel={() => setDialogVisible(false)}
        />
      )}
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          <FieldText
            id="Name"
            value={detalleFincaAp.Name || ""}
            handleChange={handleChange}
            form="form"
            type="title"
            placeholder="Nuevo nombre..."
            required={true}
            alwaysEditing={
              editionMode ||
              create ||
              initialValues.Name !== detalleFincaAp.Name
            }
          />
        </S.WrapperFieldText>
        <S.ButtonsContainer>
          {editionMode || modified || create ? (
            <>
              <Button
                label="Cancelar"
                onClick={() => {
                  if (create) history.goBack();
                  else {
                    setDetalleFincaAp(initialValues);
                    setEditionMode(false);
                    setModified(false);
                  }
                }}
                disabled={!editable || loading}
              />
              <Button
                label={create ? "Crear" : "Guardar"}
                onClick={saveFincaAp}
                disabled={!editable || loading}
              />
            </>
          ) : (
            <>
              <Button
                label="Editar"
                onClick={() => {
                  setEditionMode(true);
                  setEditarOpened(false);
                }}
                disabled={!editable || loading}
              />
              <Button
                label="▾"
                onClick={() => setEditarOpened(!editarOpened)}
                disabled={create || !editable || loading}
              />{" "}
            </>
          )}
          {editarOpened && (
            <S.Ul>
              <S.Li onClick={handleDuplicar}>Duplicar</S.Li>
              <Link
                to={{
                  pathname: `/UB_FincasAp/${idFincaAp}/audit`,
                  entityname: detalleFincaAp.Name,
                }}
              >
                <S.Li>Ver el registro de auditoría</S.Li>
              </Link>
            </S.Ul>
          )}
        </S.ButtonsContainer>
      </S.TitleBar>
      <S.SubMenuBar>
        <ul>
          <S.Submenu id="dummy" />
          {submenus.map((submenu) => (
            <S.Submenu
              key={submenu}
              className={submenu === section ? "active" : null}
              onClick={() => setSection(submenu)}
            >
              {submenu}
            </S.Submenu>
          ))}
          <S.Submenu id="dummy-end" />
        </ul>
      </S.SubMenuBar>
      <form id="form" ref={form} onSubmit={handleSubmit}>
        <S.Content>
          {submenus.map((submenu) => (
            <div
              key={submenu}
              className={submenu === section ? "active" : "inactive"}
            >
              {fields[submenu] && render_submenu_fields(fields[submenu])}
            </div>
          ))}
        </S.Content>
      </form>
      {!create && (
        <S.ModulesContainer>
          {detalleFincaAp.ModulesCount &&
            Object.entries(detalleFincaAp.ModulesCount)
              .filter((moduleInfo) => permissions[moduleInfo[0]]?.access === 1)
              .map((moduleInfo, index) => (
                <Module
                  key={index}
                  moduleInfo={moduleInfo}
                  sourceModule={{
                    type: "UB_FincasAp",
                    id: idFincaAp,
                    name: detalleFincaAp.Name,
                  }}
                  getModule={API.getModuleFincaAp}
                  updateDetail={updateDetail}
                  setLoading={setLoading}
                  access={permissions}
                  visibleInTab={true}
                />
              ))}
        </S.ModulesContainer>
      )}
    </S.Container>
  );
};
