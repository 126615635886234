import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldTextArea = ({
  id,
  label,
  value,
  handleChange,
  readOnly,
  required,
  alwaysEditing,
  hidden,
}) => {
  const [focused, setFocused] = useState(false);

  const editing = !readOnly && (focused || alwaysEditing);

  return (
    <S.Container
      onClick={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={hidden ? "hidden" : "visible"}
    >
      <S.Label>{label}</S.Label>
      <S.TextAreaContainer>
        <S.TextArea
          name={id}
          value={value}
          onChange={(e) => handleChange(id, e.target.value)}
          required={required}
          editing={editing}
          title={readOnly ? value : null}
        />
      </S.TextAreaContainer>
    </S.Container>
  );
};

FieldTextArea.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  alwaysEditing: PropTypes.bool,
  hidden: PropTypes.bool,
};
