import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.li`
  flex: 1;
  list-style: none;
  .hidden {
    display: none;
  }
`;

export const Title = styled.div`
  font-size: 0.75rem;
  color: ${COLORES.GRIS_OSCURO};
  margin-bottom: 0.2rem;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export const Text = styled.div`
  font-size: ${(props) => (props.type === "title" ? "1rem" : "0.875rem")};
  display: block;
  padding: 1px 9px;
  width: calc(50vw - 5rem);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
