import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const DialogTwoInput = ({
  message1,
  message2,
  initialValue1 = "",
  initialValue2 = "",
  labelConfirm,
  confirm,
  cancel,
}) => {
  const [input1, setInput1] = useState(initialValue1);
  const [input2, setInput2] = useState(initialValue2);
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleBar>
          <S.Close onClick={cancel}>×</S.Close>
        </S.TitleBar>
        <S.Content>
          <p>{message1}</p>
          <S.Input value={input1} onChange={(e) => setInput1(e.target.value)} />
          <p>{message2}</p>
          <S.Input value={input2} onChange={(e) => setInput2(e.target.value)} />
          <S.Buttons>
            {confirm && (
              <button onClick={() => confirm(input1, input2)}>
                {labelConfirm}
              </button>
            )}
            {cancel && <button onClick={cancel}>Cancelar</button>}
          </S.Buttons>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

DialogTwoInput.propTypes = {
  message1: PropTypes.string.isRequired,
  message2: PropTypes.string.isRequired,
  initialValue1: PropTypes.string,
  initialValue2: PropTypes.string,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
