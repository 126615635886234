import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { useParams, useHistory, useLocation } from "react-router-dom";
import * as API from "../../api";
import { FieldDate } from "../../components/FieldDate";
import { FieldText } from "../../components/FieldText";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldTextArea } from "../../components/FieldTextArea";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { FieldNumber } from "../../components/FieldNumber";
import { FieldNoAccess } from "../../components/FieldNoAccess";
import { Button } from "../../components/Button";
import { Module } from "../../components/Module";
import { Loading } from "../../components/Loading";
import { DialogTwoInput } from "../../components/DialogTwoInput";
import { DialogSelect } from "../../components/DialogSelect";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST, ADD_RELATE_ENTITY_TEXT } from "../../constants";
import { Link } from "react-router-dom";
import { FieldFile } from "../../components/FieldFile";
import { usePermissions } from "../../context/PermissionsContext";
const { REACT_APP_INFORME_BIRT_URL } = process.env;

export const DetalleSuelos = () => {
  const { idSuelo } = useParams();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("");
  const [submenus, setSubmenus] = useState([]);
  const [fields, setFields] = useState({});
  const [detalleSuelo, setDetalleSuelo] = useState({});
  const [detalleSueloListas, setDetalleSueloListas] = useState({});
  const [editarOpened, setEditarOpened] = useState(false);
  const [dialogDuplicarVisible, setDialogDuplicarVisible] = useState(false);
  const [dialogEjecutarFichaVisible, setDialogEjecutarFichaVisible] =
    useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const [informesBirtOptions, setInformesBirtOptions] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [editionMode, setEditionMode] = useState(false);
  const [modified, setModified] = useState(false);
  const [submodulesResetTrigger, setSubmodulesResetTrigger] = useState(false);
  const form = useRef();
  const timerToast = useRef();
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const dataRenderPage = {
      Module: "UB_Suelo",
      RenderType: idSuelo === "create" ? "create" : "detail",
      IdEntity: idSuelo,
    };
    API.renderPage(dataRenderPage)
      .then((res) => {
        const submenus = Object.keys(res);
        setSubmenus(submenus);
        setSection(submenus[0]);
        setFields(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
    API.getListByModule("UB_Suelo")
      .then((res) => setDetalleSueloListas(res))
      .catch((error) => API.DEVELOP && console.log(error));
  }, [idSuelo]);
  useEffect(() => {
    if (idSuelo === "create") {
      setLoading(false);
    } else {
      API.getSuelo(idSuelo)
        .then((res) => {
          setDetalleSuelo(res);
          setInitialValues(res);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
          error.code !== 401 &&
            toast("error", `${error.msg.Message} (código ${error.code})`);
          setTimeout(
            () => history.push("/UB_Suelo"),
            DEFAULT_TIMER_TOAST - 100
          );
        })
        .finally(() => setLoading(false));
    }
  }, [idSuelo, history]);
  useEffect(() => {
    document.addEventListener("wheel", blurActiveInputNumber);
    return () => document.removeEventListener("wheel", blurActiveInputNumber);
  }, []);

  const create = idSuelo === "create";
  const editable = create || detalleSuelo.Editable;

  const blurActiveInputNumber = () =>
    document.activeElement.type === "number" && document.activeElement.blur();

  const updateDetail = () => {
    API.getSuelo(idSuelo)
      .then((res) => {
        setDetalleSuelo(res);
        setInitialValues(res);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
        setTimeout(() => history.push("/UB_Suelo"), DEFAULT_TIMER_TOAST - 100);
      })
      .finally(() => setLoading(false));
  };

  const createAccount = (key, value) => {
    setLoading(true);
    const data = {};
    data.idModule = "Accounts";
    data.fields = {
      Id: "00000000-0000-0000-0000-000000000000",
      Name: value.Value,
    };
    API.saveByModule(data)
      .then((res) => {
        toast("success", `Creado "${value.Value}"`);
        const newDetalleSuelo = { ...detalleSuelo };
        newDetalleSuelo[key] = { Value: res.NewId, Label: value.Value };
        setDetalleSuelo(newDetalleSuelo);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (key, value) => {
    setEditarOpened(false);
    if (key === "HitosACumplirProximamente") return;
    key !== "LastModified" && setModified(true);
    if (value.Label?.includes(ADD_RELATE_ENTITY_TEXT))
      createAccount(key, value);
    else {
      const newDetalleSuelo = { ...detalleSuelo };
      newDetalleSuelo[key] = value;
      Object.entries(detalleSueloListas).forEach((lista) => {
        if (lista[1].Parent === key) newDetalleSuelo[lista[0]] = "";
      });
      setDetalleSuelo(newDetalleSuelo);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveSuelo();
  };

  const saveSuelo = () => {
    setLoading(true);
    setEditarOpened(false);
    const formData = new FormData(form.current);
    const objectFormData = Object.fromEntries(formData);
    objectFormData.Id = create
      ? "00000000-0000-0000-0000-000000000000"
      : idSuelo;
    objectFormData.LastModified = detalleSuelo.LastModified;
    Object.values(fields)
      .flat()
      .filter((e) => e.type === "enum_multiple")
      .map((e) => e.key)
      .forEach((key) => (objectFormData[key] = formData.getAll(key)));

    API.saveSuelo(objectFormData)
      .then((res) => {
        if (res.Status === "OK") {
          handleChange("LastModified", res.LastModified);
          setInitialValues(detalleSuelo);
          setEditionMode(false);
          setModified(false);
          if (create) {
            toast("success", "Suelo Creado");
            setTimeout(
              () => history.push("/UB_Suelo"),
              DEFAULT_TIMER_TOAST - 100
            );
          } else {
            handleChange("LastModified", res.LastModified);
            toast("success", "Suelo Guardado");
          }
        } else if (res.Status === "KO") {
          toast("error", res.Msg, 5000);
        } else if (res.Status === "NeedUpdate") {
          setEditionMode(false);
          setModified(false);
          setSubmodulesResetTrigger(true);
          updateDetail();
          toast("success", "Suelo Guardado y Actualizado");
        } else if (res.Status === "NeedReload") {
          toast(
            "error",
            "Se encontró una version mas reciente del suelo.\nSe requiere recargar (se perderán los cambios no guardados)",
            5000
          );
        } else {
          toast("info", res.Status, 5000);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const handleDuplicar = () => {
    setEditarOpened(false);
    setDialogDuplicarVisible(true);
  };

  const handleEjecutarFicha = () => {
    API.getSueloReports()
      .then((res) => {
        const options = res.map((opt) => {
          return {
            label: opt.Name,
            value: opt.Id,
            name: opt.Name,
            extension: opt.Salida,
            fichero: opt.Fichero,
          };
        });
        setInformesBirtOptions(options);
      })
      .catch((error) => API.DEVELOP && console.log(error));
    setEditarOpened(false);
    setDialogEjecutarFichaVisible(true);
  };

  const handleDesasociarIdMaster = () => {
    setLoading(true);
    setEditarOpened(false);

    const data = {};
    data.SourceModule = "UB_Suelo";
    data.IdSourceEntity = idSuelo;
    data.LinkModule = "UB_Suelo";
    data.IdLinksEntities = [idSuelo];

    API.unlinkModule(data)
      .then((res) => {
        if (res.Status === "OK") {
          toast("success", "Desvinculado");
          setEditionMode(false);
          setModified(false);
          setSubmodulesResetTrigger(true);
          updateDetail();
        } else if (res.Status === "KO") {
          toast("error", res.Msg);
        } else {
          toast("info", `${res.Status}: ${res.Msg}`);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const duplicarSuelo = (name, idPromocion) => {
    if (name === detalleSuelo.Name) {
      toast("error", "El nombre debe ser distinto");
      return;
    }
    if (idPromocion === detalleSuelo.IdPromocion) {
      toast("error", "El id de promoción debe ser distinto");
      return;
    }
    setLoading(true);
    const cloneData = {
      IdEntity: idSuelo,
      Name: name,
      IdPromocion: idPromocion,
    };
    API.cloneSuelo(cloneData)
      .then((res) => {
        setDialogDuplicarVisible(false);
        if (res.Status === "OK") {
          toast("success", "Suelo Duplicado");
        } else toast("error", res.Msg, 5000);
      })
      .catch((error) =>
        toast("error", `${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const generarReporte = (id) => {
    setLoading(true);
    setDialogEjecutarFichaVisible(false);
    const selectedOption = informesBirtOptions.find(
      (option) => option.value === id
    );

    const link = document.createElement("a");
    link.href = `${REACT_APP_INFORME_BIRT_URL}/birt-viewer/output?__report=${selectedOption.fichero}&idSuelo=${idSuelo}&__svg=true&__locale=es_ES&__timezone=Poland&__masterpage=true&__rtl=fa`;
    link.setAttribute("target", "_blank");
    link.click();

    setLoading(false);
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0 && type === "success") {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  const render_submenu_fields = (fields) => (
    <S.FieldsContainer>
      {fields.map((field, index) => {
        const {
          key,
          label,
          type,
          editable,
          required,
          relateLink,
          visibleCondition,
          AllowAddRelateEntity,
        } = field;

        let hidden = false;
        if (visibleCondition.condition !== "") {
          var valueCast = visibleCondition.value;
          if (visibleCondition.value === "true") {
            valueCast = true;
          }
          if (visibleCondition.value === "false") {
            valueCast = false;
          }

          if (
            visibleCondition.condition === "equal" &&
            detalleSuelo[visibleCondition.field] !== valueCast
          ) {
            hidden = true;
          }

          if (
            visibleCondition.condition === "not_equal" &&
            detalleSuelo[visibleCondition.field] === valueCast
          ) {
            hidden = true;
          }
        }

        const readOnly =
          permissions[module]?.edit === 0 ||
          editable === "0" ||
          (create &&
            location.inheritedFields &&
            Object.keys(location.inheritedFields).some(
              (field) => field === key
            ));
        switch (type) {
          case "text":
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleSuelo[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
                hidden={hidden}
              />
            );

          case "enum":
            const currentLabel =
              detalleSuelo[key] &&
              detalleSueloListas[key]?.SelectValues.find(
                (option) => option.Id === detalleSuelo[key]
              )?.Value;
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOption={{
                  value: detalleSuelo[key] || "",
                  label: currentLabel,
                }}
                options={
                  detalleSueloListas[key]?.Parent
                    ? detalleSueloListas[key]?.SelectValues.filter((option) =>
                        option.ParentId?.includes(
                          detalleSuelo[detalleSueloListas[key].Parent]
                        )
                      )
                    : detalleSueloListas[key]?.SelectValues
                }
                canBeEmpty={detalleSueloListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
                hidden={hidden}
              />
            );

          case "enum_multiple":
            const currentOptions = detalleSuelo[key]?.map((id) => {
              const option = {};
              option.value = id;
              option.label = detalleSueloListas[key]?.SelectValues.find(
                (option) => option.Id === id
              )?.Value;
              return option;
            });
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOptions={currentOptions}
                options={detalleSueloListas[key]?.SelectValues}
                canBeEmpty={detalleSueloListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
                hidden={hidden}
              />
            );

          case "relate":
            return (
              <FieldListSearch
                key={key}
                id={key}
                label={label}
                listName={detalleSueloListas[key]?.ListName}
                currentOption={{
                  value: detalleSuelo[key]?.Value || "",
                  label: detalleSuelo[key]?.Label,
                }}
                canBeEmpty={detalleSueloListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                relateLink={relateLink}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
                hidden={hidden}
                allowAddRelateEntity={AllowAddRelateEntity}
              />
            );

          case "textarea":
            return (
              <FieldTextArea
                key={key}
                id={key}
                label={label}
                value={detalleSuelo[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
                hidden={hidden}
              />
            );

          case "date":
            return (
              <FieldDate
                key={key}
                id={key}
                label={label}
                value={detalleSuelo[key]?.split("T")[0] || ""}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
              />
            );

          case "bool":
            return (
              <FieldCheckbox
                key={key}
                id={key}
                label={label}
                value={detalleSuelo[key] || false}
                handleChange={handleChange}
                readOnly={
                  editable === "0" && key !== "HitosACumplirProximamente"
                }
                hidden={hidden}
              />
            );

          case "int":
          case "float":
          case "decimal":
          case "currency":
            return (
              <FieldNumber
                key={key}
                id={key}
                label={label}
                value={detalleSuelo[key] || 0}
                empty={detalleSuelo[key] === "" || detalleSuelo[key] === null}
                handleChange={handleChange}
                type={type}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
                hidden={hidden}
              />
            );

          case "no_access":
            return (
              <FieldNoAccess key={key} id={key} label={label} hidden={hidden} />
            );

          case "panel":
            return (
              <li key={`panel${index}`} className="panel">
                {label}
              </li>
            );
          case "file":
            return (
              <FieldFile
                key={key}
                id={key}
                label={label}
                readOnly={readOnly}
                typeModule={module}
              />
            );
          case "Empty":
            return <li key={`empty${index}`} />;

          default:
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleSuelo[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleSuelo[key]
                }
                hidden={hidden}
              />
            );
        }
      })}
    </S.FieldsContainer>
  );

  const inheritedFields = () => {
    const inheritedFields = {
      SueloId: { Value: idSuelo, Label: detalleSuelo.Name },
    };
    return inheritedFields;
  };

  const isVisibleInTab = (section, module) => {
    const isPricingModule =
      module === "UB_UsosPricing" ||
      module === "UB_FasesConstruccion" ||
      module === "Documents_Pricing" ||
      module === "ub_pricing_hpa_cpi";
    const isJuridicosModule = module === "Documents_SituacionJuridica";
    const isnotModule =
      module === "UB_UsosPricing" ||
      module === "UB_FasesConstruccion" ||
      module === "Documents_Pricing" ||
      module === "ub_pricing_hpa_cpi" ||
      module === "Documents_SituacionJuridica";

    switch (section) {
      case "PRICING":
        return isPricingModule;
      case "SITUACIÓN JURÍDICA":
        return isJuridicosModule;
      default:
        return !isnotModule;
    }
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {dialogDuplicarVisible && (
        <DialogTwoInput
          message1="Nombre del nuevo suelo:"
          message2="ID de promoción del nuevo suelo:"
          initialValue1={detalleSuelo.Name}
          initialValue2={detalleSuelo.IdPromocion}
          labelConfirm="Duplicar"
          confirm={duplicarSuelo}
          cancel={() => setDialogDuplicarVisible(false)}
        />
      )}
      {dialogEjecutarFichaVisible && (
        <DialogSelect
          message="Seleccione el informe que desee:"
          initialValue={detalleSuelo.Name}
          labelConfirm="Ejecutar Ficha"
          confirm={generarReporte}
          cancel={() => setDialogEjecutarFichaVisible(false)}
          options={informesBirtOptions}
        />
      )}
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          <FieldText
            id="Name"
            value={detalleSuelo.Name || ""}
            handleChange={handleChange}
            form="form"
            type="title"
            placeholder="Nuevo nombre..."
            required={true}
            alwaysEditing={
              editionMode || create || initialValues.Name !== detalleSuelo.Name
            }
          />
        </S.WrapperFieldText>
        <S.ButtonsContainer>
          {editionMode || modified || create ? (
            <>
              <Button
                label="Cancelar"
                onClick={() => {
                  if (create) history.goBack();
                  else {
                    setDetalleSuelo(initialValues);
                    setEditionMode(false);
                    setModified(false);
                  }
                }}
                disabled={!editable || loading}
              />
              <Button
                label={create ? "Crear" : "Guardar"}
                onClick={saveSuelo}
                disabled={!editable || loading}
              />
            </>
          ) : (
            <>
              <Button
                label="Editar"
                onClick={() => {
                  setEditionMode(true);
                  setEditarOpened(false);
                }}
                disabled={!editable || loading}
              />
              <Button
                label="▾"
                onClick={() => setEditarOpened(!editarOpened)}
                disabled={create || !editable || loading}
              />
            </>
          )}
          {editarOpened && (
            <S.Ul>
              <S.Li onClick={handleDuplicar}>Duplicar</S.Li>
              <S.Li onClick={handleEjecutarFicha}>Ejecutar Ficha</S.Li>
              <Link
                to={{
                  pathname: `/UB_Suelo/${idSuelo}/link`,
                  sourceModule: {
                    type: "UB_Suelo",
                    id: idSuelo,
                  },
                  module: { type: "UB_Suelo", label: "Suelos" },
                }}
              >
                <S.Li>Asociar Suelos</S.Li>
              </Link>
              {detalleSuelo.IdSueloMaster && (
                <S.Li onClick={handleDesasociarIdMaster}>
                  Desasociar IdMaster
                </S.Li>
              )}
              <Link
                to={{
                  pathname: `/UB_Suelo/${idSuelo}/audit`,
                  entityname: detalleSuelo.Name,
                }}
              >
                <S.Li>Ver el registro de auditoría</S.Li>
              </Link>
            </S.Ul>
          )}
        </S.ButtonsContainer>
      </S.TitleBar>
      <S.SubMenuBar>
        <ul>
          <S.Submenu id="dummy" />
          {submenus.map((submenu) => (
            <S.Submenu
              key={submenu}
              id={submenu}
              className={submenu === section ? "active" : null}
              onClick={() => setSection(submenu)}
            >
              {submenu}
            </S.Submenu>
          ))}
          <S.Submenu id="dummy-end" />
        </ul>
      </S.SubMenuBar>
      <form id="form" ref={form} onSubmit={handleSubmit}>
        <S.Content>
          {submenus.map((submenu) => (
            <div
              key={submenu}
              className={submenu === section ? "active" : "inactive"}
            >
              {fields[submenu] && render_submenu_fields(fields[submenu])}
            </div>
          ))}
        </S.Content>
      </form>
      {!create && (
        <S.ModulesContainer>
          {detalleSuelo.ModulesCount &&
            Object.entries(detalleSuelo.ModulesCount)
              .filter((moduleInfo) => permissions[moduleInfo[0]]?.access === 1)
              .map((moduleInfo, index) => (
                <Module
                  key={index}
                  moduleInfo={moduleInfo}
                  sourceModule={{
                    type: "UB_Suelo",
                    id: idSuelo,
                    name: detalleSuelo.Name,
                  }}
                  inheritedFields={inheritedFields()}
                  getModule={API.getModuleSuelo}
                  updateDetail={updateDetail}
                  setLoading={setLoading}
                  access={permissions}
                  visibleInTab={isVisibleInTab(section, moduleInfo[0])}
                  submodulesResetTrigger={submodulesResetTrigger}
                  setSubmodulesResetTrigger={setSubmodulesResetTrigger}
                />
              ))}
        </S.ModulesContainer>
      )}
    </S.Container>
  );
};
