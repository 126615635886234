import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { MAX_UPLOAD_FILE_SIZE } from "../../constants";
import { Loading } from "../../components/Loading";

export const FieldFile = ({ id, label, typeModule, handleChange }) => {
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState("");
  const [data, setData] = useState("");
  const [fileext, setFileext] = useState("");
  const [mimetype, setMimeType] = useState("");
  const [ancho, setAncho] = useState(0);
  const [alto, setAlto] = useState(0);
  const refFile = useRef();
  const [mensaje, setMensaje] = useState("Cargando...");

  const isImagen = typeModule === "ESGdI_Imagenes" ? true : false;
  const maxUploadFileSizeMB = parseFloat(
    MAX_UPLOAD_FILE_SIZE / Math.pow(1024, 2) // Bytes to MiB
  ).toFixed(0);

  const convertBase64 = (e) => {
    if (isImagen === true) {
      getWidthAndHeight(e);
    }
    Array.from(e.target.files).forEach((archivo) => {
        if (archivo.size < MAX_UPLOAD_FILE_SIZE) {
            setMensaje("Preparando Documento...");
            setLoading(true);
        var reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onload = function () {
          var base64 = reader.result.toString().replace(/^data:(.*,)?/, "");
          handleChange && handleChange("Imagen", base64);
          var nameSplit = archivo.name.split(".");
          setFilename(archivo.name);
          setData(base64);
          setFileext(nameSplit[nameSplit.length - 1]);
          setMimeType(archivo.type);
          };
          setTimeout(function () {

              setLoading(false);
          }, (archivo.size / 1000));
        reader.onerror = (error) => console.log(error);
      } else {
        refFile.current.value = "";
        alert(
          `El tamaño del fichero excede el máximo permitido (${maxUploadFileSizeMB}MB)`
        );
      }
    });
  };

  const getWidthAndHeight = (e) => {
    Array.from(e.target.files).forEach((archivo) => {
      let img = new Image();
      img.src = window.URL.createObjectURL(archivo);
      img.onload = function () {
        setAncho(img.width);
        setAlto(img.height);
      };
    });
  };

  return (
      <div>
      <Loading hidden={!loading} mensaje={mensaje} />
      <S.Title>{label}</S.Title>
      <S.InputContainer>
        <S.InputWrapper>
          {isImagen ? (
            <S.Input
              type="file"
              accept=".jpg, .jpeg"
              onChange={convertBase64}
            />
          ) : (
            <S.Input type="file" onChange={convertBase64} ref={refFile} />
          )}
          <input hidden type="text" readOnly value={filename} name={id} />
          <input hidden type="text" readOnly value={data} name="Data" />
          <input hidden type="text" readOnly value={fileext} name="FileExt" />
          <input
            hidden
            type="text"
            readOnly
            value={mimetype}
            name="FileMimeType"
          />
          <input hidden type="text" readOnly value={ancho} name="Ancho" />
          <input hidden type="text" readOnly value={alto} name="Alto" />
        </S.InputWrapper>
      </S.InputContainer>
    </div>
  );
};

FieldFile.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  typeModule: PropTypes.string,
  handleChange: PropTypes.func,
};
