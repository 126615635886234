import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const Toast = ({ data, close }) => {
  return (
    <S.Container onClick={close}>
      <S.Wrapper type={data.type} onClick={(event) => event.stopPropagation()}>
        <S.TitleBar type={data.type}>
          <S.Close onClick={close}>×</S.Close>
        </S.TitleBar>
        <S.Content>{data.message}</S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

Toast.propTypes = {
  data: PropTypes.objectOf(PropTypes.string),
  close: PropTypes.func,
};
