import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.li`
  .hidden {
    display: none;
  }
`;

export const Title = styled.div`
  font-size: 0.75rem;
  color: ${COLORES.GRIS_OSCURO};
  margin-bottom: 0.2rem;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

export const Input = styled.input`
  font-size: 0.875rem;
  background-color: ${COLORES.FIELD_BACKGROUND};
  border: solid 1px ${COLORES.GRIS_CLARO};
  border-radius: 0.2rem;
  padding: 0 0.5rem;
  width: 100%;
  height: 30px;

  :disabled {
    border: none;
  }
`;

export const InputWrapper = styled.div`
  font-size: 0.875rem;
  display: contents;
`;
