import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldGridHour = ({
  field,
  value,
  beingEdited,
  handleChange,
  readOnly,
  required,
}) => {
  const editing = beingEdited && !readOnly;
  const valueSplit = value.split(":");
  const empty = valueSplit[0] === "" ;
  const visibleValue = empty
    ? ""
    : `${valueSplit[0]}:${valueSplit[1]}`;

  return (
    <S.Td title={value} beingEdited={beingEdited}>
      <div className={editing ? "visible" : "hidden"}>
        <S.Input
          type="time"
          name={field.key}
          value={empty ? "" : value}
          onChange={(e) => handleChange(field.key, e.target.value)}
          required={required}
        />
      </div>
      <div className={editing ? "hidden" : "visible"}>{visibleValue}</div>
    </S.Td>
  );
};

FieldGridHour.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.string,
  beingEdited: PropTypes.bool,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};
