import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldGridNoAccess = ({ field, value }) => {
  return (
    <S.Td title={value} type={field.type}>
      Sin Acceso
    </S.Td>
  );
};

FieldGridNoAccess.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.string,
};
