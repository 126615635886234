import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import { REGISTROS_POR_PAGINA_SUBMODULO } from "../../constants";
import { ListadoModulo } from "../../components/ListadoModulo";
import { Button } from "../../components/Button";
import { Link } from "react-router-dom";

export const Module = ({
  moduleInfo,
  sourceModule,
  inheritedFields,
  getModule,
  updateDetail,
  setLoading,
  access,
  visibleInTab,
  submodulesResetTrigger,
  setSubmodulesResetTrigger,
}) => {
  const [open, setOpen] = useState(false);
  const [wasOpened, setWasOpened] = useState(false);
  const [labels, setLabels] = useState([]);
  const [dataContent, setDataContent] = useState([]);
  const [moduleLists, setModuleLists] = useState({});
  const [recordsLoading, setRecordsLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [allShowed, setAllShowed] = useState(false);
  const [editarOpened, setEditarOpened] = useState(false);
  const [isVisible, setisVisible] = useState(false);
  const [sortActive, setSortActive] = useState({ order: "asc", orderField: "Inicial" });
  useEffect(() => {
    if (submodulesResetTrigger) {
      setOpen(false);
      setWasOpened(false);
      setSubmodulesResetTrigger(false);
    }
  }, [submodulesResetTrigger, setSubmodulesResetTrigger]);

  const module = moduleInfo[0];
  const {
    label,
    count,
    allowSaveNewElement,
    allowLinkElement,
    allowCreateRecordChild,
  } = moduleInfo[1];
  const allowCreate =
    access && access[module]?.edit === 1 && allowSaveNewElement;
  const allowLink = access && access[module]?.edit === 1 && allowLinkElement;
  if (allowCreateRecordChild)
    inheritedFields.ParentId = {
      Value: null,
      Label: "Alta de nueva fase",
    };

  const pagination = (page) => {
    return { Page: page, PageSize: REGISTROS_POR_PAGINA_SUBMODULO };
  };

  const ToggleOpen = () => {
    if (count > 0) {
      if (!wasOpened) {
        setRecordsLoading(true);
        setisVisible(label === "Documentos" && module === "Documents");
        let data = {};
        data.Module = module;
        data.RenderType = "grid";
        data.IdEntity = sourceModule?.id;
        API.renderPage(data)
          .then((res) => setLabels(res.Grid))
          .catch((error) => API.DEVELOP && console.log(error));
        getRegistros();
      }
      setOpen(!open);
    }
  };

  const getRegistros = (page = 1, order = "asc", orderField = "Inicial") => {
    setRecordsLoading(true);


    if (page === 1) {
      setAllShowed(false);
      API.getListByModule(module)
        .then((res) => setModuleLists(res))
        .catch((error) => API.DEVELOP && console.log(error));
    }

    let data = {};
    data.ModuleOrigin = sourceModule?.type;
    data.Pagination = pagination(page);
    data.IdEntity = sourceModule?.id;
    data.ModuleName = module;
    data.Order = order;
    data.OrderField = orderField;
    data.isVisible = isVisible;
    setSortActive({
      orderField: orderField,
      order: order,
    });
    getModule(data)
      .then((res) => {
        if (res) {
          setWasOpened(true);
          if (res.length === 0 && page === 1) {
            setOpen(false);
            setWasOpened(false);
          }
          if (res.length > 0) {
            if (page === 1) {
              setDataContent([]);
              setDataContent(res);
            } else setDataContent([...dataContent, ...res]);
          }
          if (res.length < REGISTROS_POR_PAGINA_SUBMODULO) {
            setAllShowed(true);
          }
          setPages(page);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error))
      .finally(() => setRecordsLoading(false));
  };



  const showMore = () => {
    debugger;
    getRegistros(pages + 1, sortActive.order, sortActive.orderField);
  }

  const updateRecords = (order, orderField) => {
    setRecordsLoading(true);
    getRegistros(1, order, orderField);
    updateDetail();
  };
  const orderChange = (order, orderField) => {
    debugger;
    updateRecords(order, orderField);
  };
  return (
    <S.Container className={visibleInTab ? "visible" : "inactive"}>
      <S.TitleContainer>
        <S.Title
          toggleable={count > 0 ? true : false}
          onClick={ToggleOpen}
        >{`${label.toUpperCase()} (${count})`}</S.Title>
        <S.ButtonsContainer alone={!allowCreate}>
          {allowCreate && (
            <Link
              to={{
                pathname: `/${module}/create`,
                sourceModule,
                inheritedFields,
              }}
            >
              <Button label="+" onClick={() => { }} disabled={false} />
            </Link>
          )}
          <Button
            label="▾"
            disabled={!allowLink}
            onClick={() => setEditarOpened(!editarOpened)}
          />
          {editarOpened && (
            <S.Ul>
              <Link
                to={{
                  pathname: `/${sourceModule?.type}/${sourceModule?.id}/link`,
                  sourceModule,
                  module: { type: module, label },
                }}
              >
                <S.Li>Vincular un registro existente</S.Li>
              </Link>
            </S.Ul>
          )}
        </S.ButtonsContainer>
      </S.TitleContainer>
      <S.Content open={open}>
        <ListadoModulo
          data={{ labels, dataContent, isVisible }}
          showMore={showMore}
          allShowed={allShowed}
          recordsLoading={recordsLoading}
          module={module}
          moduleLists={moduleLists}
          sourceModule={sourceModule}
          updateRecords={updateRecords}
          setLoading={setLoading}
          allowLinkElement={allowLink}
          allowCreateRecordChild={allowCreateRecordChild}
          inheritedFields={inheritedFields}
          access={access}
          setSubmodulesResetTrigger={setSubmodulesResetTrigger}
          orderChange={orderChange}
        />
      </S.Content>
    </S.Container>
  );
};

Module.propTypes = {
  moduleInfo: PropTypes.array.isRequired,
  sourceModule: PropTypes.objectOf(PropTypes.string),
  inheritedFields: PropTypes.objectOf(PropTypes.object),
  getModule: PropTypes.func.isRequired,
  updateDetail: PropTypes.func,
  setLoading: PropTypes.func,
  access: PropTypes.object,
  visibleInTab: PropTypes.bool,
  submodulesResetTrigger: PropTypes.bool,
  setSubmodulesResetTrigger: PropTypes.func,
};
