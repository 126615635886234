import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FilterRow = ({
  id,
  filter,
  selectableFilters,
  addFilter,
  removeFilter,
  changeFieldFilter,
  changeTextFilter,
}) => {
  return (
    <S.Container>
      <S.SelectField
        value={filter.FilterId}
        onChange={(e) => changeFieldFilter(id, e.target.value)}
      >
        <option hidden value="">
          Seleccionar...
        </option>
        {selectableFilters.map((filter, index) => (
          <option key={index} value={filter.FilterId}>
            {filter.FilterName}
          </option>
        ))}
      </S.SelectField>
      <S.Input
        value={filter.FilterValue}
        onChange={(e) => changeTextFilter(id, e.target.value)}
        disabled={filter === {} || filter.FilterId === ""}
        placeholder="Buscar..."
      />
      <S.Button onClick={() => removeFilter(id)} first={true}>
        -
      </S.Button>
      <S.Button onClick={addFilter} last={true}>
        +
      </S.Button>
    </S.Container>
  );
};

FilterRow.propTypes = {
  id: PropTypes.number.isRequired,
  filter: PropTypes.shape({
    FilterId: PropTypes.string,
    FilterName: PropTypes.string,
  }),
  selectableFilters: PropTypes.arrayOf(PropTypes.object),
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  changeFieldFilter: PropTypes.func.isRequired,
  changeTextFilter: PropTypes.func.isRequired,
};
