import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.li``;

export const Label = styled.div`
  font-size: 0.75rem;
  color: ${COLORES.GRIS_OSCURO};
  margin-bottom: 0.2rem;
`;

export const TextAreaContainer = styled.div`
  display: flex;
`;

export const TextArea = styled.textarea`
  font-size: 0.875rem;
  resize: vertical;
  width: 100%;
  background-color: ${COLORES.FIELD_BACKGROUND};
  border: solid 1px ${COLORES.GRIS_CLARO};
  border-color: ${COLORES.GRIS_CLARO};
  border-radius: 0.2rem;
  padding: 0 0.5rem;
  height: 3.1rem;
  min-height: 3.1rem;
  max-height: 10.1rem;
  ${(props) =>
    !props.editing &&
    css`
      padding: 0.0625rem 0.5625rem;
      border: none;
      resize: none;
      overflow: hidden;
    `}
`;

export const Text = styled.div`
  font-size: 0.875rem;
  display: block;
  padding: 1px 9px;
  overflow: hidden;
  height: 3.1rem;
  white-space: pre-wrap;
`;
