import styled from "styled-components/macro";

export const Td = styled.div`
  font-size: 0.75rem;
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.3875rem;
`;
