import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Tr = styled.form`
  display: table-row;
  border-top: 1px solid ${COLORES.FONDO_MENU};
  border-bottom: 1px solid ${COLORES.FONDO_MENU};
  background-color: ${(props) =>
    props.beingEdited ? COLORES.AZUL_HOVER : COLORES.BLANCO};
  background-color: ${(props) =>
    props.highlighted && COLORES.HIGHLIGHTED_RECORD};
  color: ${(props) => props.off && COLORES.SUBMODULE_DISABLED};
  :hover {
    background-color: ${(props) =>
      props.highlighted ? COLORES.HIGHLIGHTED_RECORD : COLORES.AZUL_HOVER};
  }
`;

export const TdEdit = styled.div`
  display: table-cell;
  text-align: center;
  padding-right: 0;
`;

export const Container = styled.div`
  font-size: 0.75rem;
`;

export const Button = styled.button`
  width: 1.6rem;
  padding: 0.2rem 0.4rem;
  margin: 0 0.2rem;
  border: 1px solid ${COLORES.GRIS};
  border-radius: 0.2rem;
  cursor: pointer;
  color: ${(props) => {
    if (props.styleType === "save") return COLORES.AZUL;
    if (props.styleType === "cancel") return COLORES.ROJO;
    return COLORES.NEGRO;
  }};

  :disabled {
    color: ${COLORES.GRIS_OSCURO};
    background-color: ${COLORES.GRIS_CLARO};
    cursor: not-allowed;
  }
`;

export const Ul = styled.ul`
  position: absolute;
  right: 0.5rem;
  list-style: none;
  background-color: ${COLORES.BLANCO};
  border: 1px solid lightgray;
  border-radius: 0.2rem;
`;

export const Li = styled.li`
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: start;
  padding: 0 0.5rem;
  border-bottom: 1px solid lightgrey;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  color: ${(props) => (props.disabled ? COLORES.GRIS_CLARO : COLORES.NEGRO)};
  :nth-last-child(1) {
    border-bottom: none;
  }
`;
