import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Td = styled.div`
  font-size: 0.75rem;
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: ${(props) => (props.beingEdited ? "0 0.2rem" : "0 0.3875rem")};
  .hidden {
    display: none;
  }
`;

export const Input = styled.input`
  height: 1.4rem;
  width: 100%;
  border-radius: 2px;
  border: 1px solid ${COLORES.GRIS_CLARO};
  box-sizing: border-box;
  outline: none;
  :focus {
    border: 1px solid ${COLORES.AZUL};
  }
`;
