import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { useParams, useHistory } from "react-router-dom";
import * as API from "../../api";
import { FieldText } from "../../components/FieldText";
import { FieldNumber } from "../../components/FieldNumber";
import { FieldTextArea } from "../../components/FieldTextArea";
import { Button } from "../../components/Button";
import { Module } from "../../components/Module";
import { Loading } from "../../components/Loading";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST } from "../../constants";
import { usePermissions } from "../../context/PermissionsContext";

export const DetalleZonas = () => {
  const { idZone } = useParams();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [detalleZona, setDetalleZona] = useState({});
  const [usos, setUsos] = useState([]);
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const [editionMode, setEditionMode] = useState(false);
  const [modified, setModified] = useState(false);
  const form = useRef();
  const timerToast = useRef();
  const history = useHistory();
  useEffect(() => {
    API.getUsosPricing()
      .then((res) => setUsos(res))
      .catch((error) => API.DEVELOP && console.log(error));
    if (idZone === "create") {
      setLoading(false);
    } else {
      API.getZone(idZone)
        .then((res) => {
          setDetalleZona(res);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
          error.code !== 401 &&
            toast("error", `${error.msg?.Message} (código ${error.code})`);
          setTimeout(() => history.push("/Zonas"), DEFAULT_TIMER_TOAST - 100);
        })
        .finally(() => setLoading(false));
    }
  }, [idZone, history]);
  useEffect(() => {
    document.addEventListener("wheel", blurActiveInputNumber);
    return () => document.removeEventListener("wheel", blurActiveInputNumber);
  }, []);

  const prices = ["PrecioVenta", "PrecioConstruccion", "PrecioUnitario"];
  const create = idZone === "create";
  const blurActiveInputNumber = () =>
    document.activeElement.type === "number" && document.activeElement.blur();

  const handleChange = (key, value) => {
    setModified(true);
    const newDetalleZona = { ...detalleZona };
    newDetalleZona[key] = value;
    setDetalleZona(newDetalleZona);
  };

  const handleChangePrices = (uso, price, value) => {
    setModified(true);
    const newDetalleZona = { ...detalleZona };
    if (!newDetalleZona.Uso) newDetalleZona.Uso = {};
    if (!newDetalleZona.Uso[uso]) newDetalleZona.Uso[uso] = {};
    newDetalleZona.Uso[uso][price] = value;
    setDetalleZona(newDetalleZona);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveZone();
  };

  const saveZone = () => {
    setLoading(true);
    const data = {};
    data.Id = create ? "00000000-0000-0000-0000-000000000000" : idZone;
    data.Name = detalleZona.Name;
    data.Description = detalleZona.Description;
    data.Uso = detalleZona.Uso || {};

    API.saveZone(data)
      .then((res) => {
        if (res.Status === "OK") {
          setEditionMode(false);
          setModified(false);
          if (create) {
            toast("success", "Zona Creada");
            setTimeout(() => history.push("/Zonas"), DEFAULT_TIMER_TOAST - 100);
          } else {
            toast("success", "Zona Guardada");
          }
        } else if (res.Status === "KO") {
          toast("error", res.Msg, 5000);
        } else if (res.Status === "NeedReload") {
          toast(
            "error",
            "Se encontró una version mas reciente de la zona.\nSe requiere recargar (se perderán los cambios no guardados)",
            5000
          );
        } else {
          toast("info", res.Status, 5000);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const updateDetail = () => {
    API.getZone(idZone)
      .then((res) => {
        setDetalleZona(res);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
        setTimeout(() => history.push("/Zonas"), DEFAULT_TIMER_TOAST - 100);
      })
      .finally(() => setLoading(false));
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0) {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          <FieldText
            id="Name"
            value={detalleZona.Name || ""}
            handleChange={handleChange}
            form="form"
            type="title"
            placeholder="Nuevo nombre..."
            required={true}
            alwaysEditing={editionMode || create}
          />
        </S.WrapperFieldText>
        <S.ButtonsContainer>
          {editionMode || modified || create ? (
            <Button
              label={create ? "Crear" : "Guardar"}
              onClick={saveZone}
              disabled={loading}
            />
          ) : (
            <>
              <Button
                label="Editar"
                onClick={() => setEditionMode(true)}
                disabled={loading}
              />
            </>
          )}
        </S.ButtonsContainer>
      </S.TitleBar>
      <form id="form" ref={form} onSubmit={handleSubmit}>
        <S.Content>
          <S.FieldsContainer>
            <FieldTextArea
              id="Description"
              label="Descripción"
              value={detalleZona.Description || ""}
              handleChange={handleChange}
              required={true}
              alwaysEditing={editionMode || create}
            />
            <S.Permits columns={prices.length + 1}>
              <S.ModuleRow>
                <p>Usos</p>
                {prices.map((action) => (
                  <p key={action}>{action}</p>
                ))}
              </S.ModuleRow>
              {usos.map((uso) => (
                <S.ModuleRow key={uso}>
                  <p>{uso}</p>
                  {prices.map((price) => {
                    let readOnly = false;
                    const value =
                      detalleZona.Uso &&
                      detalleZona.Uso[uso] &&
                      detalleZona.Uso[uso][price];
                    if (
                      uso !== "Garajes Libres (€/unidad)" &&
                      uso !== "Garajes VPO (€/unidad)" &&
                      uso !== "Trasteros (€/unidad)" &&
                      price === "PrecioUnitario"
                    ) {
                      readOnly = true;
                    }

                    return (
                      <FieldNumber
                        key={price}
                        id={price}
                        value={value || 0}
                        empty={!value}
                        readOnly={readOnly}
                        handleChange={(id, value) =>
                          handleChangePrices(uso, id, value)
                        }
                        alwaysEditing={editionMode || create}
                      />
                    );
                  })}
                </S.ModuleRow>
              ))}
            </S.Permits>
          </S.FieldsContainer>
        </S.Content>
      </form>
      {!create && (
        <S.ModulesContainer>
          {detalleZona.ModulesCount &&
            Object.entries(detalleZona.ModulesCount)
              .filter((moduleInfo) => permissions[moduleInfo[0]]?.access === 1)
              .map((moduleInfo, index) => (
                <Module
                  key={index}
                  moduleInfo={moduleInfo}
                  sourceModule={{
                    type: "Zonas",
                    id: idZone,
                    name: detalleZona.Name,
                  }}
                  inheritedFields={{}}
                  getModule={API.getModulesByOrigin}
                  updateDetail={updateDetail}
                  setLoading={setLoading}
                  access={permissions}
                  visibleInTab={true}
                />
              ))}
        </S.ModulesContainer>
      )}
    </S.Container>
  );
};
