import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { useParams, useHistory } from "react-router-dom";
import * as API from "../../api";
import { FieldText } from "../../components/FieldText";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldTextArea } from "../../components/FieldTextArea";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { FieldNumber } from "../../components/FieldNumber";
import { FieldNoAccess } from "../../components/FieldNoAccess";
import { Module } from "../../components/Module";
import { Loading } from "../../components/Loading";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST } from "../../constants";
import { usePermissions } from "../../context/PermissionsContext";

export const DetalleFincasRe = () => {
  const { idFincaRe } = useParams();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("");
  const [submenus, setSubmenus] = useState([]);
  const [fields, setFields] = useState({});
  const [detalleFincaRe, setDetalleFincaRe] = useState({});
  const [detalleFincaReListas, setDetalleFincaReListas] = useState({});
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const form = useRef();
  const timerToast = useRef();
  const history = useHistory();
  useEffect(() => {
    const dataRenderPage = {
      Module: "UB_FincasRe",
      RenderType: "detail",
    };
    API.renderPage(dataRenderPage)
      .then((res) => {
        const submenus = Object.keys(res);
        setSubmenus(submenus);
        setSection(submenus[0]);
        setFields(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
    API.getListByModule("UB_FincasRe")
      .then((res) => {
        setDetalleFincaReListas(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);
  useEffect(() => {
    API.getFincaRe(idFincaRe)
      .then((res) => {
        setDetalleFincaRe(res);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
        setTimeout(
          () => history.push("/UB_FincasRe"),
          DEFAULT_TIMER_TOAST - 100
        );
      })
      .finally(() => setLoading(false));
  }, [idFincaRe, history]);
  useEffect(() => {
    document.addEventListener("wheel", blurActiveInputNumber);
    return () => document.removeEventListener("wheel", blurActiveInputNumber);
  }, []);

  const blurActiveInputNumber = () =>
    document.activeElement.type === "number" && document.activeElement.blur();

  const handleChange = (key, value) => {
    const newDetalleFincaRe = { ...detalleFincaRe };
    newDetalleFincaRe[key] = value;
    setDetalleFincaRe(newDetalleFincaRe);
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0) {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  const render_submenu_fields = (fields) => (
    <S.FieldsContainer>
      {fields.map((field, index) => {
        const { key, label, type, editable, relateLink } = field;

        switch (type) {
          case "text":
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleFincaRe[key] || ""}
                readOnly={editable === "0"}
              />
            );

          case "enum":
            const currentLabel =
              detalleFincaRe[key] &&
              detalleFincaReListas[key]?.SelectValues.find(
                (option) => option.Id === detalleFincaRe[key]
              )?.Value;
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOption={{
                  value: detalleFincaRe[key] || "",
                  label: currentLabel,
                }}
                options={detalleFincaReListas[key]?.SelectValues}
                readOnly={editable === "0"}
                canBeEmpty={detalleFincaReListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
              />
            );

          case "enum_multiple":
            const currentOptions = detalleFincaRe[key]?.map((id) => {
              const option = {};
              option.value = id;
              option.label = detalleFincaReListas[key]?.SelectValues.find(
                (option) => option.Id === id
              )?.Value;
              return option;
            });
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOptions={currentOptions}
                options={detalleFincaReListas[key]?.SelectValues}
                readOnly={editable === "0"}
                canBeEmpty={detalleFincaReListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
              />
            );

          case "relate":
            return (
              <FieldListSearch
                key={key}
                id={key}
                label={label}
                listName={detalleFincaReListas[key]?.ListName}
                currentOption={{
                  value: detalleFincaRe[key]?.Value || "",
                  label: detalleFincaRe[key]?.Label,
                }}
                readOnly={editable === "0"}
                canBeEmpty={detalleFincaReListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                relateLink={relateLink}
              />
            );

          case "textarea":
            return (
              <FieldTextArea
                key={key}
                id={key}
                label={label}
                readOnly={editable === "0"}
                value={detalleFincaRe[key] || ""}
              />
            );

          case "bool":
            return (
              <FieldCheckbox
                key={key}
                id={key}
                label={label}
                readOnly={editable === "0"}
                value={detalleFincaRe[key] || false}
              />
            );

          case "int":
          case "float":
          case "decimal":
            return (
              <FieldNumber
                key={key}
                id={key}
                label={label}
                readOnly={editable === "0"}
                value={detalleFincaRe[key] || 0}
                empty={
                  detalleFincaRe[key] === "" || detalleFincaRe[key] === null
                }
                type={type}
              />
            );

          case "Empty":
            return <li key={`empty${index}`} />;

          case "no_access":
            return <FieldNoAccess key={key} id={key} label={label} />;

          default:
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleFincaRe[key] || ""}
                readOnly={editable === "0"}
              />
            );
        }
      })}
    </S.FieldsContainer>
  );

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          <FieldText
            id="Name"
            value={detalleFincaRe.Name || ""}
            form="form"
            type="title"
          />
        </S.WrapperFieldText>
      </S.TitleBar>
      <S.SubMenuBar>
        <ul>
          <S.Submenu id="dummy" />
          {submenus.map((submenu) => (
            <S.Submenu
              key={submenu}
              className={submenu === section ? "active" : null}
              onClick={() => setSection(submenu)}
            >
              {submenu}
            </S.Submenu>
          ))}
          <S.Submenu id="dummy-end" />
        </ul>
      </S.SubMenuBar>
      <form id="form" ref={form}>
        {submenus.map((submenu) => (
          <div
            key={submenu}
            className={submenu === section ? "active" : "inactive"}
          >
            {fields[submenu] && render_submenu_fields(fields[submenu])}
          </div>
        ))}
      </form>
      <S.ModulesContainer>
        {detalleFincaRe.ModulesCount &&
          Object.entries(detalleFincaRe.ModulesCount)
            .filter((moduleInfo) => permissions[moduleInfo[0]]?.access === 1)
            .map((moduleInfo, index) => (
              <Module
                key={index}
                moduleInfo={moduleInfo}
                sourceModule={{
                  type: "UB_FincaRe",
                  id: idFincaRe,
                  name: detalleFincaRe.Name,
                }}
                getModule={API.getModuleFincaRe}
                setLoading={setLoading}
                access={permissions}
                visibleInTab={true}
              />
            ))}
      </S.ModulesContainer>
    </S.Container>
  );
};
