import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

import { Suelos } from "../pages/Suelos";
import { FincasAp } from "../pages/FincasAp";
import { FincasRe } from "../pages/FincasRe";
import { Usuarios } from "../pages/Usuarios";
import { DatosPrinex } from "../pages/DatosPrinex";
import { DetalleSuelos } from "../pages/DetalleSuelos";
import { DetalleFincasAp } from "../pages/DetalleFincasAp";
import { DetalleFincasRe } from "../pages/DetalleFincasRe";
import { DetalleDatoPrinex } from "../pages/DetalleDatoPrinex";
import { DetalleModulo } from "../pages/DetalleModulo";
import { DetalleRoles } from "../pages/DetalleRoles";
import { DetalleZonas } from "../pages/DetalleZonas";
import { Vincular } from "../pages/Vincular";
import { Auditoria } from "../pages/Auditoria";
import { NotFound } from "../pages/NotFound";
import { NoAccess } from "../pages/NoAccess";
import { Roles } from "../pages/Roles";
import { Zonas } from "../pages/Zonas";
import { Mapa } from "../pages/Mapa";
import { ProtectedRoute } from "./protected";

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <ProtectedRoute permit="UB_Suelo" redirectPath="/noAccess">
          <Redirect to="/UB_Suelo" />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_Suelo">
        <ProtectedRoute permit="UB_Suelo" redirectPath="/noAccess">
          <Suelos />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_Suelo/:idSuelo">
        <ProtectedRoute permit="UB_Suelo" redirectPath="/noAccess">
          <DetalleSuelos />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_FincasAp">
        <ProtectedRoute permit="UB_FincasAp" redirectPath="/noAccess">
          <FincasAp />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_FincasAp/:idFincaAp">
        <ProtectedRoute permit="UB_FincasAp" redirectPath="/noAccess">
          <DetalleFincasAp />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_FincasRe">
        <ProtectedRoute permit="UB_FincasRe" redirectPath="/noAccess">
          <FincasRe />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_FincasRe/:idFincaRe">
        <ProtectedRoute permit="UB_FincasRe" redirectPath="/noAccess">
          <DetalleFincasRe />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_datos_prinex">
        <ProtectedRoute permit="UB_datos_prinex" redirectPath="/noAccess">
          <DatosPrinex />
        </ProtectedRoute>
      </Route>
      <Route exact path="/UB_datos_prinex/:idDatoPrinex">
        <ProtectedRoute permit="UB_datos_prinex" redirectPath="/noAccess">
          <DetalleDatoPrinex />
        </ProtectedRoute>
      </Route>
      <Route exact path="/Users">
        <ProtectedRoute permit="Users" redirectPath="/noAccess">
          <Usuarios />
        </ProtectedRoute>
      </Route>
      <Route exact path="/Roles">
        <Roles />
      </Route>
      <Route exact path="/Roles/:idRole">
        <DetalleRoles />
      </Route>
      <Route exact path="/Zonas">
        <ProtectedRoute permit="Zonas" redirectPath="/noAccess">
          <Zonas />
        </ProtectedRoute>
      </Route>
      <Route exact path="/Zonas/:idZone">
        <ProtectedRoute permit="Zonas" redirectPath="/noAccess">
          <DetalleZonas />
        </ProtectedRoute>
      </Route>
      <Route exact path="/:module/:id">
        <DetalleModulo />
      </Route>
      <Route exact path="/:module/:id/link">
        <Vincular />
      </Route>
      <Route exact path="/:module/:id/audit">
        <Auditoria />
      </Route>
      <Route exact path="/UB_Suelo_Geocerca">
        <Mapa />
      </Route>
      <Route exact path="/noAccess">
        <NoAccess />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

Routes.propTypes = {
  access: PropTypes.object,
};
