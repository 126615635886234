import React, { useState /*useEffect*/ } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";

export const FieldText = ({
  id,
  label,
  value,
  handleChange,
  placeholder,
  readOnly,
  required,
  alwaysEditing,
  hidden,
  form,
  type,
  link,
  idEntity,
  typeModule,
  documentName,
  fileExt,
  toast,
}) => {
  const [focused, setFocused] = useState(false);

  const editing = !readOnly && (focused || alwaysEditing);

  const downloadElement = (id, typeMod) => {
    if (typeMod === "Documents"||typeMod === "Documents_Pricing") {
      API.downloadDocument(id)
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", value);
          link.click();
        })
        .catch((error) => {
          error.code === 410
            ? toast("error", `Documento no encontrado (código ${error.code})`)
            : toast("error", `${error.msg?.Message} (código ${error.code})`);
          API.DEVELOP && console.log(error);
        });
    } else if (typeMod === "Document_Revisions") {
      API.downloadDocumentRevision(id)
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", value);
          link.click();
        })
        .catch((error) => {
          error.code === 410
            ? toast("error", `Revisión no encontrada (código ${error.code})`)
            : toast("error", `${error.msg?.Message} (código ${error.code})`);
          API.DEVELOP && console.log(error);
        });
    } else if (typeMod === "ESGdI_Imagenes") {
      API.downloadImagen(id)
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", documentName + "." + fileExt);
          link.click();
        })
        .catch((error) => {
          error.code === 410
            ? toast("error", `Imagen no encontrada (código ${error.code})`)
            : toast("error", `${error.msg?.Message} (código ${error.code})`);
          API.DEVELOP && console.log(error);
        });
    }
  };

  return (
    <S.Container
      onClick={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={hidden ? "hidden" : "visible"}
    >
      {label && <S.Title>{label}</S.Title>}
      <S.InputContainer>
        <S.InputWrapper className={editing ? "visible" : "hidden"}>
          <S.Input
            name={id}
            value={value}
            onChange={(e) => handleChange(id, e.target.value)}
            form={form}
            placeholder={placeholder}
            readOnly={readOnly}
            required={required}
          />
        </S.InputWrapper>
        <S.Text className={editing ? "hidden" : "visible"} type={type}>
          {link ? (
            <S.Download onClick={() => downloadElement(idEntity, typeModule)}>
              Descargar
            </S.Download>
          ) : (
            value
          )}
        </S.Text>
      </S.InputContainer>
    </S.Container>
  );
};

FieldText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  alwaysEditing: PropTypes.bool,
  hidden: PropTypes.bool,
  form: PropTypes.string,
  type: PropTypes.string,
  idEntity: PropTypes.string,
  typeModule: PropTypes.string,
  documentName: PropTypes.string,
  fileExt: PropTypes.string,
  toast: PropTypes.func,
};
