import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { NavMenu } from "../../../components/NavMenu";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DefaultLayout = ({ access, children }) => {
  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  return (
    <>
      <NavMenu pathname={pathname} access={access} />
      {children}
      <ToastContainer theme="colored" />
    </>
  );
};

DefaultLayout.propTypes = {
  access: PropTypes.object,
  children: PropTypes.element,
};
