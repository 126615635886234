import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  background-color: ${COLORES.FONDO_MENU};
  height: 2rem;
  border-bottom: thin dashed ${COLORES.GRIS_CLARO};

  :nth-last-child(1) {
    border-bottom: none;
  }
`;

export const SelectField = styled.select`
  flex-grow: 1;
  margin-right: 0.5rem;
  height: 1.5rem;
  padding: 1px;
  font-size: 0.75rem;
  color: ${COLORES.FILTERS_TEXT};
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 3px;
`;

export const Input = styled.input`
  flex-grow: 3;
  margin-right: 0.5rem;
  height: 1.5rem;
  box-sizing: border-box;
  font-size: 0.75rem;
  color: ${COLORES.FILTERS_TEXT};
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 3px;
  padding-left: 0.4rem;

  ::placeholder {
    color: ${COLORES.GRIS_CLARO};
  }
`;

export const Button = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: ${(props) =>
    props.first ? "3px 0 0 3px" : props.last ? "0 3px 3px 0" : "0px"};
  color: ${COLORES.NEGRO};
  background-color: ${COLORES.SELECT_BORDER};
  cursor: pointer;

  :hover {
    background-color: ${COLORES.GRIS_CLARO};
  }
`;
