import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  display: grid;
`;

export const TitleBar = styled.div`
  position: sticky;
  top: calc(2.5rem + 1px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: ${COLORES.FONDO_MENU};
  border-bottom: solid 1px ${COLORES.GRIS_CLARO};
  padding: 0 2rem;
  font-weight: bold;
`;

export const WrapperFieldText = styled.div`
  flex-grow: 1;
  font-weight: 600;
`;

export const ButtonsContainer = styled.div`
  align-self: flex-start;
  margin: auto 0 auto 1rem;

  button {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0;
    padding: 0.35rem 0.5rem;
    border: thin solid ${COLORES.NEGRO};
    border-right: none;
    border-radius: 0;
    :hover {
      background-color: ${COLORES.AZUL_HOVER_DARK};
    }
  }
  button:first-of-type {
    border-radius: 0.2rem 0 0 0.2rem;
  }
  button:last-of-type {
    border-radius: 0 0.2rem 0.2rem 0;
    border-right: thin solid ${COLORES.NEGRO};
  }
  button:only-of-type {
    border: thin solid ${COLORES.NEGRO};
    border-radius: 0.2rem;
  }
`;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  height: 2.3rem;
  background-color: ${COLORES.FONDO_MENU};
  border-bottom: solid 1px ${COLORES.GRIS_CLARO};
  padding: 0 0.5rem;
  ul {
    display: flex;
  }
  li {
    list-style: none;
  }
`;

export const FilterBars = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: thin solid ${COLORES.GRIS_CLARO};
  padding: 0 0.5rem;
`;

export const SelectSearchBar = styled.select`
  height: 1.7rem;
  box-sizing: border-box;
  margin-right: 0.1rem;
  padding-left: 0.2rem;
  font-size: 0.7rem;
  font-weight: 600;
  color: ${COLORES.BLANCO};
  background-color: ${COLORES.AZUL};
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 0.2rem;
`;

export const ButtonSearchBar = styled.button`
  height: 1.7rem;
  margin-right: 0.1rem;
  font-size: 0.7rem;
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 0.2rem;
`;

export const InputSearchBar = styled.input`
  font-size: 0.75rem;
  flex-grow: 1;
  height: 1.7rem;
  box-sizing: border-box;
  border: thin solid ${COLORES.PLACEHOLDER_BORDER};
  border-radius: 0.2rem;
  padding-left: 0.4rem;

  ::placeholder {
    color: ${COLORES.PLACEHOLDER_TEXT};
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  font-size: 0.75rem;

  thead {
    tr {
      height: 2.7rem;
    }
    td {
      background-color: ${COLORES.FONDO};
      border: thin solid ${COLORES.GRIS_CLARO};
      border-top: none;
      width: "auto";
    }
    td:nth-child(1) {
      width: 5%;
    }
    td#Name {
      width: 35%;
    }
  }
  tbody {
    tr {
      height: 2rem;
      border-top: thin solid ${COLORES.FONDO_MENU};
      border-bottom: thin solid ${COLORES.FONDO_MENU};

      :hover {
        background-color: ${COLORES.AZUL_HOVER};
      }
    }
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0.4rem;
    }
    td:nth-child(1) {
      text-align: center;
    }
  }
  a {
    color: ${COLORES.AZUL_LINK};
    text-decoration: none;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
`;

export const More = styled.button`
  font-size: 0.8rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border: none;
  background-color: ${COLORES.BLANCO};
  color: ${COLORES.AZUL};
  cursor: pointer;
  padding: 0rem 1.5rem;

  &:disabled {
    color: ${COLORES.GRIS_CLARO};
    cursor: default;
  }
`;

export const TdThead = styled.td`
  &.active {
    background-color: ${COLORES.FONDO_MENU};
    div {
      font-weight: bold;
    }
  }
`;

export const HeaderColumn = styled.div`
  display: flex;
  font-size: 0.7rem;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.4rem;
  padding-right: 0.3rem;
`;
