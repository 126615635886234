import styled from "styled-components/macro";

export const Container = styled.div`
  font-size: 0.75rem;
  display: table-cell;
  vertical-align: middle;
  padding: ${(props) => (props.editing ? "0 0.2rem" : "0 0.3875rem")};
  .hidden {
    display: none;
  }
`;

export const SelectContainer = styled.div`
  display: block;
`;

export const Text = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
