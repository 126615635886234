import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Table = styled.div`
  display: table;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  font-size: 0.9rem;

  a {
    color: ${COLORES.AZUL_LINK};
    text-decoration: none;
  }
`;

export const Tr = styled.div`
  font-size: 0.6875rem;
  display: table-row;
`;

export const Th = styled.div`
  display: table-cell;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 0.2rem;
  resize: horizontal;
  border: thin solid ${COLORES.FONDO_MENU};
`;

export const ThEdit = styled.div`
  display: table-cell;
  width: 5rem;
`;

export const Pagination = styled.div`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
`;

export const More = styled.button`
  border: none;
  background-color: ${COLORES.BLANCO};
  color: ${COLORES.AZUL};
  cursor: pointer;
  padding: 0.5rem 1.5rem;

  &:disabled {
    color: ${COLORES.GRIS_CLARO};
    cursor: default;
  }
`;

export const Loading = styled.p`
  color: ${COLORES.GRIS};
  line-height: 1.15rem;
  padding: 0.5rem 1.5rem;
`;
