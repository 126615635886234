import React from "react";

export const NoAccess = () => {
  return (
    <div>
      <p>No está autorizado a utilizar esta aplicación.</p>
      <p>Consulte con el administrador.</p>
    </div>
  );
};
