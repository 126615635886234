import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { Link } from "react-router-dom";

export const FieldGridText = ({
  field,
  value,
  beingEdited,
  handleChange,
  link,
  infoLink,
  readOnly,
  required,
}) => {
  const editing = beingEdited && !readOnly;

  return (
    <S.Td title={value} type={field.type} beingEdited={beingEdited}>
      <div className={editing ? "visible" : "hidden"}>
        <S.Input
          name={field.key}
          value={value}
          onChange={(e) => handleChange(field.key, e.target.value)}
          required={required}
        />
      </div>
      <div className={editing ? "hidden" : "visible"}>
        {link ? (
          <Link to={`/${infoLink.module}/${infoLink.id}`}>{value}</Link>
        ) : (
          value
        )}
      </div>
    </S.Td>
  );
};

FieldGridText.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.string,
  beingEdited: PropTypes.bool,
  handleChange: PropTypes.func,
  link: PropTypes.bool,
  infoLink: PropTypes.object,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};
