import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: ${COLORES.BLANCO};
  border-bottom: solid 1px ${COLORES.GRIS_CLARO};
  font-size: 0.75rem;
  z-index: 5;
`;

export const Img = styled.img`
  height: 1.5rem;
  margin: 0 0.75rem;
`;

export const MenuContainer = styled.div`
  ul {
    display: flex;
    height: 2.5rem;
    align-items: center;
  }

  li {
    list-style: none;
    line-height: 1rem;
    padding: 0.75rem 0rem;

    :hover {
      background-color: ${COLORES.MENU_HOVER};
    }

    &.active {
      background-color: ${COLORES.MENU_ACTIVO};
    }
  }

  a,
  p {
    display: inline;
    color: ${COLORES.NEGRO};
    text-decoration: none;
    line-height: 1rem;
    padding: 0.75rem 1.5rem;
  }
`;

export const SubmenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  height: 2.5rem;
  width: auto;
  background-color: ${COLORES.FONDO};
  cursor: pointer;
  li {
    width: 100%;
    background-color: ${COLORES.FONDO};
    border-radius: 0.2rem;
    border: thin solid ${COLORES.GRIS_CLARO};
    border-bottom: none;
  }
  li:first-of-type {
    border: none;
  }
  li:last-of-type {
    border-bottom: thin solid ${COLORES.GRIS_CLARO};
  }
`;

export const UserContainer = styled.div`
  display: flex;
  margin-left: auto;

  button {
    color: black;
    background-color: white;
    height: 1.75rem;
    width: 1.75rem;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0 2rem 0 1rem;
    padding: 0.35rem 0.5rem;
    border: none;
    border: thin solid ${COLORES.GRIS_CLARO};
    :hover {
      background-color: ${COLORES.GRIS_CLARO};
    }
  }

  p {
    display: inline;
    color: ${COLORES.NEGRO};
    text-decoration: none;
    line-height: 1rem;
    padding: 0.75rem 0rem;
  }
`;

export const User = styled.p`
  font-weight: 600;
`;

export const ButtonsContainer = styled.div`
  align-self: center;
`;

export const Ul = styled.ul`
  list-style: none;
  background-color: ${COLORES.BLANCO};
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 0.2rem;
  font-size: 0.9rem;
  position: absolute;
  right: 2rem;
  text-align: right;

  a {
    text-decoration: none;
    color: ${COLORES.NEGRO};
  }

  #last {
    border-bottom: none;
  }
`;

export const Li = styled.li`
  font-size: 0.75rem;
  font-weight: normal;
  margin: auto;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 0.5rem;
  border-bottom: thin solid ${COLORES.GRIS_CLARO};
  cursor: pointer;
  :hover {
    background-color: ${COLORES.AZUL_HOVER};
  }
  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORES.GRIS};
      cursor: auto;
    `}
`;
