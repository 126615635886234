import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  display: grid;
`;

export const TitleBar = styled.div`
  position: sticky;
  top: calc(2.5rem + 1px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: ${COLORES.FONDO_MENU};
  border-bottom: thin solid ${COLORES.GRIS_CLARO};
  padding: 0 1.5rem;
  font-weight: bold;
`;

export const ButtonsContainer = styled.div`
  align-self: flex-start;
  margin: 0.7rem 1rem;

  button {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0;
    padding: 0.35rem 0.5rem;
    border: thin solid ${COLORES.NEGRO};
    :hover {
      background-color: ${COLORES.AZUL_HOVER_DARK};
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  font-size: 0.75rem;

  thead {
    tr {
      height: 2.7rem;
    }
    td {
      background-color: ${COLORES.FONDO};
      border: thin solid ${COLORES.GRIS_CLARO};
      border-top: none;
    }
    td:nth-child(3) {
      width: 60%;
    }
    td:nth-child(1),
    td:nth-child(2) {
      width: 20%;
    }
  }
  tbody {
    tr {
      height: 2rem;
      border-top: thin solid ${COLORES.FONDO_MENU};
      border-bottom: thin solid ${COLORES.FONDO_MENU};

      :hover {
        background-color: ${COLORES.AZUL_HOVER};
      }
    }
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 0.4rem;
    }
  }
  a {
    color: ${COLORES.AZUL_LINK};
    text-decoration: none;
  }
`;

export const TdThead = styled.td`
  &.active {
    background-color: ${COLORES.FONDO_MENU};
    div {
      font-weight: bold;
    }
  }
`;

export const HeaderColumn = styled.div`
  display: flex;
  font-size: 0.7rem;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.4rem;
  padding-right: 0.3rem;
`;
