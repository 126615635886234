import styled from "styled-components/macro";

export const Td = styled.div`
  font-size: 0.75rem;
  display: table-cell;
  padding: 0 0.2rem;
`;

export const Input = styled.input`
  vertical-align: text-bottom;
  width: 100%;
  height: 1rem;
`;
