import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { Link } from "react-router-dom";
import * as API from "../../api";
import {
  DELAY_BUSCADOR_SUELOS,
  SUELOS_POR_PAGINA,
  TITULOS_COLUMNAS_SUELOS,
} from "../../constants";
import { Loading } from "../../components/Loading";
import { FilterRow } from "../../components/FilterRow";
import { Sort } from "../../components/Sort";
import { Button } from "../../components/Button";
import { usePermissions } from "../../context/PermissionsContext";

export const Suelos = () => {
  const [loading, setLoading] = useState(true);
  const { permissions } = usePermissions();
  const [loadingCount, setLoadingCount] = useState(true);
  const [suelos, setSuelos] = useState([]);
  const [pages, setPages] = useState(1);
  const [countSuelos, setCountSuelos] = useState(0);
  const [allShowed, setAllShowed] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [mainFilter, setMainFilter] = useState("filtros");
  const [openedFilter, setOpenedFilter] = useState(true);
  const [selectableFilters, setSelectableFilters] = useState([]);
  const [filters, setFilters] = useState([{ FilterId: "", FilterValue: "" }]);
  const [sortActive, setSortActive] = useState({
    FieldId: TITULOS_COLUMNAS_SUELOS[1].key,
    Order: "asc",
  });
  const delayTimer = useRef();
  useEffect(() => {
    API.getSearchFilter("UB_Suelo")
      .then((res) => {
        initializationFilter(res);
        setSelectableFilters(res);
        setTriggerSearch(true);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        if (error.code === 401) setTriggerSearch(true);
      });
  }, []);
  useEffect(() => {
    triggerSearch && handleFiltersChange();
  }, [triggerSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const initializationFilter = (selectableFilters) => {
    const sessionFilters = JSON.parse(localStorage.getItem("filters"));
    if (sessionFilters) {
      setFilters(sessionFilters);
    } else {
      const sueloFilterId = selectableFilters.find(
        (filter) => filter.FilterName === "Suelo"
      )?.FilterId;
      const sueloFilter = { FilterId: sueloFilterId, FilterValue: "" };
      const allFilters = selectableFilters
        .filter((filtro) => filtro.FilterId !== sueloFilterId)
        .map((filter) => {
          return { FilterId: filter.FilterId, FilterValue: "" };
        });
      const initialFilters = [sueloFilter, ...allFilters];
      setFilters(initialFilters);
      localStorage.setItem("filters", JSON.stringify(initialFilters));
    }
  };

  const pagination = (page) => {
    return { Page: page, PageSize: SUELOS_POR_PAGINA };
  };

  const getValidActiveFilters = () => {
    const activeFilters = mainFilter === "todos" ? [filters[0]] : filters;
    const validFilters = activeFilters.filter(
      (filter) => filter.FilterId && filter.FilterValue
    );
    return validFilters;
  };

  const getCountSuelos = () => {
    setLoadingCount(true);
    let data = {};
    const validActiveFilters = getValidActiveFilters();
    if (validActiveFilters.length) data.Filters = validActiveFilters;

    API.getCountSuelos(data)
      .then((res) => {
        setCountSuelos(res?.count);
        setLoadingCount(false);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      });
  };

  const getSuelos = (page = 1) => {
    setLoading(true);
    let data = {};
    data.Pagination = pagination(page);
    const validActiveFilters = getValidActiveFilters();
    if (validActiveFilters.length) data.Filters = validActiveFilters;
    data.Sort = sortActive;

    API.getSuelos(data)
      .then((res) => {
        if (res) {
          if (page === 1 && res.length === 0) setSuelos([]);
          if (res.length > 0) {
            if (page === 1) setSuelos(res);
            else setSuelos([...suelos, ...res]);
          }
          if (res.length < SUELOS_POR_PAGINA) {
            setAllShowed(true);
          }
          setPages(page);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const handleFiltersChange = () => {
    API.sourceGetCountSuelos.cancel();
    API.sourceGetSuelos.cancel();
    setAllShowed(false);
    clearTimeout(delayTimer.current);
    delayTimer.current = setTimeout(() => {
      getCountSuelos();
      getSuelos();
    }, DELAY_BUSCADOR_SUELOS);
    setTriggerSearch(false);
  };

  const showMore = () => getSuelos(pages + 1);

  const showedSuelos = allShowed ? countSuelos : pages * SUELOS_POR_PAGINA;
  const totalSuelos = loadingCount ? `${SUELOS_POR_PAGINA}+` : countSuelos;

  const changeMainFilter = (e) => {
    const value = e.target.value;
    setMainFilter(value);
    if (value === "todos") setOpenedFilter(false);
    if (value === "filtros") {
      if (filters.length === 1) addFilter();
      setOpenedFilter(true);
    }

    const totalValidFilters = filters.filter(
      (filter) => filter.FilterId && filter.FilterValue
    ).length;
    const defaultsFilter = [filters[0]];
    const defaultsValidFilters = defaultsFilter.filter(
      (filter) => filter.FilterId && filter.FilterValue
    ).length;
    if (totalValidFilters !== defaultsValidFilters) setTriggerSearch(true);
  };

  const addFilter = () => {
    const filtros = [...filters];
    filtros.push({ FilterId: "", FilterValue: "" });
    setFilters(filtros);
    localStorage.setItem("filters", JSON.stringify(filtros));
  };

  const removeFilter = (id) => {
    const filtros = [...filters];
    if (filters.length === 2) {
      changeFieldFilter(1, "");
    }
    if (filters.length > 2) {
      filtros.splice(id, 1);
      if (filters[id].FilterValue !== "") setTriggerSearch(true);
      setFilters(filtros);
      localStorage.setItem("filters", JSON.stringify(filtros));
    }
  };

  const changeFieldFilter = (id, value) => {
    const filtros = [...filters];
    filtros[id].FilterId = value;
    setFilters(filtros);
    if (filtros[id].FilterValue === "")
      localStorage.setItem("filters", JSON.stringify(filtros));
    else changeTextFilter(id, "");
  };

  const changeTextFilter = (id, value) => {
    const filtros = [...filters];
    filtros[id].FilterValue = value;
    setFilters(filtros);
    localStorage.setItem("filters", JSON.stringify(filtros));
    setTriggerSearch(true);
  };

  const sort = (field, value) => {
    setSortActive({
      FieldId: field,
      Order: value,
    });
    setTriggerSearch(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.TitleBar>
        Suelos ({showedSuelos} de {totalSuelos})
        {permissions.UB_Suelo?.edit === 1 && (
          <S.ButtonsContainer>
            <Link to={"/UB_Suelo/create"}>
              <Button label="Crear" disabled={loading} />
            </Link>
          </S.ButtonsContainer>
        )}
      </S.TitleBar>
      <S.SearchBar>
        <S.SelectSearchBar onChange={changeMainFilter} value={mainFilter}>
          <option value="todos">Todos los Suelos</option>
          <option value="filtros">Filtros</option>
        </S.SelectSearchBar>
        {mainFilter === "filtros" && (
          <S.ButtonSearchBar onClick={() => setOpenedFilter(!openedFilter)}>
            Filtros 👁
          </S.ButtonSearchBar>
        )}
        <S.InputSearchBar
          id="searchField"
          placeholder="Buscar por suelo..."
          value={filters[0].FilterValue || ""}
          onChange={(e) => changeTextFilter(0, e.target.value)}
        />
      </S.SearchBar>
      <S.FilterBars hidden={!openedFilter}>
        {filters.map(
          (filter, index) =>
            index > 0 && (
              <FilterRow
                key={index}
                id={index}
                filter={filter}
                selectableFilters={selectableFilters}
                addFilter={addFilter}
                removeFilter={removeFilter}
                changeFieldFilter={changeFieldFilter}
                changeTextFilter={changeTextFilter}
              />
            )
        )}
      </S.FilterBars>
      <S.Table>
        <thead>
          <tr>
            {TITULOS_COLUMNAS_SUELOS.map((titulo) => (
              <S.TdThead
                key={titulo.key}
                className={titulo.key === sortActive.FieldId ? "active" : null}
              >
                <S.HeaderColumn>
                  <span>{titulo.label}</span>
                  {titulo.sortable && (
                    <Sort
                      field={titulo.key}
                      sortActive={sortActive}
                      onClick={(value) => sort(titulo.key, value)}
                    />
                  )}
                </S.HeaderColumn>
              </S.TdThead>
            ))}
          </tr>
        </thead>
        <tbody>
          {suelos.length > 0 &&
            suelos.map((suelo) => (
              <tr key={suelo.Id}>
                <td>{suelo.IdPromocion}</td>
                <td title={suelo.Name}>
                  <Link to={"/UB_Suelo/" + suelo.Id}>{suelo.Name}</Link>
                </td>
                <td>{suelo.TipoDesuelo}</td>
                <td>{suelo.Clasificacion}</td>
                <td>{suelo.DhZonasName}</td>
                <td>{suelo.DhProvinciasName}</td>
                <td>{suelo.DhMunicipiosName}</td>
              </tr>
            ))}
        </tbody>
      </S.Table>
      <S.Pagination>
        <S.More onClick={showMore} disabled={loading || allShowed}>
          {allShowed ? "No hay más suelos" : "Más suelos..."}
        </S.More>
      </S.Pagination>
    </S.Container>
  );
};
