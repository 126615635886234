import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.li`
  background-color: ${COLORES.FONDO_MENU};
  padding: 0;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: ${COLORES.FONDO_MENU};
  line-height: 2rem;
  padding: 0.5rem 2rem;
  color: ${(props) =>
    props.toggleable ? COLORES.NEGRO : COLORES.SUBMODULE_DISABLED};

  :hover {
    background-color: ${COLORES.MENU_ACTIVO};
  }
`;

export const ButtonsContainer = styled.div`
  align-self: flex-start;
  margin: 0.625rem 1rem;

  button {
    color: black;
    background-color: white;
    height: 1.75rem;
    width: 1.75rem;
    font-size: 0.875rem;
    font-weight: bold;
    margin: 0;
    padding: 0.35rem 0.5rem;
    border: none;
    border: thin solid ${COLORES.GRIS_CLARO};
    :hover {
      background-color: ${COLORES.GRIS_CLARO};
    }
  }
  button:nth-child(1) {
    border-radius: ${(props) => (props.alone ? "0.2rem" : "0.2rem 0 0 0.2rem")};
  }
  button:nth-child(2) {
    border-left: none;
    border-radius: 0 0.2rem 0.2rem 0;
  }
`;

export const Ul = styled.ul`
  list-style: none;
  background-color: ${COLORES.BLANCO};
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 0.2rem;
  font-size: 0.9rem;
  position: absolute;
  right: 1rem;
  text-align: right;

  a {
    text-decoration: none;
    color: ${COLORES.NEGRO} !important;
  }
`;

export const Li = styled.li`
  font-size: 0.75rem;
  font-weight: normal;
  margin: auto;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 0.5rem;
  border-bottom: thin solid ${COLORES.GRIS_CLARO};
  cursor: pointer;
  :nth-last-child(1) {
    border-bottom: none;
  }
  :hover {
    background-color: ${COLORES.AZUL_HOVER};
  }
  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORES.GRIS};
      cursor: auto;
    `}
`;

export const Content = styled.div`
  display: ${(props) => (props.open ? "block" : "none")};
  background-color: ${COLORES.BLANCO};
  line-height: 2em;
`;
