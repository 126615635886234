import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import * as API from "../../api";
import { FieldText } from "../../components/FieldText";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldTextArea } from "../../components/FieldTextArea";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { FieldNumber } from "../../components/FieldNumber";
import { FieldDate } from "../../components/FieldDate";
import { FieldHour } from "../../components/FieldHour";
import { FieldFile } from "../../components/FieldFile";
import { FieldNoAccess } from "../../components/FieldNoAccess";
import { Button } from "../../components/Button";
import { Module } from "../../components/Module";
import { Loading } from "../../components/Loading";
import { DialogConfirm } from "../../components/DialogConfirm";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST, ADD_RELATE_ENTITY_TEXT } from "../../constants";
import { usePermissions } from "../../context/PermissionsContext";

export const DetalleModulo = () => {
  const { module, id } = useParams();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("");
  const [submenus, setSubmenus] = useState([]);
  const [fields, setFields] = useState({});
  const [detalleModulo, setDetalleModulo] = useState({});
  const [detalleModuloListas, setDetalleModuloListas] = useState({});
  const [editarOpened, setEditarOpened] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const [initialValues, setInitialValues] = useState({});
  const [editionMode, setEditionMode] = useState(false);
  const [modified, setModified] = useState(false);
  const [dialogConfirmVisible, setDialogConfirmVisible] = useState(false);
  const form = useRef();
  const timerToast = useRef();
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    const dataRenderPage = {
      Module: module,
      RenderType: id === "create" ? "create" : "detail",
    };
    API.renderPage(dataRenderPage)
      .then((res) => {
        const submenus = Object.keys(res);
        setSubmenus(submenus);
        setSection(submenus[0]);
        setFields(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));

    API.getListByModule(module)
      .then((res) => {
        const temas = [
          "Aval___fianza__documento",
          "Aval___fianza__justificante_depósito",
          "Cédula_urbanística",
          "Proyecto_urbanización__anexos_a_la_memoria",
          "Contrato__convenio",
          "Contrato__documento_contractual",
          "Contrato__factura_comercial",
          "Contrato__presupuesto",
          "Documentación_de_juntas_de_compensación",
          "Estudio_de_detalle__publicación_prensa",
          "Proyecto_parcelación_segregación_agrupación__memoria_y_anexos",
          "Plan_de_sectorización_delimitación__normas_urbanísticas",
          "Plan_general_de_ordenación_territorial__otro_documento_administrativo",
          "Plan_general_de_ordenación_territorial__plano",
          "Plan_general__memoria",
          "Plan_general__plano",
          "Plan_parcial__memoria",
          "Plan_parcial__otros_documentos_técnicos_y_estudios",
          "Plan_parcial__plano",
          "Programa_de_actuación__otro_documento_administrativo",
          "Proyecto_equidistribución__memoria",
          "Proyecto_equidistribución__otro_documento_administrativo",
          "Proyecto_equidistribución__plano",
          "Proyecto_equidistribución__solicitud_aprobación_ante_administración_actuante_(entrada_en_registro)",
          "Proyecto_parcelación___segregación___agrupación__plano",
          "Proyecto_urbanización__alegación",
          "Proyecto_urbanización__memoria",
          "Proyecto_urbanización__otro_documento_administrativo",
          "Proyecto_urbanización__plano",
          "Proyecto_urbanización__presupuesto",
          "Otros",
        ];
        if (res?.Tema?.ListName === "tema") {
          API.getCarteraBySuelo(location?.sourceModule?.id).then((res1) => {
            if (res1 === "3E021E23-D534-4ED3-9676-4B3BDE0AEDB1") {
              res.Tema.SelectValues = res.Tema.SelectValues.filter((i) =>
                temas.includes(i.Id)
              );
              setDetalleModuloListas(res);
            } else {
              res.Tema.SelectValues = res.Tema.SelectValues.filter(
                (i) => !temas.includes(i.Id)
              );
              setDetalleModuloListas(res);
            }
          });
        } else {
          setDetalleModuloListas(res);
        }
      })
      .catch((error) => API.DEVELOP && console.log(error));
  }, [module, id, location?.sourceModule?.id]);
  useEffect(() => {
    if (id === "create") {
      if (module !== "Users") {
        if (location.sourceModule)
          setDetalleModulo(location.inheritedFields || {});
        else history.push("/");
      }
      setLoading(false);
    } else {
      API.getDetailByModule(module, id)
        .then((res) => {
          setDetalleModulo(res);
          setInitialValues(res);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
          error.code !== 401 &&
            toast("error", `${error.msg.Message} (código ${error.code})`);
          setTimeout(() => history.push("/"), DEFAULT_TIMER_TOAST - 100);
        })
        .finally(() => setLoading(false));
    }
  }, [module, id, location.sourceModule, location.inheritedFields, history]);
  useEffect(() => {
    document.addEventListener("wheel", blurActiveInputNumber);
    return () => document.removeEventListener("wheel", blurActiveInputNumber);
  }, []);

  const create = id === "create";
  const editable =
    permissions[module]?.edit === 1 && (create || detalleModulo.Editable);

  const blurActiveInputNumber = () =>
    document.activeElement.type === "number" && document.activeElement.blur();

  const updateDetail = () => {
    API.getDetailByModule(module, id)
      .then((res) => {
        setDetalleModulo(res);
        setInitialValues(res);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
        setTimeout(() => history.push("/"), DEFAULT_TIMER_TOAST - 100);
      })
      .finally(() => setLoading(false));
  };

  const createAccount = (key, value) => {
    setLoading(true);
    const data = {};
    data.idModule = "Accounts";
    data.fields = {
      Id: "00000000-0000-0000-0000-000000000000",
      Name: value.Value,
    };
    API.saveByModule(data)
      .then((res) => {
        toast("success", `Creado "${value.Value}"`);
        const newDetalleModulo = { ...detalleModulo };
        newDetalleModulo[key] = { Value: res.NewId, Label: value.Value };
        setDetalleModulo(newDetalleModulo);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (key, value) => {
    setEditarOpened(false);
    key !== "LastModified" && setModified(true);
    if (value.Label?.includes(ADD_RELATE_ENTITY_TEXT))
      createAccount(key, value);
    else {
      const newDetalleModulo = { ...detalleModulo };
      newDetalleModulo[key] = value;
      setDetalleModulo(newDetalleModulo);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveModulo();
  };

  const saveModulo = () => {
    setLoading(true);
    setEditarOpened(false);
    const formData = new FormData(form.current);
    const objectFormData = Object.fromEntries(formData);
    objectFormData.Id = create ? "00000000-0000-0000-0000-000000000000" : id;
    objectFormData.SourceId = location.sourceModule?.id;
    objectFormData.LastModified = detalleModulo.LastModified;
    Object.values(fields)
      .flat()
      .filter((e) => e.type === "enum_multiple")
      .map((e) => e.key)
      .forEach((key) => (objectFormData[key] = formData.getAll(key)));

    const data = {};
    data.idModule = module;
    data.fields = objectFormData;
    data.IsGridSave = false;

    API.saveByModule(data)
      .then((res) => {
        if (res.Status === "OK") {
          handleChange("LastModified", res.LastModified);
          setInitialValues(detalleModulo);
          setEditionMode(false);
          setModified(false);
          if (create) {
            if (module === "Users") {
              toast("success", "Usuario Creado");
              setTimeout(
                () => history.push("/Users"),
                DEFAULT_TIMER_TOAST - 100
              );
            } else {
              const data = {};
              data.SourceModule = location?.sourceModule?.type;
              data.IdSourceEntity = location?.sourceModule?.id;
              data.LinkModule = module;
              data.IdLinksEntities = [res.NewId];
              API.linkModule(data)
                .then((res) => {
                  if (res.Status === "OK") {
                    toast("success", "Creado y Vinculado");
                    setTimeout(
                      () =>
                        history.push(
                          `/${location?.sourceModule?.type}/${location?.sourceModule?.id}`
                        ),
                      DEFAULT_TIMER_TOAST - 100
                    );
                  }
                })
                .catch((error) => API.DEVELOP && console.log(error));
            }
          } else {
            handleChange("LastModified", res.LastModified);
            const msg = res.Msg.split("/");
            if (msg.includes("extraMsg")) {
              toast("success", `Guardado\n${msg.pop()}`, 5000);
              updateDetail();
            } else toast("success", "Guardado");
          }
        } else if (res.Status === "NeedReload") {
          toast(
            "error",
            "Se encontró una version mas reciente.\nSe requiere recargar (se perderán los cambios no guardados)",
            5000
          );
        } else if (res.Status === "KO") {
          toast("error", res.Msg, 5000);
        } else {
          toast("info", `${res.Status}: ${res.Msg}`, 5000);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const deleteModulo = () => {
    setLoading(true);
    setEditarOpened(false);

    const data = {};
    data.Module = module;
    data.IdEntity = id;

    API.deleteByModule(data)
      .then((res) => {
        if (res.Status === "OK") {
          setDialogConfirmVisible(false);
          toast("success", "Eliminado");
          setTimeout(() => history.goBack(), DEFAULT_TIMER_TOAST - 100);
        } else if (res.Status === "KO") toast("error", res.Msg, 5000);
        else toast("info", `${res.Status}: ${res.Msg}`, 5000);
      })
      .catch((error) =>
        toast("error", `${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0) {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  const render_submenu_fields = (fields) => (
    <S.FieldsContainer>
      {fields.map((field, index) => {
        const {
          key,
          label,
          type,
          editable,
          required,
          relateLink,
          visibleCondition,
          AllowAddRelateEntity,
        } = field;

        let hidden = false;
        if (visibleCondition.condition !== "") {
          var valueCast = visibleCondition.value;
          fields.forEach((field) => {
            if (field.type === "enum" && field.key === visibleCondition.field) {
              valueCast =
                detalleModulo[visibleCondition.field] &&
                detalleModuloListas[visibleCondition.field]?.SelectValues.find(
                  (option) =>
                    option.Id === detalleModulo[visibleCondition.field]
                )?.Value;
            }
          });

          if (visibleCondition.value === "true") {
            valueCast = true;
          }
          if (visibleCondition.value === "false") {
            valueCast = false;
          }
          if (
            visibleCondition.condition === "equal" &&
            detalleModulo[visibleCondition.field] !== valueCast
          ) {
            hidden = true;
          }
          if (
            visibleCondition.condition === "not_equal" &&
            detalleModulo[visibleCondition.field] === valueCast
          ) {
            hidden = true;
          }
        }

        const readOnly =
          permissions[module]?.edit === 0 ||
          editable === "0" ||
          (create &&
            location.inheritedFields &&
            Object.keys(location.inheritedFields).some(
              (field) => field === key
            ));

        switch (type) {
          case "text":
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleModulo[key] || ""}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
              />
            );

          case "enum":
            const currentLabel =
              detalleModulo[key] &&
              detalleModuloListas[key]?.SelectValues.find(
                (option) => option.Id === detalleModulo[key]
              )?.Value;
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOption={{
                  value: detalleModulo[key] || "",
                  label: currentLabel,
                }}
                options={detalleModuloListas[key]?.SelectValues}
                canBeEmpty={detalleModuloListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                hidden={hidden}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
              />
            );

          case "enum_multiple":
            const currentOptions = detalleModulo[key]?.map((id) => {
              const option = {};
              option.value = id;
              option.label = detalleModuloListas[key]?.SelectValues.find(
                (option) => option.Id === id
              )?.Value;
              return option;
            });
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOptions={currentOptions}
                options={detalleModuloListas[key]?.SelectValues}
                canBeEmpty={detalleModuloListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
              />
            );

          case "relate":
            return (
              <FieldListSearch
                key={key}
                id={key}
                label={label}
                listName={detalleModuloListas[key]?.ListName}
                currentOption={{
                  value: detalleModulo[key]?.Value || "",
                  label: detalleModulo[key]?.Label,
                }}
                canBeEmpty={detalleModuloListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                relateLink={relateLink}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
                allowAddRelateEntity={AllowAddRelateEntity}
              />
            );

          case "textarea":
            return (
              <FieldTextArea
                key={key}
                id={key}
                label={label}
                value={detalleModulo[key] || ""}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
              />
            );

          case "bool":
            return (
              <FieldCheckbox
                key={key}
                id={key}
                label={label}
                value={detalleModulo[key] || false}
                handleChange={handleChange}
                readOnly={readOnly}
                hidden={hidden}
              />
            );

          case "int":
          case "float":
          case "decimal":
          case "coordenada":
            return (
              <FieldNumber
                key={key}
                id={key}
                label={label}
                value={detalleModulo[key] || 0}
                empty={detalleModulo[key] === "" || detalleModulo[key] === null}
                handleChange={handleChange}
                type={type}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
              />
            );

          case "date":
            return (
              <FieldDate
                key={key}
                id={key}
                label={label}
                value={detalleModulo[key]?.split("T")[0] || ""}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
              />
            );
          case "hour":
            return (
              <FieldHour
                key={key}
                id={key}
                label={label}
                value={detalleModulo[key]?.split("T")[0] || ""}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
              />
            );

          case "Empty":
            return <li key={`empty${index}`} />;

          case "file":
            if (create) {
              return (
                <FieldFile
                  key={key}
                  id={key}
                  label={label}
                  readOnly={readOnly}
                  typeModule={module}
                />
              );
            } else {
              return (
                <FieldText
                  key={key}
                  id={key}
                  label={label}
                  value={detalleModulo[key] || ""}
                  handleChange={handleChange}
                  readOnly={readOnly}
                  required={required === "1"}
                  alwaysEditing={false}
                  link={detalleModulo["LinkDescarga"]}
                  idEntity={id}
                  typeModule={module}
                  documentName={detalleModulo["DocumentName"]}
                  fileExt={detalleModulo["FileExt"]}
                  toast={toast}
                />
              );
            }

          case "imagen":
            if (!create) {
              return (
                <li key={key}>
                  <img
                    id="fieldImage"
                    alt=""
                    src={"data:image/jpg;base64," + detalleModulo[key]}
                  />

                  <FieldFile
                    key={key}
                    id={key}
                    label="Actualizar Imagen"
                    readOnly={readOnly}
                    typeModule={module}
                    handleChange={handleChange}
                  />
                </li>
              );
            } else {
              return null;
            }

          case "no_access":
            return <FieldNoAccess key={key} id={key} label={label} />;

          default:
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleModulo[key] || ""}
                handleChange={handleChange}
                readOnly={readOnly}
                required={required === "1"}
                alwaysEditing={
                  editionMode ||
                  create ||
                  initialValues[key] !== detalleModulo[key]
                }
                hidden={hidden}
              />
            );
        }
      })}
    </S.FieldsContainer>
  );

  const inheritedFields = () => {
    const inheritedFields = {
      Contrato: { Value: id, Label: detalleModulo.Name },
      JuntacoId: { Value: id, Label: detalleModulo.Name },
      ProyectoUrbanisticoId: { Value: id, Label: detalleModulo.Name },
      ProcesoUrbanisticoId: { Value: id, Label: detalleModulo.Name },
      ProyectoEdificacionId: { Value: id, Label: detalleModulo.Name },
      DatoRegId: { Value: id, Label: detalleModulo.Name },
      DocumentoId: { Value: id, Label: detalleModulo.Name },
    };
    return inheritedFields;
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {dialogConfirmVisible && (
        <DialogConfirm
          message="¿Confirma que desea eliminar el módulo?"
          labelConfirm="Sí, Eliminar"
          confirm={deleteModulo}
          cancel={() => setDialogConfirmVisible(false)}
        />
      )}
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          {module !== "Users" &&
            module !== "Document_Revisions" &&
            module !== "UB_GestionesRealizadas" &&
            module !== "Documents_Pricing" &&
            module !== "Documents_SituacionJuridica" &&
            module !== "UB_GestionesProximas" &&
            module !== "UB_Ayuntamiento" &&
            module !== "UB_Suelo_Geocerca" &&
            module !== "UB_estructuraprop" && (
              <FieldText
                id="Name"
                value={detalleModulo.Name || ""}
                handleChange={handleChange}
                form="form"
                type="title"
                placeholder="Nuevo nombre..."
                required={true}
                alwaysEditing={create}
              />
            )}
        </S.WrapperFieldText>
        <S.ButtonsContainer>
          {editionMode || modified || create ? (
            <>
              <Button
                label="Cancelar"
                onClick={() => {
                  if (create) history.goBack();
                  else {
                    setDetalleModulo(initialValues);
                    setEditionMode(false);
                    setModified(false);
                  }
                }}
                disabled={!editable || loading}
              />
              <Button
                label={create ? "Crear" : "Guardar"}
                disabled={!editable || loading}
                type="submit"
                form="form"
              />
            </>
          ) : (
            <>
              <Button
                label="Editar"
                onClick={() => {
                  setEditionMode(true);
                  setEditarOpened(false);
                }}
                disabled={!editable || loading}
              />
              <Button
                label="▾"
                onClick={() => setEditarOpened(!editarOpened)}
                disabled={create || !editable || loading}
              />
            </>
          )}
          {editarOpened && (
            <S.Ul>
              <Link
                to={{
                  pathname: `/${module}/${id}/audit`,
                  entityname: detalleModulo.Name,
                }}
              >
                <S.Li>Ver el registro de auditoría</S.Li>
              </Link>
              {permissions[module]?.delete === 1 && detalleModulo.Removable && (
                <S.Li
                  onClick={() => {
                    setEditarOpened(false);
                    setDialogConfirmVisible(true);
                  }}
                >
                  Eliminar
                </S.Li>
              )}
            </S.Ul>
          )}
        </S.ButtonsContainer>
      </S.TitleBar>
      {submenus.length > 1 && (
        <S.SubMenuBar>
          <ul>
            <S.Submenu id="dummy" />
            {submenus.map((submenu) => (
              <S.Submenu
                key={submenu}
                className={submenu === section ? "active" : null}
                onClick={() => setSection(submenu)}
              >
                {submenu}
              </S.Submenu>
            ))}
          </ul>
        </S.SubMenuBar>
      )}
      <form id="form" ref={form} onSubmit={handleSubmit}>
        <S.Content>
          {submenus.map((submenu) => (
            <div
              key={submenu}
              className={submenu === section ? "active" : "inactive"}
            >
              {fields[submenu] && render_submenu_fields(fields[submenu])}
            </div>
          ))}
        </S.Content>
      </form>
      {!create && (
        <S.ModulesContainer>
          {detalleModulo.ModulesCount &&
            Object.entries(detalleModulo.ModulesCount)
              .filter((moduleInfo) => permissions[moduleInfo[0]]?.access === 1)
              .map((moduleInfo, index) => (
                <Module
                  key={index}
                  moduleInfo={moduleInfo}
                  sourceModule={{
                    type: module,
                    id,
                    name: detalleModulo.Name,
                  }}
                  inheritedFields={inheritedFields()}
                  getModule={API.getModulesByOrigin}
                  updateDetail={updateDetail}
                  setLoading={setLoading}
                  access={permissions}
                  visibleInTab={true}
                />
              ))}
        </S.ModulesContainer>
      )}
    </S.Container>
  );
};
