import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { Link, useLocation, useHistory } from "react-router-dom";
import * as API from "../../api";
import { DELAY_BUSCADOR_VINCULAR, VINCULAR_POR_PAGINA } from "../../constants";
import { Loading } from "../../components/Loading";
import { FilterRow } from "../../components/FilterRow";
import { Button } from "../../components/Button";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST } from "../../constants";

export const Vincular = () => {
  const [loading, setLoading] = useState(true);
  const [modulos, setModulos] = useState([]);
  const [pages, setPages] = useState(1);
  const [allShowed, setAllShowed] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [mainFilter, setMainFilter] = useState("todos");
  const [openedFilter, setOpenedFilter] = useState(false);
  const [selectableFilters, setSelectableFilters] = useState([]);
  const [filters, setFilters] = useState([{ FilterId: "", FilterValue: "" }]);
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [columnsHeaders, setColumnsHeaders] = useState([]);
  const delayTimer = useRef();
  const timerToast = useRef();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getModulos();
    const dataRenderPage = {
      ModuleSource: location.sourceModule?.type,
      Module: location.module?.type,
      RenderType: "listing",
    };
    API.renderPage(dataRenderPage)
      .then((res) => {
        setColumnsHeaders(res.Listing);
      })
      .catch((error) => API.DEVELOP && console.log(error));
    API.getSearchFilterLink(location.sourceModule?.type, location.module?.type)
      .then((res) => {
        initializationFilter(res);
        setSelectableFilters(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    triggerSearch && handleSearchChange();
  }, [triggerSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const initializationFilter = (selectableFilters) => {
    const defaultFilterId = selectableFilters.find(
      (filter) => filter.FilterName === "Nombre"
    )?.FilterId;
    const filtros = [{ FilterId: defaultFilterId }];
    setFilters(filtros);
  };

  const pagination = (page) => {
    return { Page: page, PageSize: VINCULAR_POR_PAGINA };
  };

  const getValidActiveFilters = () => {
    const activeFilters = mainFilter === "todos" ? [filters[0]] : filters;
    const validFilters = activeFilters.filter(
      (filter) => filter.FilterId && filter.FilterValue
    );
    return validFilters;
  };

  const getModulos = (page = 1) => {
    setLoading(true);
    let data = {};
    data.Pagination = pagination(page);
    const validActiveFilters = getValidActiveFilters();
    if (validActiveFilters.length) data.Filters = validActiveFilters;

    data.SourceModule = location.sourceModule?.type;
    data.LinkModule = location.module?.type;
    data.IdSourceEntity = location.sourceModule?.id;

    API.getModuleLinks(data)
      .then((res) => {
        if (res) {
          if (page === 1 && res.length === 0) setModulos([]);
          if (res.length > 0) {
            if (page === 1) setModulos(res);
            else setModulos([...modulos, ...res]);
          }
          if (res.length < VINCULAR_POR_PAGINA) {
            setAllShowed(true);
          }
          setPages(page);
        }
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const handleSearchChange = () => {
    API.sourceGetModulos.cancel();
    setAllShowed(false);
    clearTimeout(delayTimer.current);
    delayTimer.current = setTimeout(() => {
      getModulos();
    }, DELAY_BUSCADOR_VINCULAR);
    setTriggerSearch(false);
  };

  const showMore = () => getModulos(pages + 1);

  const changeMainFilter = (e) => {
    const value = e.target.value;
    if (value === "todos") setOpenedFilter(false);
    if (value === "filtros") {
      if (filters.length === 1) addFilter();
      setOpenedFilter(true);
    }
    setMainFilter(value);

    const totalValidFilters = filters.filter(
      (filter) => filter.FilterId && filter.FilterValue
    ).length;
    const defaultsFilter = [filters[0]];
    const defaultsValidFilters = defaultsFilter.filter(
      (filter) => filter.FilterId && filter.FilterValue
    ).length;
    if (totalValidFilters !== defaultsValidFilters) setTriggerSearch(true);
  };

  const addFilter = () => {
    const filtros = [...filters];
    filtros.push({ FilterId: "", FilterValue: "" });
    setFilters(filtros);
  };

  const removeFilter = (id) => {
    const filtros = [...filters];
    if (filters.length === 2) {
      changeFieldFilter(1, "");
      setFilters(filtros);
    }
    if (filters.length > 2) {
      filtros.splice(id, 1);
      setFilters(filtros);
      if (filters[id].FilterValue !== "") setTriggerSearch(true);
    }
  };

  const changeFieldFilter = (id, value) => {
    const filtros = [...filters];
    filtros[id].FilterId = value;
    setFilters(filtros);
    if (filtros[id].FilterValue !== "") changeTextFilter(id, "");
  };

  const changeTextFilter = (id, value) => {
    const filtros = [...filters];
    filtros[id].FilterValue = value;
    setFilters(filtros);
    setTriggerSearch(true);
  };

  const handleSelectionChange = (id, checked) => {
    let aux = [...selectedIds];
    if (checked) {
      aux.push(id);
    } else {
      const index = aux.indexOf(id);
      aux.splice(index, 1);
    }
    setSelectedIds(aux);
  };

  const linkup = () => {
    setLoading(true);
    let data = {};
    data.SourceModule = location.sourceModule?.type;
    data.IdSourceEntity = location.sourceModule?.id;
    data.LinkModule = location.module.type;
    data.IdLinksEntities = selectedIds;

    API.linkModule(data)
      .then((res) => {
        if (res.Status === "OK") {
          toast("success", "Vinculación realizada");
          setTimeout(
            () =>
              history.push(
                `/${location.sourceModule.type}/${location.sourceModule.id}`
              ),
            DEFAULT_TIMER_TOAST - 100
          );
        } else if (res.Status === "KO") {
          toast("error", res.Msg, 5000);
        } else {
          toast("info", `${res.Status}: ${res.Msg}`, 5000);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg?.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0) {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          Buscar y agregar {location.module?.label}
        </S.WrapperFieldText>
        <S.ButtonsContainer>
          {selectedIds.length > 0 && (
            <Button
              label={`Vincular seleccionados (${selectedIds.length})`}
              disabled={loading}
              onClick={linkup}
            />
          )}
          <Button
            label="Cancelar"
            disabled={loading}
            onClick={history.goBack}
          />
        </S.ButtonsContainer>
      </S.TitleBar>

      <S.SearchBar>
        <S.SelectSearchBar onChange={changeMainFilter} value={mainFilter}>
          <option value="todos">Todos</option>
          <option value="filtros">Filtros</option>
        </S.SelectSearchBar>
        {mainFilter === "filtros" && (
          <S.ButtonSearchBar onClick={() => setOpenedFilter(!openedFilter)}>
            Filtros 👁
          </S.ButtonSearchBar>
        )}
        <S.InputSearchBar
          id="searchField"
          placeholder="Buscar por nombre..."
          value={filters[0].FilterValue || ""}
          onChange={(e) => changeTextFilter(0, e.target.value)}
        />
      </S.SearchBar>
      <S.FilterBars hidden={!openedFilter}>
        {filters.map(
          (filter, index) =>
            index > 0 && (
              <FilterRow
                key={index}
                id={index}
                filter={filter}
                selectableFilters={selectableFilters}
                addFilter={addFilter}
                removeFilter={removeFilter}
                changeFieldFilter={changeFieldFilter}
                changeTextFilter={changeTextFilter}
              />
            )
        )}
      </S.FilterBars>
      <S.Table>
        <thead>
          <tr>
            <S.TdThead>
              <S.HeaderColumn>
                <span>Vincular</span>
              </S.HeaderColumn>
            </S.TdThead>
            {columnsHeaders?.map((titulo) => (
              <S.TdThead id={titulo.key} key={titulo.key}>
                <S.HeaderColumn>
                  <span>{titulo.label}</span>
                </S.HeaderColumn>
              </S.TdThead>
            ))}
          </tr>
        </thead>
        <tbody>
          {modulos.length > 0 &&
            modulos.map((modulo) => (
              <tr key={modulo.Id}>
                <td>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleSelectionChange(modulo.Id, e.target.checked)
                    }
                  />
                </td>
                {columnsHeaders?.map((columnHeader) => {
                  if (
                    columnHeader.key === "Name" &&
                    location.module.type !==
                      "UB_Hitos_Urbanisticos_No_Ordinarios" &&
                    location.module.type !== "DH_Municipios"
                  ) {
                    return (
                      <td key={columnHeader.key} title={modulo.Name}>
                        <Link to={`/${location.module?.type}/${modulo.Id}`}>
                          {modulo.Name}
                        </Link>
                      </td>
                    );
                  } else
                    return (
                      <td key={columnHeader.key}>{modulo[columnHeader.key]}</td>
                    );
                })}
              </tr>
            ))}
        </tbody>
      </S.Table>
      <S.Pagination>
        <S.More onClick={showMore} disabled={loading || allShowed}>
          {allShowed ? "No hay más módulos" : "Más módulos..."}
        </S.More>
      </S.Pagination>
    </S.Container>
  );
};
