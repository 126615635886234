import React, { useState, useRef, useEffect } from "react";
import * as S from "./styles";
import * as API from "../../api";
import { Loading } from "../../components/Loading";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { FieldList } from "../../components/FieldList";
import { FieldText } from "../../components/FieldText";
import { SUELOS_POR_PAGINA, DELAY_BUSCADOR_SUELOS } from "../../constants";
const { REACT_APP_BASE_URL } = process.env;

export const Mapa = () => {
  const listInnerRef = useRef();
  const delayTimer = useRef();
  const [loading, setLoading] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pages, setPages] = useState(1);
  const [allShowed, setAllShowed] = useState(false);
  const [suelos, setSuelos] = useState([]);
  const [suelo, setSuelo] = useState({});
  const [zoom, setZoom] = useState(6);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [detalleListas, setDetalleListas] = useState({});
  const [dataFilters, setDataFilters] = useState({});
  const API_KEY = "AIzaSyCR6pOF_SfiKObLSfyWnR7xfOvbXkkIxBU";
  const defaultCenter = { lat: 40.416667, lng: -3.7025 };
  const defaultZoom = 6;

  useEffect(() => {
    API.getListByModule("UB_Suelo_Geocerca")
      .then((res) => setDetalleListas(res))
      .catch((error) => API.DEVELOP && console.log(error));
    API.getSearchFilter("UB_Suelo_Geocerca")
      .then((res) => setDataFilters(res))
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);

  useEffect(() => {
    if (triggerSearch) {
      setAllShowed(false);
      getSuelosFincas();
      setTriggerSearch(false);
    }
  }, [triggerSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const coords = [];

    const getCenterZoom = (coords) => {
      const defaultCenter = { lat: 40.416667, lng: -3.7025 };
      if (coords.length === 0) return [defaultCenter, defaultZoom];

      let maxLat = -90;
      let minLat = 90;
      let maxLng = -180;
      let minLng = 180;
      coords.forEach((coord) => {
        if (coord.lat > maxLat) maxLat = coord.lat;
        if (coord.lat < minLat) minLat = coord.lat;
        if (coord.lng > maxLng) maxLng = coord.lng;
        if (coord.lng < minLng) minLng = coord.lng;
      });

      const center = {
        lat: (maxLat + minLat) / 2,
        lng: (maxLng + minLng) / 2,
      };

      const heightLat = maxLat - minLat;
      const widthLng = maxLng - minLng;
      const zoomLat = -1.444 * Math.log(heightLat) + 9.0271 - 0.1;
      const zoomLng = -1.442 * Math.log(widthLng) + 10.203 - 0.1;
      const zoom = Math.trunc(Math.min(zoomLat, zoomLng));

      return [center, zoom];
    };

    if (Object.keys(suelo).length > 0)
      suelo.Fincas?.forEach((finca) =>
        finca.Coordenadas?.forEach((coord) => coords.push(coord))
      );
    else
      suelos.forEach((suelo) => {
        suelo.Fincas?.forEach((finca) =>
          finca.Coordenadas?.forEach((coord) => coords.push(coord))
        );
      });
    const [newCenter, newZoom] = getCenterZoom(coords);
    setCenter(newCenter);
    setZoom(newZoom);
  }, [suelo, suelos]);

  const getCenter = (coords) => {
    if (coords.length === 0) return [defaultCenter];

    let maxLat = -90;
    let minLat = 90;
    let maxLng = -180;
    let minLng = 180;
    coords.forEach((coord) => {
      if (coord.lat > maxLat) maxLat = coord.lat;
      if (coord.lat < minLat) minLat = coord.lat;
      if (coord.lng > maxLng) maxLng = coord.lng;
      if (coord.lng < minLng) minLng = coord.lng;
    });

    const center = {
      lat: (maxLat + minLat) / 2,
      lng: (maxLng + minLng) / 2,
    };

    return center;
  };

  const Map = ({ ...options }) => {
    const ref = useRef(null);
    const [map, setMap] = useState();

    useEffect(() => {
      if (ref.current && !map) {
         setMap(new window.google.maps.Map(ref.current, {}));
      }
    }, [ref, map]);

    useEffect(() => {
      if (map) {
        suelos.forEach((suelo) => {
          const coords = [];
          suelo.Fincas?.forEach((finca) =>
            finca.Coordenadas?.forEach((coord) => coords.push(coord))
          );

          const svgMarker = {
            path: "M100,100 A100,100 0 1,1 300,100 M300,100 L200,300 L100,100 M100,100 z",
            fillColor: suelo.Color,
            fillOpacity: 0.75,
            strokeWeight: 1.5,
            strokeColor: suelo.Color,
            rotation: 0,
            scale: 0.12,
            anchor: new window.google.maps.Point(200, 300),
          };

          const marker = new window.google.maps.Marker({
            position: getCenter(coords),
            title: suelo.Name,
            id: suelo.Id,
            icon: svgMarker,
          });

         // window.google.maps.event.addListener(marker, "click", (event) => {
            suelo.Fincas?.forEach((finca) => {
              const polygon = new window.google.maps.Polygon({
                paths: finca.Coordenadas,
                strokeColor: suelo.Color,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: suelo.Color,
                fillOpacity: 0.35,
              });
              polygon.setMap(map);

              const infoWindow = new window.google.maps.InfoWindow();
              window.google.maps.event.addListener(
                polygon,
                "click",
                (event) => {
                  infoWindow.setOptions({
                    content: `<div>
                      <a target="_blank" href="${REACT_APP_BASE_URL}/UB_Suelo/${suelo.Id}"><p>${suelo.Name}</p></a>
                      <a target="_blank" href="${REACT_APP_BASE_URL}/UB_FincasAp/${finca.Id}"><p>Finca: ${finca.Name}</p></a>
                      <p>Provincia: ${suelo.DhProvinciasName}</p>
                      <p>Municipio: ${suelo.DhMunicipiosName}</p>
                      </div>`,
                    position: {
                      lat: event.latLng.lat(),
                      lng: event.latLng.lng(),
                    },
                    pixelOffset: 0,
                  });
                  infoWindow.open(map);
                }
              );
            });
           
            window.google.maps.event.addListener(marker, "click", (event) => {
             //marker.setMap(null);
            setSuelo(suelo);
             });
          marker.setMap(map);
        });
        map.setOptions(options);
      }
    }, [map, options]);

    return <div ref={ref} style={{ height: "100%", width: "100%" }} />;
  };

  const render = (status = Status) => {
    return <h1>{status}</h1>;
  };

  const getSuelosFincas = (page = 1) => {
    setLoading(true);
    console.log(detalleListas);
    let data = {};
    data.Pagination = pagination(page);
    data.Filters = [
      {
        FilterId: dataFilters[0].FilterId,
        FilterValue:
          detalleListas[dataFilters[0].FilterName]?.CurrentOption?.value,
      },
      {
          FilterId: dataFilters[1].FilterId,
          FilterValue:
            detalleListas[dataFilters[1].FilterName]?.CurrentOption?.value,
      },
      {
        FilterId: dataFilters[2].FilterId,
        FilterValue:
        detalleListas[dataFilters[2].FilterName]?.CurrentOption?.value,
      },
      {
        FilterId: dataFilters[3].FilterId,
        FilterValue:
        detalleListas[dataFilters[3].FilterName]?.CurrentOption?.value,
      },
      {
        FilterId: dataFilters[4].FilterId,
        FilterValue: searchValue,
      },
    ];

    API.getSuelosFincas(data)
      .then((res) => {
        if (res) {
          if (page === 1 && res.length === 0) setSuelos([]);
          if (res.length > 0) {
            if (page === 1) setSuelos(res);
            else setSuelos([...suelos, ...res]);
          }
          if (res.length < SUELOS_POR_PAGINA) {
            setAllShowed(true);
          }
          setPages(page);
        }
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
      })
      .finally(() => setLoading(false));
  };

  const pagination = (page) => {
    return { Page: page, PageSize: SUELOS_POR_PAGINA };
  };

  const showMore = () => getSuelosFincas(pages + 1);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (!allShowed && scrollTop + clientHeight >= scrollHeight - 1) {
        showMore();
      }
    }
  };

  const changeSearchText = (id, value) => {
    API.sourceGetSuelos.cancel();
    clearTimeout(delayTimer.current);
    delayTimer.current = setTimeout(() => {
      setSuelo({});
      setSearchValue(value);
      setTriggerSearch(true);
    }, DELAY_BUSCADOR_SUELOS);
  };
  
  const listChange = (id, value) => {
    const newDetalleListas = { ...detalleListas };
    //newDetalleListas["Cartera"].CurrentOption = { value: "" };
      if (!(id === "Cartera"))
      {
          if (!(id === "Provincia" || id === "Municipio"))
          {
          newDetalleListas["Comunidad Autonoma"].CurrentOption = { value: "" };
          }
          newDetalleListas["Municipio"].CurrentOption = { value: "" };
          if (!(id === "Municipio"))
          {
           newDetalleListas["Provincia"].CurrentOption = { value: "" };
          }
    }
    newDetalleListas[id].CurrentOption = {
      label: detalleListas[id].SelectValues.find((e) => e.Id === value)?.Value,
      value: value,
    };
  
   
    if("Comunidad Autonoma"===id)
    {
        API.searchValueList("depen"+id,value)
             .then((res)=>
                newDetalleListas["Provincia"].SelectValues=res.SelectValues)
             .finally(setDetalleListas(newDetalleListas));
   //debugger
        API.searchValueList("depenComunidad AutonomaProvincia",value)
            .then((res)=>
                newDetalleListas["Municipio"].SelectValues=res.SelectValues)
            .finally(setDetalleListas(newDetalleListas));
      }

      if("Provincia"===id)
      {
         API.searchValueList("depenProvincia",value)
             .then((res)=>
                 newDetalleListas["Municipio"].SelectValues=res.SelectValues)
             .finally(setDetalleListas(newDetalleListas));
        }
  
 
    
    setSuelo({});
    setTriggerSearch(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.Buscador>
        <S.FieldsContainer>
        <FieldList
            id="Cartera"
            label="Cartera"
            type="enum"
            currentOption={detalleListas["Cartera"]?.CurrentOption}
            options={detalleListas["Cartera"]?.SelectValues}
            canBeEmpty={true}
            handleChange={listChange}
            readOnly={false}
            required={false}
            alwaysEditing={true}
            hidden={false}
          />
          <FieldList
            id="Comunidad Autonoma"
            label="Comunidad Autónoma"
            type="enum"
            currentOption={detalleListas["Comunidad Autonoma"]?.CurrentOption}
            options={detalleListas["Comunidad Autonoma"]?.SelectValues}
            canBeEmpty={true}
            handleChange={listChange}
            readOnly={false}
            required={false}
            alwaysEditing={true}
            hidden={false}
          />
          <FieldList
            id="Provincia"
            label="Provincia"
            type="enum"
            currentOption={detalleListas["Provincia"]?.CurrentOption}
            options={detalleListas["Provincia"]?.SelectValues}
            canBeEmpty={true}
            handleChange={listChange}
            readOnly={false}
            required={false}
            alwaysEditing={true}
            hidden={false}
          />
           <FieldList
            id="Municipio"
            label="Municipio"
            type="enum"
            currentOption={detalleListas["Municipio"]?.CurrentOption}
            options={detalleListas["Municipio"]?.SelectValues}
            canBeEmpty={true}
            handleChange={listChange}
            readOnly={false}
            required={false}
            alwaysEditing={true}
            hidden={false}
          />
          <FieldText
            id="Suelo"
            label="Suelo"
            handleChange={changeSearchText}
            placeholder="Buscar por suelo..."
            readOnly={false}
            required={false}
            alwaysEditing={true}
            hidden={false}
          />
        </S.FieldsContainer>
        <S.Listado>
          <ul onScroll={onScroll} ref={listInnerRef}>
            {suelos.map((suelo) => 
            (            
               <li 
                key={suelo.Id}
                title={suelo.Cartera}
                onClick={() => setSuelo(suelo)}
              >
                <span style={{color: suelo.Color}}> <b> * </b> </span>&nbsp;
                {suelo.Name}
              </li>
            ))}
          </ul>
        </S.Listado>
      </S.Buscador>
      <S.Mapa>
        <Wrapper apiKey={API_KEY} render={render}>
          <Map zoom={zoom} center={center} />
        </Wrapper>
      </S.Mapa>
    </S.Container>
  );
};
