import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldNumber = ({
  id,
  label,
  value: numericValue,
  empty,
  handleChange,
  type,
  readOnly,
  required,
  alwaysEditing,
  hidden,
}) => {
  const [value, setValue] = useState("");
  const [focused, setFocused] = useState(false);
  useEffect(() => setValue(empty ? "" : numericValue), [empty, numericValue]);

  const editing = !readOnly && (focused || alwaysEditing);

  const numberOptions = () => {
    return {
      style: type === "currency" ? "currency" : "decimal",
      minimumFractionDigits: numDigits(),
      maximumFractionDigits: numDigits(),
      currency: "EUR",
    };
  };

  const numDigits = () => {
    if (type === "coordenada") return 6;
    if (type === "float") return 2;
    if (type === "decimal") return 2;
    if (type === "currency") return 2;
    return 0;
  };

  const step = () => {
    if (type === "coordenada") return 0.000001;
    if (type === "float") return 0.01;
    if (type === "decimal") return 0.01;
    if (type === "currency") return 0.01;
    return 1;
  };

  const min = () => {
    if (type === "coordenada") return null;
    return 0;
  };

  return (
    <S.Container
      onClick={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={hidden ? "hidden" : "visible"}
    >
      <S.Title>{label}</S.Title>
      <S.InputContainer>
        <S.InputWrapper>
          {editing ? (
            <S.Input
              type="number"
              value={value}
              onChange={(e) =>
                handleChange(id, e.target.value ? Number(e.target.value) : "")
              }
              step={step()}
              min={min()}
              required={required}
            />
          ) : empty ? (
            ""
          ) : (
            Number(value).toLocaleString("es-ES", numberOptions())
          )}
        </S.InputWrapper>
        <input
          className="hidden"
          name={id}
          type="number"
          value={value}
          readOnly={true}
        />
      </S.InputContainer>
    </S.Container>
  );
};

FieldNumber.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.number,
  empty: PropTypes.bool,
  handleChange: PropTypes.func,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  alwaysEditing: PropTypes.bool,
  hidden: PropTypes.bool,
};
