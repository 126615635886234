import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { Link } from "react-router-dom";
import * as API from "../../api";
import { TITULOS_COLUMNAS_ROLES } from "../../constants";
import { Loading } from "../../components/Loading";
import { Button } from "../../components/Button";

export const Roles = () => {
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  useEffect(() => {
    API.getRoles()
      .then((res) => {
        if (res && res.length > 0) {
          setRoles(res);
        }
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.TitleBar>
        Roles
        <S.ButtonsContainer>
          <Link to={"/Roles/create"}>
            <Button label="Crear" disabled={loading} />
          </Link>
        </S.ButtonsContainer>
      </S.TitleBar>
      <S.Table>
        <thead>
          <tr>
            {TITULOS_COLUMNAS_ROLES.map((titulo) => (
              <S.TdThead key={titulo.key}>
                <S.HeaderColumn>
                  <span>{titulo.label}</span>
                </S.HeaderColumn>
              </S.TdThead>
            ))}
          </tr>
        </thead>
        <tbody>
          {roles.length > 0 &&
            roles.map((rol) => (
              <tr key={rol.Id}>
                <td>{rol.Id}</td>
                <td>
                  <Link to={"/Roles/" + rol.Id}>{rol.Name}</Link>
                </td>
                <td>{rol.Description}</td>
              </tr>
            ))}
        </tbody>
      </S.Table>
    </S.Container>
  );
};
