import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { COLORES } from "../../constants";
import Select from "react-select";

export const FieldList = ({
  id,
  label,
  type,
  currentOption,
  currentOptions,
  options,
  canBeEmpty,
  handleChange,
  readOnly,
  required,
  alwaysEditing,
  hidden,
}) => {
  const [focused, setFocused] = useState(false);

  const editing = !readOnly && (focused || alwaysEditing);
  const optionsFixed = options?.map((option) => ({
    value: option.Id,
    label: option.Value,
  }));

  return (
    <S.Container
      onClick={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={hidden ? "hidden" : "visible"}
    >
      {label && <S.Title>{label}</S.Title>}
      <S.SelectContainer className={editing ? "visible" : "hidden"}>
        <Select
          name={id}
          styles={customStyles}
          value={type === "enum" ? currentOption : currentOptions}
          options={optionsFixed}
          onChange={(e) => {
            let newValue = null;
            if (type === "enum") {
              newValue = e ? e.value : "";
            } else {
              newValue = e ? e.map((e) => e.value) : [];
            }
            handleChange(id, newValue);
          }}
          isClearable={canBeEmpty}
          placeholder="Seleccionar..."
          noOptionsMessage={() => "No hay opciones"}
          required={required}
          isMulti={type === "enum_multiple"}
        />
      </S.SelectContainer>
      <S.Text className={editing ? "hidden" : "visible"}>
        {type === "enum"
          ? currentOption?.label
          : currentOptions?.map(
              (option, i, array) =>
                option.label + (i + 1 < array.length ? ", " : "")
            )}
      </S.Text>
    </S.Container>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.GRIS,
    },
    borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.GRIS_CLARO,
    backgroundColor: COLORES.FIELD_BACKGROUND,
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    "&.indicatorContainer": {
      padding: "0 8px",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "6px 12px",
    borderBottom: `1px solid ${COLORES.GRIS_CLARO}`,
    backgroundColor: state.isSelected ? COLORES.LIST_ACTIVE : "white",
    "&:hover": {
      backgroundColor: state.isSelected
        ? COLORES.LIST_ACTIVE
        : COLORES.GRIS_CLARO,
    },
  }),
};

FieldList.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  currentOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  currentOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  options: PropTypes.array,
  canBeEmpty: PropTypes.bool,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  alwaysEditing: PropTypes.bool,
  hidden: PropTypes.bool,
};
