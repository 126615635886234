import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { Link } from "react-router-dom";
import * as API from "../../api";
import {
  MENUS,
  REPORTES,
  REPORTES_QUASAR,
  MENUS_EXTRA,
  USUARIOS,
} from "../../constants";
import { useMsal } from "@azure/msal-react";
import { Button } from "../Button";
import logo from "./../../assets/images/favicon.ico";
import { toast } from "react-toastify";
import { Loading } from "../../components/Loading";
import { usePermissions } from "../../context/PermissionsContext";
const { REACT_APP_INFORMES_BASE_URL } = process.env;

export const NavMenu = ({ pathname }) => {
  const [loading, setLoading] = useState(false);
  const { permissions } = usePermissions();
  const [active, setActive] = useState("");
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [reportMenuOpened, setReportMenuOpened] = useState(false);
  const [mensaje, setMensaje] = useState("Cargando...");
  const { instance, accounts } = useMsal();
  const username = accounts[0]?.username;
  useEffect(() => setActive(pathname), [pathname]);

  const openInformes = () => {
    setLoading(true);
    API.generarAutorizacionReporte()
      .then((res) => {
        const reportesPath = `${REACT_APP_INFORMES_BASE_URL}/signIn?key=${encodeURIComponent(
          res
        )}`;
        window.open(reportesPath, "_blank");
      })
      .catch((error) => console.log("generarAutorizacionReporte", error))
      .finally(() => setLoading(false));
  };

  const handleMenuChange = (option) => {
    setUserMenuOpened(false);
    setReportMenuOpened(false);
    setActive(option);
  };

  const logout = () => {
    localStorage.clear();
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };

  const reporteHitos = () => {
    setMensaje("Descargando...");
    setLoading(true);
    API.reporteHitos()
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const filename =
          res.headers["content-disposition"].split("filename=")[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
      })
      .catch((error) => {
        error.code === 410
          ? toast("error", `Reporte no encontrado (código ${error.code})`)
          : toast("error", `${error.msg?.Message} (código ${error.code})`);
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setReportMenuOpened(false);
        setLoading(false);
      });
  };

  const reporteDatosExcel = () => {
    setMensaje("Descargando...");
    setLoading(true);
    API.reporteDatosValoracionExcel()
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const filename =
          res.headers["content-disposition"].split("filename=")[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
      })
      .catch((error) => {
        error.code === 410
          ? toast("error", `Reporte no encontrado (código ${error.code})`)
          : toast("error", `${error.msg?.Message} (código ${error.code})`);
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setReportMenuOpened(false);
        setLoading(false);
      });
  };

  const reporteParametrosUrbanisticos = () => {
    setMensaje("Descargando...");
    setLoading(true);
    API.reporteParametrosUrbanisticos()
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const filename =
          res.headers["content-disposition"].split("filename=")[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
      })
      .catch((error) => {
        error.code === 410
          ? toast("error", `Reporte no encontrado (código ${error.code})`)
          : toast("error", `${error.msg?.Message} (código ${error.code})`);
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setReportMenuOpened(false);
        setLoading(false);
      });
  };

  const reportePrefacturas = () => {
    setMensaje("Descargando...");
    setLoading(true);
    API.reportPrefacturasExcel()
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const filename =
          res.headers["content-disposition"].split("filename=")[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        link.click();
      })
      .catch((error) => {
        error.code === 410
          ? toast("error", `Reporte no encontrado (código ${error.code})`)
          : toast("error", `${error.msg?.Message} (código ${error.code})`);
        API.DEVELOP && console.log(error);
      })
      .finally(() => {
        setReportMenuOpened(false);
        setLoading(false);
      });
  };

  return (
    <S.Nav>
      <Loading hidden={!loading} mensaje={mensaje} />
      <Link to={"/"}>
        <S.Img src={logo} alt="Logo" />
      </Link>
      <S.MenuContainer>
        <ul>
          {MENUS.filter(
            (menu) => permissions && permissions[menu.key]?.access
          ).map((menu) => {
            if (menu.key === REPORTES_QUASAR.key) {
              return (
                <S.SubmenuContainer key={menu.key}>
                  <li
                    onClick={() => {
                      setReportMenuOpened(!reportMenuOpened);
                      setUserMenuOpened(false);
                    }}
                  >
                    <p>{REPORTES_QUASAR.label}</p>
                  </li>
                  {reportMenuOpened && (
                    <>
                      <li onClick={reporteHitos}>
                        <p>Reporte Hitos Urbanísticos</p>
                      </li>
                      <li onClick={reporteDatosExcel}>
                        <p>Informe datos de valoracion</p>
                      </li>
                      <li onClick={reporteParametrosUrbanisticos}>
                        <p>Informe parametros urbanísticos</p>
                      </li>
                      <li onClick={reportePrefacturas}>
                        <p>Prefactura</p>
                      </li>
                    </>
                  )}
                </S.SubmenuContainer>
              );
            } else if (menu.key === REPORTES.key) {
              return (
                <S.SubmenuContainer key={menu.key}>
                  <li
                    onClick={() => {
                      openInformes();
                    }}
                  >
                    <p>{REPORTES.label}</p>
                  </li>
                </S.SubmenuContainer>
              );
            } else {
              return (
                <li
                  key={menu.key}
                  className={menu.key === active ? "active" : null}
                >
                  <Link
                    to={`/${menu.key}`}
                    onClick={() => handleMenuChange(menu.key)}
                  >
                    {menu.label}
                  </Link>
                </li>
              );
            }
          })}
          {!MENUS.some((menu) => menu.key === active) && (
            <li className="active">
              {active === USUARIOS.key ? (
                <Link to={`/${USUARIOS.key}`}>{USUARIOS.label}</Link>
              ) : (
                <p>
                  {MENUS_EXTRA.find((menu) => menu.key === pathname)?.label ||
                    pathname}
                </p>
              )}
            </li>
          )}
        </ul>
      </S.MenuContainer>
      <S.UserContainer>
        <S.User>{username}</S.User>
        <S.ButtonsContainer>
          <Button
            label="▾"
            onClick={() => {
              setUserMenuOpened(!userMenuOpened);
              setReportMenuOpened(false);
            }}
          />
          {userMenuOpened && (
            <S.Ul>
              {permissions && permissions["Users"]?.access === 1 && (
                <>
                  <Link to={"/Users"}>
                    <S.Li onClick={() => setUserMenuOpened(false)}>
                      Usuarios
                    </S.Li>
                  </Link>
                  <Link to={"/Roles"}>
                    <S.Li onClick={() => setUserMenuOpened(false)}>Roles</S.Li>
                  </Link>
                  <Link to={"/Zonas"}>
                    <S.Li onClick={() => setUserMenuOpened(false)}>Zonas</S.Li>
                  </Link>
                </>
              )}
              <S.Li id="last" onClick={logout}>
                Cerrar sesión
              </S.Li>
            </S.Ul>
          )}
        </S.ButtonsContainer>
      </S.UserContainer>
    </S.Nav>
  );
};

NavMenu.propTypes = {
  pathname: PropTypes.string.isRequired,
};
