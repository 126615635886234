import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const DialogConfirm = ({ message, labelConfirm, confirm, cancel }) => {
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleBar>
          <S.Close onClick={cancel}>×</S.Close>
        </S.TitleBar>
        <S.Content>
          <p>{message}</p>
          <S.Buttons>
            {confirm && <button onClick={confirm}>{labelConfirm}</button>}
            {cancel && <button onClick={cancel}>Cancelar</button>}
          </S.Buttons>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

DialogConfirm.propTypes = {
  message: PropTypes.string.isRequired,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
