import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { Link } from "react-router-dom";
import * as API from "../../api";
import {
  DELAY_BUSCADOR_FINCASAP,
  FINCASAP_POR_PAGINA,
  TITULOS_COLUMNAS_FINCASAP,
} from "../../constants";
import { Loading } from "../../components/Loading";
import { FilterRow } from "../../components/FilterRow";
import { Sort } from "../../components/Sort";
import { Button } from "../../components/Button";
import { usePermissions } from "../../context/PermissionsContext";

export const FincasAp = () => {
  const [loading, setLoading] = useState(true);
  const { permissions } = usePermissions();
  const [loadingCount, setLoadingCount] = useState(true);
  const [fincasAp, setFincasAp] = useState([]);
  const [pages, setPages] = useState(1);
  const [countFincasAp, setCountFincasAp] = useState(0);
  const [allShowed, setAllShowed] = useState(false);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [mainFilter, setMainFilter] = useState("todas-las-fincas");
  const [openedFilter, setOpenedFilter] = useState(false);
  const [selectableFilters, setSelectableFilters] = useState([]);
  const [filters, setFilters] = useState([{ FilterId: "", FilterValue: "" }]);
  const [sortActive, setSortActive] = useState({
    FieldId: TITULOS_COLUMNAS_FINCASAP[0].key,
    Order: "asc",
  });
  const delayTimer = useRef();
  useEffect(() => {
    getCountFincasAp();
    getFincasAp();
    API.getSearchFilter("UB_FincasAp")
      .then((res) => {
        initializationFilter(res);
        setSelectableFilters(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    triggerSearch && handleFiltersChange();
  }, [triggerSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const initializationFilter = (selectableFilters) => {
    const FincasFilterId = selectableFilters.find(
      (filter) => filter.FilterName === "Finca Aportada"
    )?.FilterId;
    const filtros = [{ FilterId: FincasFilterId }];
    setFilters(filtros);
  };

  const pagination = (page) => {
    return { Page: page, PageSize: FINCASAP_POR_PAGINA };
  };

  const getValidActiveFilters = () => {
    const activeFilters =
      mainFilter === "todas-las-fincas" ? [filters[0]] : filters;
    const validFilters = activeFilters.filter(
      (filter) => filter.FilterId && filter.FilterValue
    );
    return validFilters;
  };

  const getCountFincasAp = () => {
    setLoadingCount(true);
    let data = {};
    const validActiveFilters = getValidActiveFilters();
    if (validActiveFilters.length) data.Filters = validActiveFilters;

    API.getCountFincasAp(data)
      .then((res) => {
        setCountFincasAp(res?.count);
        setLoadingCount(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const getFincasAp = (page = 1) => {
    setLoading(true);
    let data = {};
    data.Pagination = pagination(page);
    const validActiveFilters = getValidActiveFilters();
    if (validActiveFilters.length) data.Filters = validActiveFilters;
    data.Sort = sortActive;

    API.getFincasAp(data)
      .then((res) => {
        if (res) {
          if (page === 1 && res.length === 0) setFincasAp([]);
          if (res.length > 0) {
            if (page === 1) setFincasAp(res);
            else setFincasAp([...fincasAp, ...res]);
          }
          if (res.length < FINCASAP_POR_PAGINA) {
            setAllShowed(true);
          }
          setPages(page);
        }
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const handleFiltersChange = () => {
    API.sourceGetCountFincasAp.cancel();
    API.sourceGetFincasAp.cancel();
    setAllShowed(false);
    clearTimeout(delayTimer.current);
    delayTimer.current = setTimeout(() => {
      getCountFincasAp();
      getFincasAp();
    }, DELAY_BUSCADOR_FINCASAP);
    setTriggerSearch(false);
  };

  const showMore = () => getFincasAp(pages + 1);

  const showedFincasAp = allShowed
    ? countFincasAp
    : pages * FINCASAP_POR_PAGINA;
  const totalFincasAp = loadingCount
    ? `${FINCASAP_POR_PAGINA}+`
    : countFincasAp;

  const changeMainFilter = (e) => {
    const value = e.target.value;
    if (value === "todas-las-fincas") setOpenedFilter(false);
    if (value === "filtros") {
      if (filters.length === 1) addFilter();
      setOpenedFilter(true);
    }
    setMainFilter(value);

    const totalValidFilters = filters.filter(
      (filter) => filter.FilterId && filter.FilterValue
    ).length;
    const defaultsFilter = [filters[0]];
    const defaultsValidFilters = defaultsFilter.filter(
      (filter) => filter.FilterId && filter.FilterValue
    ).length;
    if (totalValidFilters !== defaultsValidFilters) setTriggerSearch(true);
  };

  const addFilter = () => {
    const filtros = [...filters];
    filtros.push({ FilterId: "", FilterValue: "" });
    setFilters(filtros);
  };

  const removeFilter = (id) => {
    const filtros = [...filters];
    if (filters.length === 2) {
      changeFieldFilter(1, "");
      setFilters(filtros);
    }
    if (filters.length > 2) {
      filtros.splice(id, 1);
      setFilters(filtros);
      if (filters[id].FilterValue !== "") setTriggerSearch(true);
    }
  };

  const changeFieldFilter = (id, value) => {
    const filtros = [...filters];
    filtros[id].FilterId = value;
    setFilters(filtros);
    if (filtros[id].FilterValue !== "") changeTextFilter(id, "");
  };

  const changeTextFilter = (id, value) => {
    const filtros = [...filters];
    filtros[id].FilterValue = value;
    setFilters(filtros);
    setTriggerSearch(true);
  };

  const sort = (field, value) => {
    setSortActive({
      FieldId: field,
      Order: value,
    });
    setTriggerSearch(true);
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.TitleBar>
        Fincas ({showedFincasAp} de {totalFincasAp})
        {permissions.UB_FincasAp?.edit === 1 && (
          <S.ButtonsContainer>
            <Link to={"/UB_FincasAp/create"}>
              <Button label="Crear" disabled={loading} />
            </Link>
          </S.ButtonsContainer>
        )}
      </S.TitleBar>
      <S.SearchBar>
        <S.SelectSearchBar onChange={changeMainFilter} value={mainFilter}>
          <option value="todas-las-fincas">Todas las Fincas</option>
          <option value="filtros">Filtros</option>
        </S.SelectSearchBar>
        {mainFilter === "filtros" && (
          <S.ButtonSearchBar onClick={() => setOpenedFilter(!openedFilter)}>
            Filtros 👁
          </S.ButtonSearchBar>
        )}
        <S.InputSearchBar
          id="searchField"
          placeholder="Buscar por finca..."
          value={filters[0].FilterValue || ""}
          onChange={(e) => changeTextFilter(0, e.target.value)}
        />
      </S.SearchBar>
      <S.FilterBars hidden={!openedFilter}>
        {filters.map(
          (filter, index) =>
            index > 0 && (
              <FilterRow
                key={index}
                id={index}
                filter={filter}
                selectableFilters={selectableFilters}
                addFilter={addFilter}
                removeFilter={removeFilter}
                changeFieldFilter={changeFieldFilter}
                changeTextFilter={changeTextFilter}
              />
            )
        )}
      </S.FilterBars>
      <S.Table>
        <thead>
          <tr>
            {TITULOS_COLUMNAS_FINCASAP.map((titulo) => (
              <S.TdThead
                key={titulo.key}
                className={titulo.key === sortActive.FieldId ? "active" : null}
              >
                <S.HeaderColumn>
                  <span>{titulo.label}</span>
                  {titulo.sortable && (
                    <Sort
                      field={titulo.key}
                      sortActive={sortActive}
                      onClick={(value) => sort(titulo.key, value)}
                    />
                  )}
                </S.HeaderColumn>
              </S.TdThead>
            ))}
          </tr>
        </thead>
        <tbody>
          {fincasAp.length > 0 &&
            fincasAp.map((fincaAp) => (
              <tr key={fincaAp.Id}>
                <td>
                  <Link to={"/UB_FincasAp/" + fincaAp.Id}>{fincaAp.Name}</Link>
                </td>
                <td>{fincaAp.Expediente}</td>
                <td>{fincaAp.IdPrinexLargo}</td>
                <td>{fincaAp.Idprinex}</td>
                <td>{fincaAp.FechaCreacion}</td>
                <td>{fincaAp.LastModified}</td>
              </tr>
            ))}
        </tbody>
      </S.Table>
      <S.Pagination>
        <S.More onClick={showMore} disabled={loading || allShowed}>
          {allShowed ? "No hay más fincas" : "Más fincas..."}
        </S.More>
      </S.Pagination>
    </S.Container>
  );
};
