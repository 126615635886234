import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PermissionsProvider } from "./context/PermissionsContext/index";
import { BrowserRouter as Router } from "react-router-dom";
const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <PermissionsProvider>
        <Router>
          <App />
        </Router>
      </PermissionsProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
