import React, { useState, useEffect, useRef } from "react";
import * as S from "./styles";
import { useParams, useHistory } from "react-router-dom";
import * as API from "../../api";
import { FieldText } from "../../components/FieldText";
import { FieldList } from "../../components/FieldList";
import { FieldListSearch } from "../../components/FieldListSearch";
import { FieldTextArea } from "../../components/FieldTextArea";
import { FieldCheckbox } from "../../components/FieldCheckbox";
import { FieldNumber } from "../../components/FieldNumber";
import { FieldNoAccess } from "../../components/FieldNoAccess";
import { Button } from "../../components/Button";
import { Module } from "../../components/Module";
import { Loading } from "../../components/Loading";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST } from "../../constants";
import { Link } from "react-router-dom";
import { usePermissions } from "../../context/PermissionsContext";

export const DetalleDatoPrinex = () => {
  const { idDatoPrinex } = useParams();
  const { permissions } = usePermissions();
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState("");
  const [submenus, setSubmenus] = useState([]);
  const [fields, setFields] = useState({});
  const [detalleDatoPrinex, setDetalleDatoPrinex] = useState({});
  const [detalleDatoPrinexListas, setDetalleDatoPrinexListas] = useState({});
  const [editarOpened, setEditarOpened] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const form = useRef();
  const timerToast = useRef();
  const history = useHistory();
  useEffect(() => {
    const dataRenderPage = {
      Module: "UB_datos_prinex",
      RenderType: "detail",
    };
    API.renderPage(dataRenderPage)
      .then((res) => {
        const submenus = Object.keys(res);
        setSubmenus(submenus);
        setSection(submenus[0]);
        setFields(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
    API.getListByModule("UB_datos_prinex")
      .then((res) => {
        setDetalleDatoPrinexListas(res);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  }, []);
  useEffect(() => {
    API.getDatoPrinex(idDatoPrinex)
      .then((res) => {
        setDetalleDatoPrinex(res);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
        setTimeout(
          () => history.push("/UB_datos_prinex"),
          DEFAULT_TIMER_TOAST - 100
        );
      })
      .finally(() => setLoading(false));
  }, [idDatoPrinex, history]);
  useEffect(() => {
    document.addEventListener("wheel", blurActiveInputNumber);
    return () => document.removeEventListener("wheel", blurActiveInputNumber);
  }, []);

  const blurActiveInputNumber = () =>
    document.activeElement.type === "number" && document.activeElement.blur();

  const updateDetail = () => {
    API.getDatoPrinex(idDatoPrinex)
      .then((res) => {
        setDetalleDatoPrinex(res);
      })
      .catch((error) => {
        API.DEVELOP && console.log(error);
        error.code !== 401 &&
          toast("error", `${error.msg.Message} (código ${error.code})`);
        setTimeout(
          () => history.push("/UB_datos_prinex"),
          DEFAULT_TIMER_TOAST - 100
        );
      })
      .finally(() => setLoading(false));
  };

  const editable = detalleDatoPrinex.Editable;

  const handleChange = (key, value) => {
    const newDetalleDatoPrinex = { ...detalleDatoPrinex };
    newDetalleDatoPrinex[key] = value;
    setDetalleDatoPrinex(newDetalleDatoPrinex);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveDatoPrinex();
  };

  const saveDatoPrinex = () => {
    setLoading(true);
    setEditarOpened(false);
    const formData = new FormData(form.current);
    const objectFormData = Object.fromEntries(formData);
    objectFormData.Id = idDatoPrinex;
    objectFormData.LastModified = detalleDatoPrinex.LastModified;
    Object.values(fields)
      .flat()
      .filter((e) => e.type === "enum_multiple")
      .map((e) => e.key)
      .forEach((key) => (objectFormData[key] = formData.getAll(key)));

    API.saveDatoPrinex(objectFormData)
      .then((res) => {
        if (res.Status === "OK") {
          handleChange("LastModified", res.LastModified);
          toast("success", "Dato ERP Guardado");
        } else if (res.Status === "NeedReload") {
          toast(
            "error",
            "Se encontró una version mas reciente del dato ERP.\nSe requiere recargar (se perderán los cambios no guardados)",
            5000
          );
        } else if (res.Status === "KO") {
          toast("error", res.Msg, 5000);
        } else {
          toast("info", res.Status, 5000);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0) {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  const render_submenu_fields = (fields) => (
    <S.FieldsContainer>
      {fields.map((field, index) => {
        const { key, label, type, editable, relateLink } = field;

        switch (type) {
          case "text":
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleDatoPrinex[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
              />
            );

          case "enum":
            const currentLabel =
              detalleDatoPrinex[key] &&
              detalleDatoPrinexListas[key]?.SelectValues.find(
                (option) => option.Id === detalleDatoPrinex[key]
              )?.Value;
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOption={{
                  value: detalleDatoPrinex[key] || "",
                  label: currentLabel,
                }}
                options={detalleDatoPrinexListas[key]?.SelectValues}
                readOnly={editable === "0"}
                canBeEmpty={detalleDatoPrinexListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
              />
            );

          case "enum_multiple":
            const currentOptions = detalleDatoPrinex[key]?.map((id) => {
              const option = {};
              option.value = id;
              option.label = detalleDatoPrinexListas[key]?.SelectValues.find(
                (option) => option.Id === id
              )?.Value;
              return option;
            });
            return (
              <FieldList
                key={key}
                id={key}
                label={label}
                type={type}
                currentOptions={currentOptions}
                options={detalleDatoPrinexListas[key]?.SelectValues}
                readOnly={editable === "0"}
                canBeEmpty={detalleDatoPrinexListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
              />
            );

          case "relate":
            return (
              <FieldListSearch
                key={key}
                id={key}
                label={label}
                listName={detalleDatoPrinexListas[key]?.ListName}
                currentOption={{
                  value: detalleDatoPrinex[key]?.Value || "",
                  label: detalleDatoPrinex[key]?.Label,
                }}
                readOnly={editable === "0"}
                canBeEmpty={detalleDatoPrinexListas[key]?.ShowEmpty === 1}
                handleChange={handleChange}
                relateLink={relateLink}
              />
            );

          case "textarea":
            return (
              <FieldTextArea
                key={key}
                id={key}
                label={label}
                readOnly={editable === "0"}
                value={detalleDatoPrinex[key] || ""}
                handleChange={handleChange}
              />
            );

          case "bool":
            return (
              <FieldCheckbox
                key={key}
                id={key}
                label={label}
                readOnly={editable === "0"}
                value={detalleDatoPrinex[key] || false}
                handleChange={handleChange}
              />
            );

          case "int":
          case "float":
          case "decimal":
            return (
              <FieldNumber
                key={key}
                id={key}
                label={label}
                readOnly={editable === "0"}
                value={detalleDatoPrinex[key] || 0}
                empty={
                  detalleDatoPrinex[key] === "" ||
                  detalleDatoPrinex[key] === null
                }
                handleChange={handleChange}
                type={type}
              />
            );

          case "Empty":
            return <li key={`empty${index}`} />;

          case "no_access":
            return <FieldNoAccess key={key} id={key} label={label} />;

          default:
            return (
              <FieldText
                key={key}
                id={key}
                label={label}
                value={detalleDatoPrinex[key] || ""}
                handleChange={handleChange}
                readOnly={editable === "0"}
              />
            );
        }
      })}
    </S.FieldsContainer>
  );

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          <FieldText
            id="Name"
            value={detalleDatoPrinex.Name || ""}
            handleChange={handleChange}
            form="form"
            type="title"
          />
        </S.WrapperFieldText>
        <S.ButtonsContainer>
          <Button
            label="Guardar"
            onClick={saveDatoPrinex}
            disabled={!editable || loading}
          />
          <Button
            label="▾"
            onClick={() => setEditarOpened(!editarOpened)}
            disabled={!editable || loading}
          />
          {editarOpened && (
            <S.Ul>
              <Link
                to={{
                  pathname: `/UB_datos_prinex/${idDatoPrinex}/audit`,
                  entityname: detalleDatoPrinex.Name,
                }}
              >
                <S.Li>Ver el registro de auditoría</S.Li>
              </Link>
            </S.Ul>
          )}
        </S.ButtonsContainer>
      </S.TitleBar>
      <S.SubMenuBar>
        <ul>
          <S.Submenu id="dummy" />
          {submenus.map((submenu) => (
            <S.Submenu
              key={submenu}
              className={submenu === section ? "active" : null}
              onClick={() => setSection(submenu)}
            >
              {submenu}
            </S.Submenu>
          ))}
        </ul>
      </S.SubMenuBar>
      <form id="form" ref={form} onSubmit={handleSubmit}>
        <S.Content>
          {submenus.map((submenu) => (
            <div
              key={submenu}
              className={submenu === section ? "active" : "inactive"}
            >
              {fields[submenu] && render_submenu_fields(fields[submenu])}
            </div>
          ))}
        </S.Content>
      </form>
      <S.ModulesContainer>
        {detalleDatoPrinex.ModulesCount &&
          Object.entries(detalleDatoPrinex.ModulesCount)
            .filter((moduleInfo) => permissions[moduleInfo[0]]?.access === 1)
            .map((moduleInfo, index) => (
              <Module
                key={index}
                moduleInfo={moduleInfo}
                sourceModule={{
                  type: "UB_datos_prinex",
                  id: idDatoPrinex,
                  name: detalleDatoPrinex.Name,
                }}
                getModule={API.getModuleDatoPrinex}
                updateDetail={updateDetail}
                setLoading={setLoading}
                access={permissions}
                visibleInTab={true}
              />
            ))}
      </S.ModulesContainer>
    </S.Container>
  );
};
