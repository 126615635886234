import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { RegistroSubModulo } from "../../components/RegistroSubModulo";
import { Sort } from "../../components/Sort";
import { Title } from "../FieldList/styles";

export const ListadoModulo = ({
  data,
  showMore,
  allShowed,
  recordsLoading,
  module,
  moduleLists,
  sourceModule,
  updateRecords,
  setLoading,
  allowLinkElement,
  allowCreateRecordChild,
  inheritedFields,
  access,
  setSubmodulesResetTrigger,
  orderChange,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState("");
  const { labels, dataContent } = data;
  
  const [sortActive, setSortActive] =  useState({order : "asc",orderField : "Inicial"});
  const sort = (field, value) => {
      setSortActive({
          orderField:field,
        order: value,
      });
      
      orderChange?.(value, field);
    };

  const handleDropdown = (id) => {
    if (id === dropdownOpened) setDropdownOpened("");
    else setDropdownOpened(id);
  };

  return (
    <>
      <S.Table>
        <S.Tr>
          {labels.map((field) => (
            <S.Th key={field.key} title={field.label}>         
              {field.label}
              {data.isVisible && <Sort
                      field={!("Inicial"===sortActive.orderField) && Title.key}
                      sortActive={sortActive}
                      onClick={(value) => sort(field.label, value)}
                      />}
            </S.Th>
          ))}
          <S.ThEdit />
        </S.Tr>
        {dataContent.map((registro) => (
          <RegistroSubModulo
            key={registro.Id}
            registro={registro}
            labels={labels}
            dropdownOpened={dropdownOpened}
            handleDropdown={handleDropdown}
            module={module}
            moduleLists={moduleLists}
            sourceModule={sourceModule}
            updateRecords={updateRecords}
            setLoading={setLoading}
            allowLinkElement={allowLinkElement}
            allowCreateRecordChild={allowCreateRecordChild}
            inheritedFields={inheritedFields}
            access={access}
            setSubmodulesResetTrigger={setSubmodulesResetTrigger}
          />
        ))}
      </S.Table>
      <S.Pagination>
        {dataContent.length === 0 || recordsLoading ? (
          <S.Loading>Cargando...</S.Loading>
        ) : (
          <S.More
            onClick={showMore}
            disabled={
              allShowed ||
              module === "UB_Hitos_Urbanisticos" ||
              module === "UB_UsosPricing" ||
              module === "UB_estructuraprop"

            }
          >
            {allShowed ||
            module === "UB_Hitos_Urbanisticos" ||
            module === "UB_UsosPricing" ||
            module === "UB_estructuraprop"
              ? "No hay más registros"
              : "Más registros..."}
          </S.More>
        )}
      </S.Pagination>
    </>
  );
};

ListadoModulo.propTypes = {
  data: PropTypes.shape({
    dataContent: PropTypes.array,
    labels: PropTypes.array,
    isVisible:PropTypes.bool,
  }).isRequired,
  showMore: PropTypes.func,
  allShowed: PropTypes.bool,
  recordsLoading: PropTypes.bool,
  module: PropTypes.string.isRequired,
  moduleLists: PropTypes.object,
  sourceModule: PropTypes.objectOf(PropTypes.string),
  updateRecords: PropTypes.func,
  setLoading: PropTypes.func,
  allowLinkElement: PropTypes.bool,
  allowCreateRecordChild: PropTypes.bool,
  inheritedFields: PropTypes.objectOf(PropTypes.object),
  access: PropTypes.object,
  setSubmodulesResetTrigger: PropTypes.func,
  orderChange: PropTypes.func,
};
