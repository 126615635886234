import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const Dialog = ({
  message,
  initialValue = "",
  labelConfirm,
  confirm,
  cancel,
}) => {
  const [input, setInput] = useState(initialValue);
  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleBar>
          <S.Close onClick={cancel}>×</S.Close>
        </S.TitleBar>
        <S.Content>
          <p>{message}</p>
          <S.Input value={input} onChange={(e) => setInput(e.target.value)} />
          <S.Buttons>
            {confirm && (
              <button onClick={() => confirm(input)}>{labelConfirm}</button>
            )}
            {cancel && <button onClick={cancel}>Cancelar</button>}
          </S.Buttons>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

Dialog.propTypes = {
  message: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
