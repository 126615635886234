import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const DialogSelect = ({
  message,
  options,
  labelConfirm,
  confirm,
  cancel,
}) => {
  const [value, setValue] = useState("");

  return (
    <S.Container>
      <S.Wrapper>
        <S.TitleBar>
          <S.Close onClick={cancel}>×</S.Close>
        </S.TitleBar>
        <S.Content>
          <p>{message}</p>
          <S.Body>
            <>
              <p>Informe BIRT</p>
              <S.Select
                value={value}
                onChange={(e) => setValue(e.target.value)}
              >
                <option label="" value=""></option>
                {options.map((option) => (
                  <option
                    key={option.value}
                    label={option.label}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </S.Select>
            </>
          </S.Body>
          <S.Buttons>
            {confirm && (
              <button onClick={() => confirm(value)} disabled={!value}>
                {labelConfirm}
              </button>
            )}
            {cancel && <button onClick={cancel}>Cancelar</button>}
          </S.Buttons>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
};

DialogSelect.propTypes = {
  message: PropTypes.string.isRequired,
  options: PropTypes.array,
  labelConfirm: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
