import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as S from "./styles";
import * as API from "../../api";
import { FieldText } from "../../components/FieldText";
import { FieldList } from "../../components/FieldList";
import { FieldTextArea } from "../../components/FieldTextArea";
import { Button } from "../../components/Button";
import { Loading } from "../../components/Loading";
import { Toast } from "../../components/Toast";
import { DEFAULT_TIMER_TOAST } from "../../constants";

export const DetalleRoles = () => {
  const { idRole } = useParams();
  const [loading, setLoading] = useState(true);
  const [detalleRol, setDetalleRol] = useState({});
  const [modules, setModules] = useState([]);
  const [actions, setActions] = useState([]);
  const [detalleRolListas, setDetalleRolListas] = useState([]);
  const [toastVisible, setToastVisible] = useState(false);
  const [dataToast, setDataToast] = useState({});
  const [editionMode, setEditionMode] = useState(false);
  const [modified, setModified] = useState(false);
  const form = useRef();
  const timerToast = useRef();
  const history = useHistory();
  useEffect(() => {
    API.getModules()
      .then((res) => setModules(res))
      .catch((error) => API.DEVELOP && console.log(error));
    API.getRoleAction()
      .then((res) => setActions(res))
      .catch((error) => API.DEVELOP && console.log(error));
    API.GetRolePermitsByAction()
      .then((res) => setDetalleRolListas(res))
      .catch((error) => API.DEVELOP && console.log(error));
    if (idRole === "create") {
      setLoading(false);
    } else {
      API.getRol(idRole)
        .then((res) => {
          setDetalleRol(res);
        })
        .catch((error) => {
          API.DEVELOP && console.log(error);
          error.code !== 401 &&
            toast("error", `${error.msg?.Message} (código ${error.code})`);
          setTimeout(() => history.push("/Roles"), DEFAULT_TIMER_TOAST - 100);
        })
        .finally(() => setLoading(false));
    }
  }, [idRole, history]);

  const create = idRole === "create";

  const handleChange = (key, value) => {
    key !== "LastModified" && setModified(true);
    const newDetalleRol = { ...detalleRol };
    newDetalleRol[key] = value;
    setDetalleRol(newDetalleRol);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    saveRol();
  };

  const saveRol = () => {
    setLoading(true);
    const data = {};
    data.Id = create ? "00000000-0000-0000-0000-000000000000" : idRole;
    data.Name = detalleRol.Name;
    data.Description = detalleRol.Description;
    data.ModuleAccess = detalleRol.ModuleAccess;
    data.LastModified = detalleRol.LastModified;

    API.saveRol(data)
      .then((res) => {
        if (res.Status === "OK") {
          handleChange("LastModified", res.LastModified);
          setEditionMode(false);
          setModified(false);
          if (create) {
            toast("success", "Rol Creado");
            setTimeout(() => history.push("/Roles"), DEFAULT_TIMER_TOAST - 100);
          } else {
            toast("success", "Rol Guardado");
          }
        } else if (res.Status === "KO") {
          toast("error", res.Msg, 5000);
        } else if (res.Status === "NeedReload") {
          toast(
            "error",
            "Se encontró una version mas reciente del rol.\nSe requiere recargar (se perderán los cambios no guardados)",
            5000
          );
        } else {
          toast("info", res.Status, 5000);
        }
      })
      .catch((error) =>
        toast("error", `${error.msg.Message} (código ${error.code})`)
      )
      .finally(() => setLoading(false));
  };

  const toast = (type, message, timeout = DEFAULT_TIMER_TOAST) => {
    setDataToast({ type, message });
    setToastVisible(true);
    if (timeout > 0) {
      timerToast.current = setTimeout(() => setToastVisible(false), timeout);
    }
  };

  const closeToast = () => {
    clearTimeout(timerToast.current);
    setToastVisible(false);
  };

  const moduleList = (moduleType, action, value) => {
    const handleChangePermits = (key, value) => {
      setModified(true);
      const newDetalleRol = { ...detalleRol };
      if (!newDetalleRol.ModuleAccess) newDetalleRol.ModuleAccess = {};
      if (!newDetalleRol.ModuleAccess[moduleType])
        newDetalleRol.ModuleAccess[moduleType] = [];
      if (
        newDetalleRol.ModuleAccess[moduleType].some((e) => e.Action === key)
      ) {
        newDetalleRol.ModuleAccess[moduleType] = newDetalleRol.ModuleAccess[
          moduleType
        ].map((e) => {
          if (e.Action === key) e.Access = value;
          return e;
        });
      } else {
        const permit = { Action: key, Access: value };
        newDetalleRol.ModuleAccess[moduleType].push(permit);
      }
      setDetalleRol(newDetalleRol);
    };

    const defaultOption =
      action === "access"
        ? { value: "-98", label: "AccessNoHabilitado" }
        : { value: "0", label: "NoEstablecido" };

    return (
      <FieldList
        key={action}
        id={action}
        type="enum"
        currentOption={
          value
            ? {
                value,
                label: detalleRolListas[action]?.find((e) => e.Id === value)
                  ?.Value,
              }
            : defaultOption
        }
        options={detalleRolListas[action]}
        canBeEmpty={false}
        handleChange={handleChangePermits}
        readOnly={false}
        required={true}
        alwaysEditing={editionMode || create}
      />
    );
  };

  return (
    <S.Container>
      <Loading hidden={!loading} />
      {toastVisible && <Toast data={dataToast} close={closeToast} />}
      <S.TitleBar>
        <S.WrapperFieldText>
          <FieldText
            id="Name"
            value={detalleRol.Name || ""}
            handleChange={handleChange}
            form="form"
            type="title"
            placeholder="Nuevo nombre..."
            required={true}
            alwaysEditing={editionMode || create}
          />
        </S.WrapperFieldText>
        <S.ButtonsContainer>
          {editionMode || modified || create ? (
            <Button
              label={create ? "Crear" : "Guardar"}
              onClick={saveRol}
              disabled={loading}
            />
          ) : (
            <>
              <Button
                label="Editar"
                onClick={() => setEditionMode(true)}
                disabled={loading}
              />
            </>
          )}
        </S.ButtonsContainer>
      </S.TitleBar>
      <form id="form" ref={form} onSubmit={handleSubmit}>
        <S.Content>
          <S.FieldsContainer>
            <FieldTextArea
              id="Description"
              label="Descripción"
              value={detalleRol.Description || ""}
              handleChange={handleChange}
              required={true}
              alwaysEditing={editionMode || create}
            />
            <S.Permits columns={actions.length + 1}>
              <S.ModuleRow>
                <p>Módulos\Acciones</p>
                {actions.map((action) => (
                  <p key={action}>{action}</p>
                ))}
              </S.ModuleRow>
              {modules.map((moduleType) => (
                <S.ModuleRow key={moduleType}>
                  <p>{moduleType}</p>
                  {actions.map((action) => {
                    const value = detalleRol.ModuleAccess
                      ? detalleRol.ModuleAccess[moduleType]?.find(
                          (e) => e.Action === action
                        )?.Access
                      : "";
                    return moduleList(moduleType, action, value);
                  })}
                </S.ModuleRow>
              ))}
            </S.Permits>
          </S.FieldsContainer>
        </S.Content>
      </form>
    </S.Container>
  );
};
