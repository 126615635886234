import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import { COLORES, ADD_RELATE_ENTITY_TEXT } from "../../constants";
import * as API from "../../api";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";

export const FieldListSearch = ({
  id,
  label,
  listName,
  currentOption,
  canBeEmpty,
  handleChange,
  readOnly,
  required,
  alwaysEditing,
  hidden,
  relateLink,
  allowAddRelateEntity,
}) => {
  const [focused, setFocused] = useState(false);

  const editing = !readOnly && (focused || alwaysEditing);

  const promiseOptions = (e) => {
    return new Promise((resolve) => {
      API.searchValueList(listName, e)
        .then((res) => {
          const values = res.SelectValues;
          if (!allowAddRelateEntity || values.length > 0)
            return resolve([
              ...values.map((option) => ({
                value: option.Id,
                label: option.Value,
              })),
            ]);
          else {
            return resolve([
              {
                value: e,
                label: ADD_RELATE_ENTITY_TEXT + e,
              },
            ]);
          }
        })
        .catch((error) => API.DEVELOP && console.log(error));
    });
  };

  return (
    <S.Container
      onClick={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      className={hidden ? "hidden" : "visible"}
    >
      <S.Title>{label}</S.Title>
      <S.SelectContainer className={editing ? "visible" : "hidden"}>
        <AsyncSelect
          name={id}
          styles={customStyles}
          value={currentOption}
          defaultOptions={[
            {
              value: currentOption?.value,
              label: "Buscar y seleccionar...",
              isDisabled: true,
            },
          ]}
          loadOptions={promiseOptions}
          onChange={(e) =>
            handleChange(id, e ? { Value: e.value, Label: e.label } : "")
          }
          isClearable={canBeEmpty}
          noOptionsMessage={() => "No hay opciones"}
          loadingMessage={() => "Cargando..."}
          required={required}
        />
      </S.SelectContainer>
      <S.Text className={editing ? "hidden" : "visible"}>
        {relateLink?.link ? (
          <Link to={`/${relateLink?.moduleType}/${currentOption?.value}`}>
            {currentOption?.label}
          </Link>
        ) : (
          currentOption?.label
        )}
      </S.Text>
    </S.Container>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.GRIS,
    },
    borderColor: state.isFocused ? COLORES.LIST_ACTIVE : COLORES.GRIS_CLARO,
    backgroundColor: COLORES.FIELD_BACKGROUND,
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    "&.indicatorContainer": {
      padding: "0 8px",
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "6px 12px",
    borderBottom: `1px solid ${COLORES.GRIS_CLARO}`,
    backgroundColor: state.isSelected ? COLORES.LIST_ACTIVE : "white",
    "&:hover": {
      backgroundColor: state.isSelected
        ? COLORES.LIST_ACTIVE
        : COLORES.GRIS_CLARO,
    },
  }),
};

FieldListSearch.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  listName: PropTypes.string,
  currentOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  canBeEmpty: PropTypes.bool,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  alwaysEditing: PropTypes.bool,
  hidden: PropTypes.bool,
  relateLink: PropTypes.object,
  allowAddRelateEntity: PropTypes.bool,
};
