import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 2.5rem - 1px);
`;

export const Buscador = styled.div`
  width: 30%;
  padding: 1rem;
  border-right: thin solid lightgray;
  li {
    list-style: none;
  }
`;

export const FieldsContainer = styled.ul`
  display: grid;
  gap: 0.25rem;
  grid-template-columns: 1fr;
  background-color: ${COLORES.FONDO};
  .hidden {
    visibility: hidden;
  }
`;


export const InputSearchBar = styled.input`
  font-size: 0.75rem;
  flex-grow: 1;
  height: 1.7rem;
  box-sizing: border-box;
  border: thin solid ${COLORES.PLACEHOLDER_BORDER};
  border-radius: 0.2rem;
  padding-left: 0.4rem;
  width: 100%;

  ::placeholder {
    color: ${COLORES.PLACEHOLDER_TEXT};
  }
`;

export const Listado = styled.div`
  display: block;
  margin: 0.5rem 0;
  margin-left: 0.2rem;
  font-size: 0.75rem;

  ul {
    width: 100%;
    height: calc(80vh - 7rem);
    overflow-x: hidden;
    overflow-y: auto;

    li {
      list-style-type: none;
      margin-bottom: 0.5rem;
      white-space: nowrap;
      cursor: pointer;
      :hover {
        font-weight: bold;
      }
    }
  }
`;

export const Mapa = styled.div`
  margin: 1rem;
  width: 70%;

  div.gm-style-iw-c {
    a {
      color: blue;
      text-decoration: none;
    }
  }
`;
