import styled, { css } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  display: grid;
  .active {
    display: inherit;
  }
  .inactive {
    display: none;
  }
  a {
    color: ${COLORES.AZUL_LINK};
    text-decoration: none;
  }
`;

export const TitleBar = styled.div`
  position: sticky;
  top: calc(2.5rem + 1px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: ${COLORES.FONDO_MENU};
  border-bottom: solid 1px ${COLORES.GRIS_CLARO};
  padding: 0 2rem;
  z-index: 3;
`;

export const WrapperFieldText = styled.div`
  flex-grow: 1;
  font-weight: 600;
`;

export const SubMenuBar = styled.div`
  position: sticky;
  top: calc(5.5rem + 2px);
  font-size: 0.6875rem;
  color: ${COLORES.TABS_TEXT};
  display: flex;
  align-items: center;
  height: 2.5rem;
  background-color: ${COLORES.FONDO_MENU};
  z-index: 2;
  ul {
    display: flex;
    width: 100%;
  }
  li {
    list-style: none;
    padding: 0.25rem 1rem;
  }
  li[id*="dummy"] {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }
  li[id="dummy-end"] {
    flex-grow: 1;
  }
`;

export const Submenu = styled.li`
  display: flex;
  align-items: center;
  height: 2rem;
  cursor: pointer;
  border-bottom: solid 1px ${COLORES.GRIS_CLARO};

  &.active {
    font-weight: bold;
    background-color: ${COLORES.BLANCO};
    border-bottom: thin solid ${COLORES.BLANCO};
    border-left: thin solid ${COLORES.GRIS_CLARO};
    border-right: thin solid ${COLORES.GRIS_CLARO};
  }

  :hover {
    background-color: ${COLORES.AZUL_HOVER};
  }
`;

export const Content = styled.div`
  li {
    margin-right: 0.3rem;
    list-style: none;
  }
`;

export const FieldsContainer = styled.ul`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(3rem, auto);
  background-color: ${COLORES.FONDO};
  padding: 1rem 2rem;
  .hidden {
    visibility: hidden;
  }
  .panel {
    grid-column: 1/3;
    border: thin solid ${COLORES.GRIS_CLARO};
    border-left: none;
    border-right: none;
    align-self: center;
    padding: 0.5rem 2rem;
    margin: 0 -2rem;
  }
`;

export const ModulesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const ButtonsContainer = styled.div`
  align-self: flex-start;
  margin: auto 0 auto 1rem;

  button {
    font-size: 0.875rem;
    font-weight: 600;
    margin: 0;
    padding: 0.35rem 0.5rem;
    border: thin solid ${COLORES.NEGRO};
    border-right: none;
    border-radius: 0;
    :hover {
      background-color: ${COLORES.AZUL_HOVER_DARK};
    }
    :disabled {
      background-color: ${COLORES.GRIS_CLARO};
    }
  }
  button:first-of-type {
    border-radius: 0.2rem 0 0 0.2rem;
  }
  button:last-of-type {
    border-radius: 0 0.2rem 0.2rem 0;
    border-right: thin solid ${COLORES.NEGRO};
  }
  button:only-of-type {
    border-radius: 0.2rem;
    border: thin solid ${COLORES.NEGRO};
  }
  a {
    color: ${COLORES.NEGRO};
    text-decoration: none;
  }
`;

export const Ul = styled.ul`
  list-style: none;
  background-color: ${COLORES.BLANCO};
  border: thin solid ${COLORES.GRIS_CLARO};
  border-radius: 0.2rem;
  font-size: 0.9rem;
  position: absolute;
  right: 2rem;
  text-align: right;
`;

export const Li = styled.li`
  font-size: 0.75rem;
  font-weight: normal;
  margin: auto;
  height: 1.75rem;
  line-height: 1.75rem;
  padding: 0 0.5rem;
  border-bottom: thin solid ${COLORES.GRIS_CLARO};
  cursor: pointer;
  :nth-last-child(1) {
    border-bottom: none;
  }
  :hover {
    background-color: ${COLORES.AZUL_HOVER};
  }
  ${(props) =>
    props.disabled &&
    css`
      color: ${COLORES.GRIS};
      cursor: auto;
    `}
`;

export const Permits = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-auto-rows: 2.2rem;
  p,
  li {
    align-self: center;
  }
`;

export const ModuleRow = styled.div`
  display: contents;
`;
