import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import * as API from "../../api";
import AsyncSelect from "react-select/async";

export const FieldGridListSearch = ({
  id,
  listName,
  currentOption,
  canBeEmpty,
  handleChange,
  beingEdited,
  readOnly,
  required,
}) => {
  const editing = beingEdited && !readOnly;

  const promiseOptions = (e) => {
    return new Promise((resolve) => {
      API.searchValueList(listName, e)
        .then((res) =>
          resolve(
            res.SelectValues.map((option) => ({
              value: option.Id,
              label: option.Value,
            }))
          )
        )
        .catch((error) => API.DEVELOP && console.log(error));
    });
  };

  return (
    <S.Container editing={editing}>
      <S.SelectContainer className={editing ? "visible" : "hidden"}>
        <AsyncSelect
          name={id}
          styles={customStyles}
          value={currentOption}
          defaultOptions={[
            {
              value: "default",
              label: "Buscar y seleccionar...",
              isDisabled: true,
            },
          ]}
          loadOptions={promiseOptions}
          onChange={(e) =>
            handleChange(id, e ? { Value: e.value, Label: e.label } : "")
          }
          isClearable={canBeEmpty}
          noOptionsMessage={() => "No hay opciones"}
          loadingMessage={() => "Cargando..."}
          required={required}
        />
      </S.SelectContainer>
      <S.Text
        title={currentOption?.label}
        className={editing ? "hidden" : "visible"}
      >
        {currentOption?.label}
      </S.Text>
    </S.Container>
  );
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "1rem",
    height: "1.5rem",
    boxShadow: "none",
    "&:hover": {
      borderColor: state.isFocused ? "indianred" : "grey",
    },
    borderColor: state.isFocused ? "indianred" : "lightgrey",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: "5px",
  }),
  indicatorSeparator: (provided, state) => ({}),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    "&.indicatorContainer": {
      padding: "0 2px",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "1.2rem",
    padding: "0 8px",
  }),
  input: (provided, state) => ({
    ...provided,
    height: "1.5rem",
    marginTop: "1px",
    div: {
      input: {
        verticalAlign: "top",
      },
    },
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    padding: "0 8px",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0px 12px",
    borderBottom: "1px solid lightgrey",
    backgroundColor: state.isSelected ? "indianred" : "white",
    "&:hover": {
      backgroundColor: state.isSelected
        ? "indianred"
        : state.isDisabled
        ? "white"
        : "lightgrey",
    },
    color: state.isDisabled ? "darkgrey" : "initial",
  }),
  loadingMessage: (provided, state) => ({
    ...provided,
    lineHeight: "1rem",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    lineHeight: "1rem",
  }),
};

FieldGridListSearch.propTypes = {
  id: PropTypes.string.isRequired,
  listName: PropTypes.string,
  currentOption: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  canBeEmpty: PropTypes.bool,
  handleChange: PropTypes.func,
  beingEdited: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};
