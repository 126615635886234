import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Button = styled.button`
  background-color: ${(props) => props.backgroundColor || COLORES.AZUL};
  color: ${(props) => props.color || COLORES.BLANCO};
  margin: 0.5rem;
  padding: 0.2rem 1rem;
  border-radius: 0.2rem;
  border: thin solid ${COLORES.AZUL};
  cursor: pointer;

  &:disabled {
    background-color: ${COLORES.GRIS_CLARO};
    border: thin solid ${COLORES.GRIS_OSCURO};
    cursor: not-allowed;
  }
`;
