import React, { useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldDate = ({
  id,
  label,
  value,
  handleChange,
  readOnly,
  required,
  alwaysEditing,
}) => {
  const [focused, setFocused] = useState(false);

  const editing = !readOnly && (focused || alwaysEditing);
  const valueSplit = value.split("-");
  const empty = valueSplit[0] === "" || valueSplit[0] === "0001";
  const visibleValue = empty
    ? ""
    : `${valueSplit[2]}/${valueSplit[1]}/${valueSplit[0]}`;

  return (
    <S.Container
      onClick={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      {label && <S.Title>{label}</S.Title>}
      <S.InputContainer>
        <S.InputWrapper className={editing ? "visible" : "hidden"}>
          <S.Input
            name={id}
            type="date"
            value={empty ? "" : value}
            onChange={(e) => handleChange(id, e.target.value)}
            readOnly={readOnly}
            required={required}
          />
        </S.InputWrapper>
        <S.Text className={editing ? "hidden" : "visible"}>
          {visibleValue}
        </S.Text>
      </S.InputContainer>
    </S.Container>
  );
};

FieldDate.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  initialValue: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  alwaysEditing: PropTypes.bool,
};
