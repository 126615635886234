import axios from "axios";
import { loginRequest } from "../authConfig";
import { printVigenciaToken } from "../App";

const { NODE_ENV, REACT_APP_BASE_URL, REACT_APP_BASE_API_URL } = process.env;
export const DEVELOP = NODE_ENV === "development";

export const APP_BASE_URL = DEVELOP
  ? "http://localhost:3000"
  : REACT_APP_BASE_URL;
const BASE_API_URL = REACT_APP_BASE_API_URL;
const URL_AUTHENTICATE = "/Auth";

const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
});

let requestInterceptorId;
let responseInterceptorId;

export let sourceGetCountSuelos = axios.CancelToken.source();
export let sourceGetSuelos = axios.CancelToken.source();
export let sourceGetCountFincasAp = axios.CancelToken.source();
export let sourceGetFincasAp = axios.CancelToken.source();
export let sourceGetCountFincasRe = axios.CancelToken.source();
export let sourceGetFincasRe = axios.CancelToken.source();
export let sourceGetCountDatosPrinex = axios.CancelToken.source();
export let sourceGetDatosPrinex = axios.CancelToken.source();
export let sourceGetModulos = axios.CancelToken.source();
export let sourceGetAuditorias = axios.CancelToken.source();
export let sourceGetUsers = axios.CancelToken.source();
export let sourceGetCountUsers = axios.CancelToken.source();
export let sourceGetZones = axios.CancelToken.source();
export let sourceGetCountZones = axios.CancelToken.source();

const isTokenValid = (url = "empty_url", account) => {
  const currentTime = Date.now();
  const msalToken = localStorage.getItem("msalToken");
  const msalTokenExpiresOn = Number(localStorage.getItem("msalTokenExpiresOn"));
  const appToken = localStorage.getItem("appToken");
  const appTokenExpiresOn = Number(localStorage.getItem("appTokenExpiresOn"));

  if (DEVELOP) {
    console.group(`%c¿isTokenValid? ${url}`, "color: yellow");
    console.log(`%c[IReq] ${new Date(currentTime)}`, "color: cyan;");
    console.log(`[IReq] account`, account?.username);
    console.log(`[IReq] msalToken`, msalToken?.substring(0, 100));
    printVigenciaToken(msalTokenExpiresOn, "msalTokenExpiresOn", "IReq");
    console.log(`[IReq] appToken`, appToken?.substring(0, 100));
    printVigenciaToken(appTokenExpiresOn, "appTokenExpiresOn", "IReq");
  }

  if (url === URL_AUTHENTICATE) {
    if (account && msalToken && currentTime < msalTokenExpiresOn) {
      if (DEVELOP) {
        console.log(
          "%c[IReq] isTokenValid",
          "color: lightgreen; font-weight: bold"
        );
        console.groupEnd();
      }
      return true;
    }

    if (DEVELOP) {
      console.log("%c[IReq] !isTokenValid", "color: red; font-weight: bold");
      console.groupEnd();
    }
    return false;
  }

  // url !== URL_AUTHENTICATE
  if (
    account &&
    msalToken &&
    currentTime < msalTokenExpiresOn &&
    appToken &&
    currentTime < appTokenExpiresOn
  ) {
    if (DEVELOP) {
      console.log(
        "%c[IReq] isTokenValid",
        "color: lightgreen; font-weight: bold"
      );
      console.groupEnd();
    }
    return true;
  }

  if (DEVELOP) {
    console.log("%c[IReq] !isTokenValid", "color: red; font-weight: bold");
    console.groupEnd();
  }
  return false;
};

const waitForLocalStorage = (key, timeout = 5000, interval = 100) => {
  return new Promise((resolve, reject) => {
    if (localStorage.getItem(key) !== null) {
      return resolve(localStorage.getItem(key));
    }

    const startTime = Date.now();
    const checkInterval = setInterval(() => {
      if (localStorage.getItem(key) !== null) {
        clearInterval(checkInterval);
        resolve(localStorage.getItem(key));
      } else if (Date.now() - startTime > timeout) {
        clearInterval(checkInterval);
        reject(new Error(`Timeout: ${key} no encontrado en localStorage`));
      }
    }, interval);
  });
};

export const configureAxios = (msalInstance, setPermissions) => {
  DEVELOP && console.log("[configureAxios] Start");
  //region requestInterceptor
  if (requestInterceptorId !== undefined)
    axiosInstance.interceptors.request.eject(requestInterceptorId);

  let tokenFetching = false;
  const pendingRequestsQueue = [];

  const resolvePendingRequests = (newToken) => {
    DEVELOP && console.log("%cresolvePendingRequests", "color: yellow");
    pendingRequestsQueue.forEach(({ resolve, config }) => {
      DEVELOP && console.log(`%cResolviendo ${config.url}`, "color: magenta");
      const newConfig = { ...config };
      newConfig.headers.Authorization = `Bearer ${newToken}`;
      resolve(newConfig);
    });
    pendingRequestsQueue.length = 0;
  };

  const rejectPendingRequests = (error) => {
    DEVELOP && console.log("%crejectPendingRequests", "color: pink");
    pendingRequestsQueue.forEach(({ reject }) => reject(error));
    pendingRequestsQueue.length = 0;
  };

  requestInterceptorId = axiosInstance.interceptors.request.use(
    async (config) => {
      const account = msalInstance.getActiveAccount();
      let appToken = localStorage.getItem("appToken") || "";
      try {
        await waitForLocalStorage("started");
      } catch (error) {
        console.error("waitForLocalStorage", error);
      }

      if (isTokenValid(config.url, account)) {
        if (config.url !== URL_AUTHENTICATE) {
          config.headers.Authorization = `Bearer ${appToken}`;
        }
        return config;
      } else {
        if (tokenFetching) {
          // Si la renovación del token está en curso, encola la solicitud
          const promise = new Promise((resolve, reject) => {
            DEVELOP &&
              console.log(
                `%ctokenFetching, ${config.url} a la cola`,
                "color: magenta"
              );
            pendingRequestsQueue.push({ resolve, reject, config });
          });
          // Espera hasta que se resuelva la promesa para completar la llamada
          await promise;
          return config;
        } else {
          DEVELOP &&
            console.log(
              `%c!tokenFetching -> ${config.url} inicia obtención de nuevo(s) token(s)`,
              "color: magenta"
            );
          tokenFetching = true;
          try {
            const currentTime = Date.now();
            const msalTokenExtExpiresOn = Number(
              localStorage.getItem("msalTokenExtExpiresOn")
            );
            if (!msalTokenExtExpiresOn || currentTime > msalTokenExtExpiresOn) {
              DEVELOP &&
                console.log(
                  "%c[IReq] msalTokenExtExpiresOn expirado (o inexistente) => msalInstace.loginRedirect",
                  "color: pink"
                );
              msalInstance
                .loginRedirect(loginRequest)
                .catch((error) =>
                  console.error(
                    "[IReq] msalInstance.loginRedirect->error",
                    error
                  )
                );
              return new Promise(() => {});
            }

            const msalTokenExpiresOn = Number(
              localStorage.getItem("msalTokenExpiresOn")
            );
            const accessTokenRequest = { ...loginRequest, account };
            if (!msalTokenExpiresOn || currentTime > msalTokenExpiresOn) {
              DEVELOP &&
                console.log(
                  "%cmsalTokenExpiresOn expirado (o inexistente) => msalInstance.acquireTokenSilent",
                  "color: pink"
                );
              const resAcquireTokenSilent =
                await msalInstance.acquireTokenSilent(accessTokenRequest);
              const msalToken = resAcquireTokenSilent.accessToken;
              DEVELOP &&
                console.log(
                  "%cnuevo msalToken (acquireTokenSilent)",
                  "color: lightgreen",
                  msalToken.substring(0, 100)
                );
              const msalTokenExpiresOn = Number(
                resAcquireTokenSilent.expiresOn
              );
              const msalTokenExtExpiresOn = Number(
                resAcquireTokenSilent.extExpiresOn
              );
              localStorage.setItem("msalToken", msalToken);
              localStorage.setItem(
                "msalTokenExpiresOn",
                msalTokenExpiresOn.toString()
              );
              localStorage.setItem(
                "msalTokenExtExpiresOn",
                msalTokenExtExpiresOn.toString()
              );
            }

            if (config.url !== URL_AUTHENTICATE) {
              const appTokenExpiresOn = Number(
                localStorage.getItem("appTokenExpiresOn")
              );
              const msalToken = localStorage.getItem("msalToken") || "";
              if (!appToken || currentTime > appTokenExpiresOn) {
                DEVELOP &&
                  console.log(
                    "%cappToken expirado (o inexistente) => autenticar()",
                    "color: pink"
                  );
                const data = { Key: msalToken };
                const resAutenticar = await autenticar(data);
                appToken = resAutenticar.Token;
                DEVELOP &&
                  console.log(
                    "%cnuevo appToken (autenticar)",
                    "color: lightgreen",
                    msalToken.substring(0, 100)
                  );
                localStorage.setItem("appToken", resAutenticar.Token);
                localStorage.setItem(
                  "appTokenExpiresOn",
                  resAutenticar.RefreshToken
                );
                setPermissions(resAutenticar.Permisos);
              }
            }

            config.headers.Authorization = `Bearer ${appToken}`;
            DEVELOP && console.log("%ctokenFetched", "color: magenta");
            tokenFetching = false;
            // Resuelve las promesas encoladas con el nuevo token
            resolvePendingRequests(appToken);
            return config;
          } catch (error) {
            console.error("[IReq] acquireTokenSilent | autenticar", error);
            DEVELOP && console.log("%ctokenFetching = false", "color: magenta");
            tokenFetching = false;
            // Rechaza las promesas encoladas con el error de renovación del token
            rejectPendingRequests(error);
            return Promise.reject(error);
          }
        }
      }
    },
    (error) => {
      console.error("[IReq] interceptors.request->error", error);
      return Promise.reject(error);
    }
  );

  //region responseInterceptor
  if (responseInterceptorId !== undefined)
    axiosInstance.interceptors.response.eject(responseInterceptorId);

  responseInterceptorId = axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401) {
        if (originalRequest.url === URL_AUTHENTICATE) {
          localStorage.setItem("appToken", "notAuthorized");
          window.location.href = `${APP_BASE_URL}/noAccess`;
        }
      }
      return Promise.reject(error);
    }
  );

  DEVELOP && console.log("[configureAxios] End");
};

const get = async (url, config) => {
  try {
    const response = await axiosInstance.get(url, config);
    if (
      (config?.responseType === "blob" &&
        response.config.url.includes("/GetSuelo/reporteHitos")) ||
      (config?.responseType === "blob" &&
        response.config.url.includes("/GetSuelo/GetReporteDatosExcel")) ||
      (config?.responseType === "blob" &&
        response.config.url.includes(
          "/GetSuelo/GetReporteDatosValoracionExcel"
        )) ||
      (config?.responseType === "blob" &&
        response.config.url.includes("/Prefactura")) ||
      (config?.responseType === "blob" &&
        response.config.url.includes(
          "/GetSuelo/GetReporteParametrosUrbanisticos"
        ))
    )
      return response ? response : null;
    return response.data ? response.data : null;
  } catch (error) {
    if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};

const post = async (url, data, source) => {
  try {
    const response = await axiosInstance.post(url, data, {
      cancelToken: source?.token,
    });
    return response.data ? response.data : null;
  } catch (error) {
    if (axios.isCancel(error)) {
      // console.log("POST Request Cancelada");
    } else if (error.response) {
      const res = {
        code: error.response.status,
        msg: error.response.data,
        error: error,
      };
      throw res;
    } else {
      const res_1 = { code: 500, msg: error.message, error: error };
      throw res_1;
    }
  }
};
//ACCESO REPORTES
export const generarAutorizacionReporte = () => {
  const url = "/GenerarAutorizacionReporte";
  return get(url);
};

export const autenticar = (data) => {
  const url = URL_AUTHENTICATE;
  return post(url, data);
};

// export const auth = (data) => {
//   const url = "/Auth";
//   return post(url, data);
// };

export const renderPage = (data) => {
  const url = "/RenderPage";
  return post(url, data);
};

export const getModules = () => {
  const url = "/GetModules";
  return get(url);
};

export const getListByModule = (module) => {
  const url = `/GetListByModule/${module}`;
  return get(url);
};

export const getCarteraBySuelo = (id) => {
  const url = `/GetCarteraBySuelo/${id}`;
  return get(url);
};

export const saveByModule = (data) => {
  const url = "/SaveByModule";
  return post(url, data);
};

export const getSearchFilter = (moduleName) => {
  const url = `/GetSearchFilter/${moduleName}`;
  return get(url);
};

export const searchValueList = (listName, searchText) => {
  const url = `/SearchValueList?listName=${listName}&searchText=${searchText}`;
  return get(url);
};

export const getDetailByModule = (module, id) => {
  const url = `/GetDetailByModule?idModule=${module}&idEntity=${id}`;
  return get(url);
};

export const getModulesByOrigin = (data) => {
  const url = "/GetModulesByOrigin";
  return post(url, data);
};

export const deleteByModule = (data) => {
  const url = "/DeleteByModule";
  return post(url, data);
};

// SUELOS

export const getSuelos = (data) => {
  sourceGetSuelos = axios.CancelToken.source();
  const url = "/GetSuelos";
  return post(url, data, sourceGetSuelos);
};

export const getSuelo = (idSuelo) => {
  const url = `/GetSuelo/${idSuelo}`;
  return get(url);
};

export const saveSuelo = (data) => {
  const url = "/SaveSuelo";
  return post(url, data);
};

export const getCountSuelos = (data) => {
  sourceGetCountSuelos = axios.CancelToken.source();
  const url = "/GetCountSuelos";
  return post(url, data, sourceGetCountSuelos);
};

export const getModuleSuelo = (data) => {
  const url = "/GetModuleSuelo";
  return post(url, data);
};

export const cloneSuelo = (data) => {
  const url = "/CloneSuelo";
  return post(url, data);
};

export const getSueloReports = () => {
  const url = "/GetSueloReports";
  return get(url);
};

export const renderFichaSuelo = (reportId, sueloId) => {
  const url = `/RenderFichaSuelo/${reportId}/${sueloId}`;
  return get(url, { responseType: "blob" });
};

// FINCAS APORTADAS

export const getFincasAp = (data) => {
  sourceGetFincasAp = axios.CancelToken.source();
  const url = "/GetFincasAp";
  return post(url, data, sourceGetFincasAp);
};

export const getFincaAp = (idFinca) => {
  const url = `/GetFincaAp/${idFinca}`;
  return get(url);
};

export const saveFincaAp = (data) => {
  const url = "/SaveFincaAp";
  return post(url, data);
};

export const getCountFincasAp = (data) => {
  sourceGetCountFincasAp = axios.CancelToken.source();
  const url = "/GetCountFincasAp";
  return post(url, data, sourceGetCountFincasAp);
};

export const getModuleFincaAp = (data) => {
  const url = "/GetModuleFincaAp";
  return post(url, data);
};

export const cloneFincaAp = (data) => {
  const url = "/CloneFincaAp";
  return post(url, data);
};

//FINCAS RESULTANTES

export const getFincasRe = (data) => {
  sourceGetFincasRe = axios.CancelToken.source();
  const url = "/GetFincasRe";
  return post(url, data, sourceGetFincasRe);
};

export const getCountFincasRe = (data) => {
  sourceGetCountFincasRe = axios.CancelToken.source();
  const url = "/GetCountFincasRe";
  return post(url, data, sourceGetCountFincasRe);
};

export const getFincaRe = (idFinca) => {
  const url = `/GetFincaRe/${idFinca}`;
  return get(url);
};

export const getModuleFincaRe = (data) => {
  const url = "/GetModuleFincaRe";
  return post(url, data);
};

// DATOS PRINEX

export const getDatosPrinex = (data) => {
  sourceGetDatosPrinex = axios.CancelToken.source();
  const url = "/GetDatosPrinex";
  return post(url, data, sourceGetDatosPrinex);
};

export const getDatoPrinex = (idDatoPrinex) => {
  const url = `/GetDatoPrinex/${idDatoPrinex}`;
  return get(url);
};

export const saveDatoPrinex = (data) => {
  const url = "/SaveDatoPrinex";
  return post(url, data);
};

export const getCountDatosPrinex = (data) => {
  sourceGetCountDatosPrinex = axios.CancelToken.source();
  const url = "/GetCountDatosPrinex";
  return post(url, data, sourceGetCountDatosPrinex);
};

export const getModuleDatoPrinex = (data) => {
  const url = "/GetModuleDatoPrinex";
  return post(url, data);
};

// VINCULAR

export const linkModule = (data) => {
  const url = "/LinkModule";
  return post(url, data);
};

export const getModuleLinks = (data) => {
  sourceGetModulos = axios.CancelToken.source();
  const url = "/GetModuleLinks";
  return post(url, data, sourceGetModulos);
};

export const unlinkModule = (data) => {
  const url = "/UnlinkModule";
  return post(url, data);
};

export const getSearchFilterLink = (sourceModule, module) => {
  const url = `/GetSearchFilterLink/${sourceModule}/${module}`; //
  return get(url);
};

// AUDITORÍA

export const getAuditData = (data) => {
  sourceGetAuditorias = axios.CancelToken.source();
  const url = "/GetAuditData";
  return post(url, data, sourceGetAuditorias);
};

// USUARIOS

export const getUsers = (data) => {
  sourceGetUsers = axios.CancelToken.source();
  const url = "/GetUsers";
  return post(url, data, sourceGetUsers);
};

export const getCountUsers = (data) => {
  sourceGetCountUsers = axios.CancelToken.source();
  const url = "/GetCountUsers";
  return post(url, data, sourceGetCountUsers);
};

export const getActionsUser = (data) => {
  const url = "/GetActionsUser";
  return post(url, data);
};

// ZONAS

export const getZones = (data) => {
  sourceGetZones = axios.CancelToken.source();
  const url = "/GetZonas";
  return post(url, data, sourceGetZones);
};

export const getCountZones = (data) => {
  sourceGetCountZones = axios.CancelToken.source();
  const url = "/GetCountZonas";
  return post(url, data, sourceGetCountZones);
};

export const getZone = (idZone) => {
  const url = `/GetZona/${idZone}`;
  return get(url);
};

export const getUsosPricing = () => {
  const url = "/GetUsosPricing";
  return get(url);
};

export const saveZone = (data) => {
  const url = "/SaveZona";
  return post(url, data);
};

export const deleteZone = (idZone) => {
  const url = `/DeleteZona/${idZone}`;
  return get(url);
};

//DESCARGA

export const downloadDocument = (id) => {
  const url = `/DownloadDocument/` + id;
  return get(url, { responseType: "blob" });
};

export const downloadDocumentRevision = (id) => {
  const url = `/DownloadDocumentRevision/` + id;
  return get(url, { responseType: "blob" });
};

export const downloadImagen = (id) => {
  const url = `/DownloadImagen/` + id;
  return get(url, { responseType: "blob" });
};

export const reporteHitos = () => {
  const url = `/GetSuelo/reporteHitos`;
  return get(url, { responseType: "blob" });
};

export const reporteDatosValoracionExcel = () => {
  const url = "/GetSuelo/GetReporteDatosValoracionExcel";
  return get(url, { responseType: "blob" });
};

export const reporteParametrosUrbanisticos = () => {
  const url = "/GetSuelo/GetReporteParametrosUrbanisticos";
  return get(url, { responseType: "blob" });
};

export const reportPrefacturasExcel = () => {
  const url = "/Prefactura";
  return get(url, { responseType: "blob" });
};

// ROLES

export const getRoles = () => {
  const url = `/GetRoles`;
  return get(url);
};

export const getRol = (idRole) => {
  const url = `/GetRol/${idRole}`;
  return get(url);
};

export const saveRol = (data) => {
  const url = "/SaveRol";
  return post(url, data);
};

export const getRoleAction = () => {
  const url = "/GetRoleAction";
  return get(url);
};

export const GetRolePermitsByAction = () => {
  const url = "/GetRolePermitsByAction";
  return get(url);
};

// VISOR DE SUELOS

export const getSuelosFincas = (data) => {
  sourceGetSuelos = axios.CancelToken.source();
  const url = "/GetSuelosFincas";
  return post(url, data, sourceGetSuelos);
};

//
