import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldGridCheckbox = ({
  field,
  value,
  beingEdited,
  handleChange,
  readOnly,
}) => {
  return (
    <S.Td type={field.type}>
      <S.Input
        type="checkbox"
        value={value ? true : false}
        checked={value ? true : false}
        disabled={readOnly || !beingEdited}
        onChange={(e) => handleChange(field.key, e.target.checked)}
      />
      <input type="hidden" name={field.key} value={value ? true : false} />
    </S.Td>
  );
};

FieldGridCheckbox.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.bool.isRequired,
  beingEdited: PropTypes.bool,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
};
