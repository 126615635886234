import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldNoAccess = ({ id, label, hidden }) => {
  return (
    <S.Container className={hidden ? "hidden" : "visible"}>
      {label && <S.Title>{label}</S.Title>}
      <S.InputContainer>
        <S.Text>Sin acceso</S.Text>
      </S.InputContainer>
    </S.Container>
  );
};

FieldNoAccess.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  hidden: PropTypes.string,
};
