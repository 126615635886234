import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldGridNumber = ({
  field,
  value: numericValue,
  empty,
  type,
  beingEdited,
  handleChange,
  readOnly,
  required,
}) => {
  const value = empty ? "" : numericValue;
  const editing = beingEdited && !readOnly;

  const numberOptions = () => {
    return {
      style: type === "currency" ? "currency" : "decimal",
      minimumFractionDigits: numDigits(),
      maximumFractionDigits: numDigits(),
      currency: "EUR",
    };
  };

  const numDigits = () => {
    if (field.key === "Latitud") return 6;
    if (field.key === "Longitud") return 6;
    if (type === "float") return 2;
    if (type === "decimal") return 2;
    if (type === "currency") return 2;
    return 0;
  };

  const step = () => {
    if (field.key === "Latitud") return 0.000001;
    if (field.key === "Longitud") return 0.000001;
    if (type === "float") return 0.01;
    if (type === "decimal") return 0.01;
    if (type === "currency") return 0.01;
    return 1;
  };

  const min = () => {
    if (field.key === "Latitud") return null;
    if (field.key === "Longitud") return null;
    return 0;
  };

  return (
    <S.Td type={field.type} beingEdited={beingEdited}>
      <div className={editing ? "visible" : "hidden"}>
        <S.Input
          type="number"
          name={field.key}
          value={value}
          step={step()}
          min={min()}
          onChange={(e) =>
            handleChange(
              field.key,
              e.target.value ? Number(e.target.value) : ""
            )
          }
          disabled={readOnly}
          required={required}
        />
      </div>
      <div className={editing ? "hidden" : "visible"}>
        {Number(value).toLocaleString("es-ES", numberOptions())}
      </div>
    </S.Td>
  );
};

FieldGridNumber.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.number,
  empty: PropTypes.bool,
  type: PropTypes.string,
  beingEdited: PropTypes.bool,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
};
