import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

export const FieldGridList = ({
  field,
  value,
  options,
  beingEdited,
  handleChange,
  readOnly,
  required,
}) => {
  const optionsFixed = options?.map((option) => ({
    value: option.Id,
    label: option.Value,
  }));
  const editing = beingEdited && !readOnly;

  return (
    <S.Td title={value} type={field.id} beingEdited={beingEdited}>
      <div className={editing ? "visible" : "hidden"}>
        <S.Select
          name={field.key}
          value={value}
          onChange={(value) => handleChange(field.key, value.target.value)}
          required={required}
        >
          {options?.length ? (
            optionsFixed.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          ) : (
            <option value={value}>{value}</option>
          )}
        </S.Select>
      </div>
      <div className={editing ? "hidden" : "visible"}>
        {options?.length
          ? optionsFixed.find((option) => option.value === value)?.label
          : value}
      </div>
    </S.Td>
  );
};

FieldGridList.propTypes = {
  field: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.object),
  beingEdited: PropTypes.bool,
  handleChange: PropTypes.func,
  editing: PropTypes.bool,
  required: PropTypes.bool,
};
