import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  display: grid;
  .active {
    display: inherit;
  }
  .inactive {
    display: none;
  }
  li {
    list-style: none;
  }
  a {
    color: ${COLORES.AZUL_LINK};
    text-decoration: none;
  }
`;

export const TitleBar = styled.div`
  position: sticky;
  top: calc(2.5rem + 1px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  background-color: ${COLORES.FONDO_MENU};
  border-bottom: solid 1px ${COLORES.GRIS_CLARO};
  padding: 0 2rem;
  z-index: 3;
`;

export const WrapperFieldText = styled.div`
  font-weight: 600;
`;

export const SubMenuBar = styled.div`
  position: sticky;
  top: calc(5.5rem + 2px);
  font-size: 0.6875rem;
  color: ${COLORES.TABS_TEXT};
  display: flex;
  align-items: center;
  height: 2.5rem;
  background-color: ${COLORES.FONDO_MENU};
  z-index: 2;
  ul {
    display: flex;
    width: 100%;
  }
  li {
    padding: 0.25rem 1rem;
  }
  li[id*="dummy"] {
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }
  li[id="dummy-end"] {
    flex-grow: 1;
  }
`;

export const Submenu = styled.li`
  display: flex;
  align-items: center;
  height: 2rem;
  cursor: pointer;
  border-bottom: solid 1px ${COLORES.GRIS_CLARO};

  &.active {
    font-weight: bold;
    background-color: ${COLORES.BLANCO};
    border-bottom: thin solid ${COLORES.BLANCO};
    border-left: thin solid ${COLORES.GRIS_CLARO};
    border-right: thin solid ${COLORES.GRIS_CLARO};
  }

  :hover {
    background-color: ${COLORES.AZUL_HOVER};
  }
`;

export const FieldsContainer = styled.ul`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 3rem;
  background-color: ${COLORES.FONDO};
  padding: 1rem 2rem;
`;

export const ModulesContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;
