import React, { useState, useEffect } from "react";
import * as S from "./styles";
import { useLocation } from "react-router-dom";
import * as API from "../../api";
import {
  AUDITORIA_POR_PAGINA,
  TITULOS_COLUMNAS_AUDITORIA,
} from "../../constants";
import { Loading } from "../../components/Loading";

export const Auditoria = () => {
  const [loading, setLoading] = useState(true);
  const [auditorias, setAuditorias] = useState([]);
  const [pages, setPages] = useState(1);
  const [allShowed, setAllShowed] = useState(false);
  const location = useLocation();

  useEffect(() => {
    getAuditorias();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pagination = (page) => {
    return { Page: page, PageSize: AUDITORIA_POR_PAGINA };
  };

  const splitPathName = location.pathname.split("/");

  const getAuditorias = (page = 1) => {
    setLoading(true);
    let data = {};
    data.Pagination = pagination(page);
    data.IdEntity = splitPathName[2];
    data.ModuleName = splitPathName[1];

    API.getAuditData(data)
      .then((res) => {
        if (res) {
          if (page === 1 && res.length === 0) setAuditorias([]);
          if (res.length > 0) {
            if (page === 1) setAuditorias(res);
            else setAuditorias([...auditorias, ...res]);
          }
          if (res.length < AUDITORIA_POR_PAGINA) {
            setAllShowed(true);
          }
          setPages(page);
        }
        setLoading(false);
      })
      .catch((error) => API.DEVELOP && console.log(error));
  };

  const showMore = () => getAuditorias(pages + 1);

  const entityname = location.entityname || splitPathName[2];

  return (
    <S.Container>
      <Loading hidden={!loading} />
      <S.TitleBar>Registro de auditorías de {`${entityname}`}</S.TitleBar>
      <S.Table>
        <thead>
          <tr>
            {TITULOS_COLUMNAS_AUDITORIA.map((titulo) => (
              <S.TdThead key={titulo.key}>
                <S.HeaderColumn>
                  <span>{titulo.label}</span>
                </S.HeaderColumn>
              </S.TdThead>
            ))}
          </tr>
        </thead>
        <tbody>
          {auditorias.length > 0 &&
            auditorias.map((auditoria) => (
              <tr>
                <td>{auditoria.Name}</td>
                <td>{auditoria.ValorAnterior}</td>
                <td>{auditoria.ValorNuevo}</td>
                <td>{auditoria.CambiadoPor}</td>
                <td>{auditoria.Origen}</td>
                <td>{auditoria.FechaCambio}</td>
              </tr>
            ))}
        </tbody>
      </S.Table>
      <S.Pagination>
        <S.More onClick={showMore} disabled={loading || allShowed}>
          {allShowed ? "No hay más auditorías" : "Más auditorías..."}
        </S.More>
      </S.Pagination>
    </S.Container>
  );
};
