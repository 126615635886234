import styled from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.li`
  .hidden {
    display: none;
  }
`;

export const Title = styled.div`
  font-size: 0.75rem;
  color: ${COLORES.GRIS_OSCURO};
  margin-bottom: 0.2rem;
`;

export const SelectContainer = styled.div`
  font-size: 0.875rem;
  display: block;
`;

export const Text = styled.div`
  font-size: 0.875rem;
  display: block;
  padding: 7px 11px;
`;
