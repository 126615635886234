import styled, { keyframes } from "styled-components/macro";
import { COLORES } from "../../constants";

export const Container = styled.div`
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #0003;
`;

const entry = keyframes`
  0% {transform: scale(0);}
  90% {transform: scale(1.1);}
  100% {transform: scale(1);}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 15rem;
  background-color: ${COLORES.BLANCO};
  margin: 6rem auto;
  border-radius: 3px;
  box-shadow: 5px 5px 5px #555;
  text-align: center;
  animation: ${entry} 0.2s linear forwards;
`;

export const TitleBar = styled.div`
  height: 1.5rem;
  border-radius: 3px 3px 0 0;
  background-color: ${(props) => {
    switch (props.type) {
      case "success":
        return COLORES.SUCCESS;
      case "error":
        return COLORES.ERROR;
      case "info":
        return COLORES.INFO;
      default:
        return COLORES.BLANCO;
    }
  }};
  button {
    margin: 0;
    padding: 0 0.25rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${COLORES.NEGRO};
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  height: 1.5rem;
  line-height: 1.5rem;
`;

export const Content = styled.p`
  font-size: 0.875rem;
  margin: 1.5rem;
  padding: 1rem;
  white-space: pre-line;
`;
