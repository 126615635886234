export const DEFAULT_TIMER_TOAST = 2500;
export const ADD_RELATE_ENTITY_TEXT = "[CREAR NUEVO] ";
export const MAX_UPLOAD_FILE_SIZE = 4294966272; // EN BYTES (4194303 KB)

// MENÚ PRINCIPAL
const SUELOS = { key: "UB_Suelo", label: "Suelos" };
const FINCAS_APORTADAS = { key: "UB_FincasAp", label: "Fincas Aportadas" };
const FINCAS_RESULTANTES = { key: "UB_FincasRe", label: "Fincas Resultantes" };
const DATOS_PRINEX = { key: "UB_datos_prinex", label: "Datos ERP Expedientes" };
const VISOR_SUELOS = { key: "UB_Suelo_Geocerca", label: "Visor de Suelos" };
export const REPORTES = { key: "Reports", label: "Reportes generales" };
export const REPORTES_QUASAR = {
  key: "Reports_Quasar",
  label: "Otros Reportes Quasar",
};

export const MENUS = [
  SUELOS,
  FINCAS_APORTADAS,
  FINCAS_RESULTANTES,
  DATOS_PRINEX,
  VISOR_SUELOS,
  REPORTES,
  REPORTES_QUASAR,
];

// MENÚ EXTRA
const JUNTA_COMPENSACION = {
  key: "Ju_Juntaco",
  label: "Junta de Compensación",
};
const SEGUIMIENTO_HITOS = {
  key: "PGU_Anotaciones",
  label: "Seguimiento Hitos",
};
const ORDENANZA_PPAL = {
  key: "ord_OR_ordenanzas",
  label: "Ordenanza",
};
const PROYECTOS_URBANISTICOS = {
  key: "DIDP_ProyectosUrbanisticos",
  label: "Proyectos Urbanísticos",
};
const CONTRATOS = {
  key: "UB_contratos",
  label: "Contratos",
};
const DERRAMAS = {
  key: "UB_Derramas",
  label: "Derramas",
};
const PROPIETARIOS_SECTOR = {
  key: "UB_estructuraprop",
  label: "Propietarios del Sector",
};
const PROXIMAS_GESTIONES = {
  key: "UB_GestionesProximas",
  label: "Próximas Gestiones",
};
const AYUNTAMIENTO = {
    key: "UB_Ayuntamiento",
    label: "Ayuntamiento",
};
const GESTIONES_REALIZADAS = {
  key: "UB_GestionesRealizadas",
  label: "Gestiones Realizadas",
};
const OBJETIVOS_SUELOS = {
  key: "UB_objetivossuelos",
  label: "Objetivo Suelos",
};
const OBSERVACIONES_GENERALES = {
  key: "UB_SueloObs",
  label: "Observaciones Generales",
};
const DATOS_CATASTRALES = {
  key: "UB_DatoCat",
  label: "Datos Catastrales",
};
const DATOS_REGISTRALES = {
  key: "UB_DatoReg",
  label: "Datos Registrales",
};
const OBSERVACIONES_PROYECTO = {
  key: "DIDP_Observaciones_Proyecto",
  label: "Observaciones Proyecto",
};
const MIEMBRO_JUNTA = {
  key: "Ju_Miembroju",
  label: "Miembro Junta",
};
const DOCUMENTOS = {
  key: "Documents",
  label: "Documentos",
};
const DOCUMENTOS_PRICING = {
  key: "Documents_Pricing",
  label: "Documentos",
};
const DOCUMENTOS_STITUACIONJURIDICA = {
  key: "Documents_SituacionJuridica",
  label: "Documentos Juridicos",
};
const REVISIONES_DOCUMENTOS = {
  key: "Document_Revisions",
  label: "Revisión",
};
const IMAGENES = {
  key: "ESGdI_Imagenes",
  label: "Imágenes",
};
const VINCULAR = {
  key: "vincular",
  label: "Vincular",
};
const AUDITORIAS = {
  key: "Audit",
  label: "Auditorías",
};
const REUNIONES = {
  key: "Meetings",
  label: "Reuniones",
};
const HITOS_URBANISTICOS_NO_ORDINARIOS = {
  key: "UB_Hitos_Urbanisticos_No_Ordinarios",
  label: "Hitos Urbanísticos No Ordinarios",
};
export const USUARIOS = {
  key: "Users",
  label: "Usuarios",
};
export const ZONAS = {
  key: "Zones",
  label: "Zonas",
};
const SIN_ACCESO = {
  key: "noAccess",
  label: "Sin acceso",
};
export const MENUS_EXTRA = [
  JUNTA_COMPENSACION,
  SEGUIMIENTO_HITOS,
  ORDENANZA_PPAL,
  PROYECTOS_URBANISTICOS,
  CONTRATOS,
  DERRAMAS,
  PROPIETARIOS_SECTOR,
  PROXIMAS_GESTIONES,
  AYUNTAMIENTO,
  GESTIONES_REALIZADAS,
  OBJETIVOS_SUELOS,
  OBSERVACIONES_GENERALES,
  DATOS_CATASTRALES,
  DATOS_REGISTRALES,
  OBSERVACIONES_PROYECTO,
  MIEMBRO_JUNTA,
  DOCUMENTOS,
  DOCUMENTOS_PRICING,
  DOCUMENTOS_STITUACIONJURIDICA,
  REVISIONES_DOCUMENTOS,
  IMAGENES,
  VINCULAR,
  AUDITORIAS,
  REUNIONES,
  HITOS_URBANISTICOS_NO_ORDINARIOS,
  USUARIOS,
  ZONAS,
  SIN_ACCESO,
];

// SUELOS
const PROMOCION = {
  key: "IdPromocion",
  label: "ID PROMOCIÓN",
  sortable: true,
};
const SUELO = { key: "Name", label: "Suelo", sortable: true };
const TIPO_DE_SUELO = {
  key: "TipoDesuelo",
  label: "Tipo de Suelo",
  sortable: true,
};
const CLASIFICACION = {
  key: "Clasificacion",
  label: "Clasificación",
  sortable: true,
};
const ZONA = { key: "zona", label: "Zona", sortable: false };
const PROVINCIA = { key: "provincia", label: "Provincia", sortable: false };
const MUNICIPIO = { key: "municipio", label: "Municipio", sortable: false };
export const DELAY_BUSCADOR_SUELOS = 500;
export const SUELOS_POR_PAGINA = 40;
export const TITULOS_COLUMNAS_SUELOS = [
  PROMOCION,
  SUELO,
  TIPO_DE_SUELO,
  CLASIFICACION,
  ZONA,
  PROVINCIA,
  MUNICIPIO,
];

// MÓDULOS
export const REGISTROS_POR_PAGINA_SUBMODULO = 10;

// FINCAS APORTADAS
const FINCAAPORTADA = { key: "Name", label: "Finca Aportada", sortable: true };
const EXPEDIENTE = {
  key: "Expediente",
  label: "Expediente Aportada",
  sortable: true,
};
const ID_INMUEBLE = {
  key: "IdPrinexLargo",
  label: "Id Inmueble",
  sortable: true,
};
const ID_ACTIVO = { key: "Idprinex", label: "ID Activo", sortable: false };
const FECHA_CREACION = {
  key: "FechaCreacion",
  label: "Fecha de Creación",
  sortable: true,
};
const ULTIMA_MODIFICACION = {
  key: "LastModified",
  label: "Última Modificación",
  sortable: true,
};
export const DELAY_BUSCADOR_FINCASAP = 500;
export const FINCASAP_POR_PAGINA = 40;
export const TITULOS_COLUMNAS_FINCASAP = [
  FINCAAPORTADA,
  EXPEDIENTE,
  ID_INMUEBLE,
  ID_ACTIVO,
  FECHA_CREACION,
  ULTIMA_MODIFICACION,
];

// FINCAS RESULTANTES
const FINCARESULTANTE = {
  key: "Name",
  label: "Finca Resultante",
  sortable: true,
};
const PROPIETARIO = {
  key: "Propietario",
  label: "Propietario",
  sortable: false,
};
const EN_PROINDIVISO = {
  key: "Enproindiviso",
  label: "En Proindiviso",
  sortable: true,
};
export const DELAY_BUSCADOR_FINCASRE = 500;
export const FINCASRE_POR_PAGINA = 40;
export const TITULOS_COLUMNAS_FINCASRE = [
  FINCARESULTANTE,
  PROPIETARIO,
  EN_PROINDIVISO,
];

// DATOS PRINEX
const ID_ACTIVO_DATO_PRINEX = {
  key: "IdActivo",
  label: "Id Activo",
  sortable: true,
};
const PROMOCION_DATO_PRINEX = {
  key: "Promocion",
  label: "Promoción",
  sortable: true,
};
const PVP = { key: "ValorContableBruto", label: "PVP", sortable: true };
const PVP_MINIMO = {
  key: "ValorContableNeto",
  label: "PVP Minimo",
  sortable: true,
};
const IDENTIFICADOR_PRINEX = {
  key: "IdPrinex",
  label: "Identificador Prinex",
  sortable: true,
};
const IMPORTE_SPOT_VALUE = {
  key: "ValorAdjudicacion",
  label: "Importe/Spot Value",
  sortable: true,
};
const PROPIETARIO_DATO_PRINEX = {
  key: "Propietario",
  label: "Propietario",
  sortable: false,
};
export const DELAY_BUSCADOR_DATOSPRINEX = 500;
export const DATOSPRINEX_POR_PAGINA = 40;
export const TITULOS_COLUMNAS_DATOSPRINEX = [
  ID_ACTIVO_DATO_PRINEX,
  PROMOCION_DATO_PRINEX,
  PVP,
  PVP_MINIMO,
  IDENTIFICADOR_PRINEX,
  IMPORTE_SPOT_VALUE,
  PROPIETARIO_DATO_PRINEX,
];

// VINCULAR
export const DELAY_BUSCADOR_VINCULAR = 500;
export const VINCULAR_POR_PAGINA = 40;

// AUDITORIA
const CAMPO_AUDITORIA = {
  key: "Campo",
  label: "Campo",
  sortable: false,
};
const VALOR_ANTERIOR_AUDITORIA = {
  key: "ValorAnterior",
  label: "Valor Anterior",
  sortable: false,
};
const VALOR_NUEVO_AUDITORIA = {
  key: "ValorNuevo",
  label: "Valor Nuevo",
  sortable: false,
};
const CAMBIADO_AUDITORIA = {
  key: "CambiadoPor",
  label: "Cambiado Por",
  sortable: false,
};
const ORIGEN_AUDITORIA = {
  key: "Origen",
  label: "Origen",
  sortable: false,
};
const FECHA_CAMBIO_AUDITORIA = {
  key: "FechaCambio",
  label: "Fecha de Cambio",
  sortable: false,
};
export const DELAY_BUSCADOR_AUDITORIA = 500;
export const AUDITORIA_POR_PAGINA = 40;
export const TITULOS_COLUMNAS_AUDITORIA = [
  CAMPO_AUDITORIA,
  VALOR_ANTERIOR_AUDITORIA,
  VALOR_NUEVO_AUDITORIA,
  CAMBIADO_AUDITORIA,
  ORIGEN_AUDITORIA,
  FECHA_CAMBIO_AUDITORIA,
];

// USUARIOS
const NOMBRE_USUARIO = {
  key: "Name",
  label: "Nombre y Apellidos",
  sortable: false,
};
const USERNAME_USUARIO = {
  key: "UserName",
  label: "Nombre de Usuario",
  sortable: false,
};
const GESTOR_USUARIO = {
  key: "GestorExterno",
  label: "Gestor Externo",
  sortable: false,
};
const ROL_USUARIO = {
  key: "RolUser",
  label: "Rol",
  sortable: false,
};
const EMAIL_USUARIO = {
  key: "Email",
  label: "Correo electrónico",
  sortable: false,
};
const EQUIPO_USUARIO = {
  key: "Team",
  label: "Equipo",
  sortable: false,
};
const ESTADO_USUARIO = {
  key: "UserStatus",
  label: "Estado del Empleado",
  sortable: false,
};
const ULTIMA_MODIFICACION_USUARIO = {
  key: "LastModified",
  label: "Última Modificación",
  sortable: false,
};
const ULTIMO_ACCESO = {
  key: "LastLogin",
  label: "Último Acceso",
  sortable: false,
};
export const DELAY_BUSCADOR_USUARIOS = 500;
export const USUARIOS_POR_PAGINA = 40;
export const TITULOS_COLUMNAS_USUARIOS = [
  NOMBRE_USUARIO,
  USERNAME_USUARIO,
  GESTOR_USUARIO,
  ROL_USUARIO,
  EMAIL_USUARIO,
  EQUIPO_USUARIO,
  ESTADO_USUARIO,
  ULTIMA_MODIFICACION_USUARIO,
  ULTIMO_ACCESO,
];

// ROLES
const ID_ROL = {
  key: "Id",
  label: "ID",
  sortable: false,
};
const NAME_ROL = {
  key: "Name",
  label: "Nombre",
  sortable: false,
};
const DESCRIPCION_ROL = {
  key: "Description",
  label: "Descripción",
  sortable: false,
};
export const TITULOS_COLUMNAS_ROLES = [ID_ROL, NAME_ROL, DESCRIPCION_ROL];

// ZONAS
const NOMBRE_ZONA = {
  key: "Name",
  label: "Nombre",
  sortable: false,
};
const DESCRIPCION_ZONA = {
  key: "Description",
  label: "Descripción",
  sortable: false,
};
const ELIMINAR_ZONA = {
  key: "Eliminar",
  label: "Eliminar",
  sortable: false,
};
export const DELAY_BUSCADOR_ZONAS = 500;
export const ZONAS_POR_PAGINA = 40;
export const TITULOS_COLUMNAS_ZONAS = [
  NOMBRE_ZONA,
  DESCRIPCION_ZONA,
  ELIMINAR_ZONA,
];

// PALETA DE COLORES
export const COLORES = {
  AZUL: "#0679c8",
  BLANCO: "white",
  ROJO: "red",
  ROJO_ALISEDA: "#de0e48",
  ROJO_ALISEDA_HOVER: "#fe0e48",
  GRIS: "grey",
  GRIS_CLARO: "lightgrey",
  GRIS_OSCURO: "#717171",
  AZUL_HOVER: "#e2f2fe",
  AZUL_HOVER_DARK: "#0463a4",
  AZUL_LINK: "#00aaee",
  FONDO_MENU: "#f6f6f6",
  FONDO: "white",
  FIELD_BACKGROUND: "white",
  NEGRO: "black",
  LIST_ACTIVE: "indianred",
  MENU_ACTIVO: "#e4e9ed",
  MENU_HOVER: "#f7f8f9",
  SUBMENU_ACTIVO: "#f8f8f8",
  SUCCESS: "green",
  ERROR: "red",
  INFO: "royalblue",
  PLACEHOLDER_TEXT: "#9aa5ad",
  PLACEHOLDER_BORDER: "#e8e8e8",
  SELECT_BORDER: "#e8e8e8",
  FILTERS_TEXT: "#555555",
  TABS_TEXT: "#555555",
  SUBMODULE_DISABLED: "#0000004D",
  HIGHLIGHTED_RECORD: "#a1dcff",
};
